import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

import { SidebarAccordionItemDirective } from './sidebar-accordion-item.directive';
import { SB_SIDEBAR } from './sidebar.token';

@Component({
  selector: 'sb-sidebar',
  standalone: true,
  providers: [
    { provide: SB_SIDEBAR, useExisting: SidebarComponent },
    { provide: CdkScrollable, useExisting: SidebarComponent },
  ],

  template: `<div class="flex grow flex-col py-6 pl-5 pr-3">
    <ng-content></ng-content>
  </div>`,
})
export class SidebarComponent extends CdkScrollable {
  @HostBinding('class')
  readonly hostClass = clsx(
    'flex h-full max-h-full w-64 flex-col overflow-y-auto overflow-x-hidden bg-white shadow-xl scrollbar-hide',
  );

  accordionItems: SidebarAccordionItemDirective[] = [];

  registerAccordionItem(item: SidebarAccordionItemDirective) {
    this.accordionItems.push(item);
  }

  closeOtherAccordionItems(id: string) {
    this.accordionItems.forEach((item) => {
      if (item.id === id) {
        return;
      }
      item.expanded = false;
    });
  }

  closeAllAccordionItems() {
    this.accordionItems.forEach((item) => {
      item.expanded = false;
    });
  }
}
