import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { IconButtonComponent } from '../../button';
import { DropdownModule, dropdownPlacement, DropdownPlacement } from '../../dropdown';
import { IconComponent } from '../../icon';
import { SpinnerComponent } from '../../spinner';
import { statusMapping, StatusType } from '../status-mapping.helper';

interface StatusOption {
  status: StatusType;
  label: string;
}

// order of statuses in dropdown
const statusOrder: StatusType[] = ['Approved', 'Pending', 'Declined'];

@Component({
  selector: 'sb-status-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DropdownModule, TranslateModule, IconComponent, SpinnerComponent, IconButtonComponent],
  templateUrl: './status-button.component.html',
})
export class StatusButtonComponent {
  @Input()
  position: dropdownPlacement = DropdownPlacement['bottom-right'];
  @Input()
  status!: StatusType;

  @Input()
  saving = false;

  @Output()
  statusChange: EventEmitter<StatusType> = new EventEmitter<StatusType>();

  readonly statusMapping = statusMapping;

  options: StatusOption[] = statusOrder.map((status) => ({ status, label: statusMapping[status].label }));

  trackByStatus(_: number, item: StatusOption) {
    return item.status;
  }
}
