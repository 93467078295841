<div class="flex flex-col gap-2" role="tablist">
  @for (tab of tabs; track tab.id) {
    <div
      role="tab"
      class="cursor-pointer rounded-base border-0 p-2 text-left font-semibold outline-none hover:bg-primary-50 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-primary-500/50"
      [ngClass]="{
        'text-red-500': tab.isInvalid,
        'bg-primary-50': tab.isSelected,
        'bg-transparent': !tab.isSelected,
        'text-primary-500': tab.isSelected && !tab.isInvalid,
        'text-gray-500': !tab.isSelected && !tab.isInvalid
      }"
      (click)="selected.emit(tab)"
    >
      {{ tab.text }}
    </div>
  }
</div>
