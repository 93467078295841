import { Directive, Input } from '@angular/core';

export const dropdownHostClasses = {
  class:
    'flex w-full items-center justify-start border-0 px-4 py-2 text-left text-base font-normal outline-0  focus:bg-grey-100 disabled:pointer-events-none disabled:text-grey-400',
  '[class.bg-primary-50]': 'selected || null',
  '[class.bg-transparent]': '!selected || null',
  '[class.hover:bg-grey-100]': 'hasHoverEffect !== false',
};

@Directive({
  selector: '[sbDropdownBaseItem]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    ...dropdownHostClasses,
  },
})
export class DropdownBaseItemDirective {
  @Input()
  selected = false;
  @Input()
  hasHoverEffect = true;
}
