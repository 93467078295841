import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[sbLegacyDialogSidebar], [sb-legacy-dialog-sidebar], sb-legacy-dialog-sidebar',
  standalone: true,
})
export class DialogSidebarDirective {
  private readonly baseClass = 'block h-full border-0 border-l border-solid border-grey-200';

  @HostBinding('class')
  class = this.baseClass;
}
