import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[sbInputIconSuffix], sb-input-icon-suffix',
  standalone: true,
})
export class InputIconSuffixDirective {
  @HostBinding('class')
  hostClass = 'absolute inset-y-0 right-0 flex items-center px-3 text-grey-400';
}
