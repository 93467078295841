<input
  class="peer sr-only"
  #input
  type="checkbox"
  [id]="inputId"
  [checked]="checked"
  [disabled]="disabled"
  (blur)="onBlur()"
  (click)="onInputClick()"
  (change)="onInteractionEvent($event)"
/>
<label
  [for]="inputId"
  #label
  class="m-0 flex h-6 w-6 cursor-pointer select-none items-center justify-center rounded-full border border-solid text-sm font-medium peer-focus-visible:border-primary-500 peer-disabled:cursor-default peer-aria-invalid:border-red-500 peer-aria-invalid:text-red-500"
  [ngClass]="{
    'opacity-60': disabled,
    'text-grey-500 bg-grey-100 border-grey-100': !checked,
    'text-white bg-primary-500 border-primary-500': checked
  }"
>
  <ng-content></ng-content>
</label>
