import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { clsx } from 'clsx';

import { ButtonComponent, ButtonGroupComponent, IconButtonComponent } from '../button';
import { IconComponent } from '../icon';
import { calculatePages } from './pagination.helper';

export interface PaginationChangeEvent {
  page: number;
}

interface Page {
  label: string;
  value: number;
  type: 'page' | 'ellipsis';
}

export const PAGINATION_DEFAULT_ITEMS_PER_PAGE = 10;
export const PAGINATION_DEFAULT_MAX_VISIBLE_PAGES = 7;

@Component({
  selector: 'sb-pagination',
  standalone: true,
  imports: [CommonModule, IconComponent, IconButtonComponent, ButtonComponent, TranslateModule],
  templateUrl: './pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges {
  @Input()
  totalItems!: number;

  @Input()
  itemsPerPage = PAGINATION_DEFAULT_ITEMS_PER_PAGE;

  @Input()
  page = 1;

  @Input()
  maxVisiblePages = PAGINATION_DEFAULT_MAX_VISIBLE_PAGES;

  @Input()
  showCount = false;

  currentPage = 1;

  pages: Page[] = [];

  @Output()
  pageChange = new EventEmitter<PaginationChangeEvent>();

  @HostBinding('class.hidden')
  get hidden(): boolean {
    return this.totalPages <= 1;
  }

  @HostBinding('attr.role')
  role = 'navigation';

  @HostBinding('attr.aria-label')
  ariaLabel = 'Pagination';

  @HostBinding('class')
  hostClasses = clsx(['block']);

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  get canGoToPreviousPage(): boolean {
    return this.currentPage > 1;
  }

  get canGoToNextPage(): boolean {
    return this.currentPage < this.totalPages;
  }

  ngOnChanges(): void {
    this.currentPage = Math.min(Math.max(this.page, 1), this.totalPages);
    this.setVisiblePages();
  }

  goToPage(page: number): void {
    if (!this.canGoToPage(page) || page === this.currentPage) {
      return;
    }
    this.currentPage = page;
    this.setVisiblePages();
    this.pageChange.emit({ page });
  }

  trackByPage(_: number, page: Page): number {
    return page.value;
  }

  private setVisiblePages(): void {
    this.pages = calculatePages({
      currentPage: this.currentPage,
      totalPages: this.totalPages,
      maxSize: this.maxVisiblePages,
    }).map((value, index) => ({
      label: `${value}`,
      value: value === '...' ? -index : value,
      type: value === '...' ? 'ellipsis' : 'page',
    }));
  }

  private canGoToPage(page: number): boolean {
    return page >= 1 && page <= this.totalPages;
  }
}
