import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { InputBaseDirective } from './input-base.directive';

const inputTypes = [
  'color',
  'date',
  'datetime-local',
  'month',
  'week',
  'text',
  'number',
  'email',
  'password',
  'search',
  'tel',
  'url',
  'time',
];

@Directive({
  selector: 'input[sbInput], textarea[sbInput]',
  standalone: true,
})
export class InputDirective extends InputBaseDirective {
  @HostBinding('class')
  hostClass = 'read-only:bg-grey-200';

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }

  private _readonly = false;

  @HostBinding('attr.readonly')
  get showReadonly() {
    return this.readonly || null;
  }

  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value || 'text';

    if (!this.isTextarea && inputTypes.includes(this._type)) {
      (this.elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }

  protected _type = 'text';

  @HostBinding('class.sb-textarea')
  private isTextarea: boolean;

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;

    if (this._placeholder) {
      (this.elementRef.nativeElement as HTMLInputElement).placeholder = this._placeholder;
    }
  }
  get placeholder(): string {
    return this._placeholder;
  }
  protected _placeholder = '';

  @HostBinding('required')
  get attachRequired() {
    return this.required;
  }

  constructor(
    @Optional() @Self() ngControl: NgControl,
    protected elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    super(ngControl);

    const nodeName = elementRef.nativeElement.nodeName.toLowerCase();

    this.isTextarea = nodeName === 'textarea';
  }
}
