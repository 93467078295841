import { AfterContentInit, ContentChild, Directive, HostBinding, inject, Input, OnDestroy } from '@angular/core';
import { clsx } from 'clsx';

import { SidebarAccordionBodyComponent } from './sidebar-accordion-body.component';
import { SidebarItemComponent } from './sidebar-item.component';
import { SB_SIDEBAR } from './sidebar.token';

let id = 0;

@Directive({
  selector: '[sbSidebarAccordionItem], [sb-sidebar-accordion-item], sb-sidebar-accordion-item',
  exportAs: 'sbSidebarAccordionItem',
  standalone: true,
})
export class SidebarAccordionItemDirective implements AfterContentInit {
  private sidebar = inject(SB_SIDEBAR, { optional: true });

  @ContentChild(SidebarItemComponent)
  readonly item?: SidebarItemComponent;

  @ContentChild(SidebarAccordionBodyComponent)
  readonly body?: SidebarAccordionBodyComponent;

  @HostBinding('class')
  class = clsx('group/accordion-item');

  @Input()
  @HostBinding('id')
  id = `sidebar-accordion-item-${id++}`;

  @Input()
  @HostBinding('attr.aria-expanded')
  set expanded(value: boolean) {
    if (value === this._expanded) {
      return;
    }
    this._expanded = value;
    this.setItemState();
  }

  get expanded(): boolean {
    return this._expanded;
  }

  private _expanded = false;

  @HostBinding('attr.aria-controls')
  get ariaControls() {
    return this.body?.id;
  }

  ngAfterContentInit(): void {
    if (this.body) {
      this.body.ariaLabelledby = this.id;
    }

    this.item?.registerAccordionItem(this);
    this.sidebar?.registerAccordionItem(this);
    this.setItemState();
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  setItemState() {
    if (this.body) {
      this.body.expanded = this.expanded;
    }
    if (this.item) {
      this.item.active = this.expanded;
    }
  }
}
