import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sbSidebarList], [sb-sidebar-list], sb-sidebar-list',
  exportAs: 'sbSidebarList',
  standalone: true,
})
export class SidebarListDirective {
  @HostBinding('class')
  hostClass = clsx(['m-0 mb-9 flex list-none flex-col gap-3.5 p-0 last:mb-0']);
}
