import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { sbIcons } from '@sb/svg-icons';

import { ButtonThemePalette, buttonThemePalette } from '../button';

// TODO either this or AbsenceStatus in absence.model.ts should be removed
export type StatusType = 'Approved' | 'Declined' | 'Pending';

export type StatusMapping = {
  [key in StatusType]: {
    icon: sbIcons;
    button: buttonThemePalette;
    label: string;
  };
};

export const statusMapping: StatusMapping = {
  Approved: {
    icon: 'check',
    button: ButtonThemePalette.green,
    label: _('Approved'),
  },
  Declined: {
    icon: 'times',
    button: ButtonThemePalette.red,
    label: _('Declined'),
  },
  Pending: {
    icon: 'ellipsis-h',
    button: ButtonThemePalette.grey,
    label: _('Pending'),
  },
};
