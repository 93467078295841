<span
  class="flex items-center justify-center"
  [ngClass]="{
    'h-5 w-5': size === 'base',
    'h-3 w-3': size === 'sm'
  }"
>
  <ng-content></ng-content>
</span>
<ng-content select="icon[iconPositionEnd], sb-icon[iconPositionEnd], sb-spinner[iconPositionEnd]"></ng-content>
