import { NgModule } from '@angular/core';
import { TreeComponent } from './tree.component';
import { TreeNodeDirective } from './tree-node.directive';

const standalone = [TreeComponent, TreeNodeDirective];

@NgModule({
  imports: [...standalone],
  exports: [...standalone],
})
export class TreeModule {}
