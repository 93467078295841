import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { sbIcons } from '@sb/svg-icons';

import { IconComponent } from '../icon';
import { InputIconPrefixDirective } from './input-icon-prefix.directive';

@Component({
  selector: 'sb-input-icon',
  standalone: true,
  imports: [IconComponent, InputIconPrefixDirective, NgIf],
  templateUrl: './input-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputIconComponent {
  @HostBinding('class')
  readonly hostClass = 'inline-block w-full relative [&>input:not(:last-child)]:pr-9 [&>input:not(:first-child)]:pl-9';
  @Input()
  icon?: sbIcons;
}
