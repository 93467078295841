import { FocusableOption } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';
import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, inject, Input, Output } from '@angular/core';

import { dropdownHostClasses } from './dropdown-base-item.directive';
import { MENU_TRIGGER } from './dropdown-toggle.directive';

@Directive({
  selector: '[sbDropdownItem]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    ...dropdownHostClasses,
    '[attr.disabled]': 'disabled || null',
    '[attr.aria-disabled]': 'disabled || null',
    '[tabindex]': '0',
  },
})
export class DropdownItemDirective implements FocusableOption {
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private readonly parentTrigger = inject(MENU_TRIGGER, { optional: true });

  @HostBinding('class.cursor-pointer')
  cursorPointer = true;

  @Input()
  set sbDropdownItemDisabled(value: BooleanInput) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this.disabled) {
      this.disabled = newValue;
    }
  }

  // implemented as part of FocusableOption
  disabled = false;

  @Output('sbDropdownItemTriggered')
  triggered = new EventEmitter<void>();

  @Input()
  selected = false;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (this.disabled) {
      return;
    }
    switch (event.keyCode) {
      case SPACE:
      case ENTER:
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this.trigger({ keepOpen: event.keyCode === SPACE });
        }
    }
  }

  @HostListener('click')
  trigger(options?: { keepOpen: boolean }) {
    const { keepOpen } = { ...options };
    if (!this.disabled) {
      this.triggered.emit();

      if (!keepOpen) {
        this.parentTrigger?.close({ focusTrigger: true });
      }
    }
  }

  // implemented as part of FocusableOption
  focus(): void {
    this.elementRef.nativeElement.focus();
  }
}
