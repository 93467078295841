import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[sbLegacyDialogActions], [sb-legacy-dialog-actions], sb-legacy-dialog-actions',
  standalone: true,
})
export class DialogActionsDirective implements OnChanges {
  private readonly baseClass = 'flex space-x-2 p-3';

  @HostBinding('class')
  class = this.baseClass;

  @Input()
  align: 'start' | 'end' = 'start';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['align']) {
      this.setClass();
    }
  }

  private setClass(): void {
    if (this.align === 'start') {
      this.class = this.baseClass;
    } else {
      this.class = `${this.baseClass} justify-end`;
    }
  }
}
