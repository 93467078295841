import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input, Optional, Self } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { clsx } from 'clsx';

let nextUniqueId = 0;

@Directive({
  standalone: true,
  selector: '[sbInputBase]',
})
export class InputBaseDirective {
  @HostBinding('class')
  baseClasses = clsx([
    'block w-full appearance-none rounded-base bg-white',
    'px-2.5 py-1.5',
    'text-base text-grey-600 placeholder:text-grey-400',
    'border border-solid border-grey-200',
    'focus:border-primary-500 focus:outline-none',
    'disabled:bg-grey-200',
    'aria-invalid:border-red-500 aria-invalid:focus:border-red-500',
  ]);

  @Input()
  @HostBinding('id')
  set id(value: string) {
    this._id = value || this._id;
  }
  get id(): string {
    return this._id;
  }
  protected _id = `sb-input-${nextUniqueId++}`;

  @Input()
  @HostBinding('disabled')
  get disabled(): boolean {
    // ngcontrol disabled state takes precedence over input property
    return this.ngControl?.disabled || this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  protected _disabled = false;

  @Input()
  name?: string;

  @HostBinding('attr.name')
  get showName() {
    return this.name || null;
  }

  @Input()
  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  protected _required: boolean | undefined;

  @Input()
  @HostBinding('attr.aria-invalid')
  set hasError(value) {
    this._hasError = coerceBooleanProperty(value);
  }
  get hasError() {
    return this._hasError;
  }
  protected _hasError: boolean | undefined;

  constructor(@Optional() @Self() protected ngControl: NgControl) {}
}
