import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[sbDialogHeaderActions], [sb-dialog-header-actions], sb-dialog-header-actions',
  exportAs: 'sbDialogHeaderActions',
})
export class SbDialogHeaderActionsDirective {
  @HostBinding('class.sb-dialog-header-actions')
  readonly hostclass = true;
}
