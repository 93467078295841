import { AfterContentInit, Directive, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  standalone: true,
  selector: 'form[sbDialogForm], form[sb-dialog-form]',
  exportAs: 'sbDialogForm',
})
export class SbDialogFormDirective implements AfterContentInit, OnDestroy {
  @HostBinding('class')
  readonly hostClasses = clsx('flex flex-col gap-4');

  @HostBinding('attr.novalidate')
  readonly novalidate = '';

  private hiddenSubmitElement?: HTMLButtonElement;

  constructor(private readonly elementRef: ElementRef<HTMLFormElement>) {}

  ngAfterContentInit(): void {
    this.attachHiddenSubmit();
  }

  ngOnDestroy(): void {
    this.detachHiddenSubmit();
  }

  private attachHiddenSubmit(): void {
    this.hiddenSubmitElement = this.createHiddenSubmit();
    this.elementRef.nativeElement.appendChild(this.hiddenSubmitElement);
  }

  private createHiddenSubmit(): HTMLButtonElement {
    const submitButton = document.createElement('button');
    submitButton.type = 'submit';
    submitButton.classList.add('sr-only');
    return submitButton;
  }

  private detachHiddenSubmit(): void {
    if (this.hiddenSubmitElement) {
      this.hiddenSubmitElement.remove();
    }
  }
}
