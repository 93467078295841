import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { clsx } from 'clsx';

import { IconComponent } from '../../icon';
import { SpinnerComponent } from '../../spinner';
import { statusMapping, StatusType } from '../status-mapping.helper';

@Component({
  selector: 'sb-status-icon',
  standalone: true,
  imports: [CommonModule, IconComponent, SpinnerComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './status-icon.component.html',
})
export class StatusIconComponent {
  readonly statusMapping = statusMapping;

  @HostBinding('class')
  get statusClass() {
    return clsx(
      ['inline-flex h-8.5 w-8.5 items-center justify-center rounded-base'],
      this.status === 'Approved' && ['bg-green-500', 'text-white'],
      this.status === 'Declined' && ['bg-red-500', 'text-white'],
      this.status === 'Pending' && ['bg-grey-200', 'text-grey-500'],
    );
  }

  @Input()
  status: StatusType = 'Pending';
  @Input()
  saving = false;
}
