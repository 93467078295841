import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-button-group',
  standalone: true,
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent {
  @HostBinding('class')
  hostClasses = clsx([
    'relative inline-flex align-middle',
    'children-not-first-child:rounded-l-none',
    'children-not-last-child:rounded-r-none',
    'children-not-first-child:-ml-px',
    'children-focus:z-1',
  ]);
}
