import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CheckboxBaseComponent, CheckboxChangeEvent } from '../checkbox/checkbox-base.component';
import { IconComponent } from '../icon';

export const SB_CIRCLE_CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxCircleComponent),
  multi: true,
};

let nextUniqueId = 0;

@Component({
  selector: 'sb-checkbox-circle',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './checkbox-circle.component.html',
  providers: [SB_CIRCLE_CHECKBOX_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxCircleComponent extends CheckboxBaseComponent {
  protected override _uniqueId;

  @Input()
  id: string;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change: EventEmitter<CheckboxChangeEvent<CheckboxCircleComponent>> = new EventEmitter<
    CheckboxChangeEvent<CheckboxCircleComponent>
  >();
  // for this case native output is expected

  @ViewChild('input')
  inputElement!: ElementRef<HTMLInputElement>;

  @ViewChild('label')
  labelElement!: ElementRef<HTMLInputElement>;

  constructor(protected override cd: ChangeDetectorRef) {
    super(cd);
    this.id = this._uniqueId = `sb-checkbox-circle-${++nextUniqueId}`;
  }
}
