import {
  booleanAttribute,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export interface CheckboxChangeEvent<T = unknown> {
  source: T;
  checked: boolean;
}

@Directive()
export abstract class CheckboxBaseComponent implements ControlValueAccessor {
  @HostBinding('class')
  hostClass = 'inline-block relative';

  protected abstract _uniqueId: string;

  @Input()
  abstract id: string;

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  @Input({ transform: booleanAttribute })
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (value != this.checked) {
      this._checked = value;
      this.cd.markForCheck();
    }
  }
  private _checked = false;

  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (value !== this.disabled) {
      this._disabled = value;
      this.cd.markForCheck();
    }
  }
  private _disabled = false;

  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract readonly change: EventEmitter<any>;

  @ViewChild('input')
  abstract inputElement: ElementRef<HTMLInputElement>;

  @ViewChild('label')
  abstract labelElement: ElementRef<HTMLInputElement>;

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  public onChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.  Called when the checkbox is blurred.  Needed to properly implement ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onTouched: () => any = () => {};

  constructor(protected cd: ChangeDetectorRef) {}

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.checked = !!obj;
  }

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onBlur(): void {
    // See https://github.com/angular/angular/issues/17793
    Promise.resolve().then(() => {
      this.onTouched();
      this.cd.markForCheck();
    });
  }

  onTouchTargetClick(): void {
    if (this.disabled) {
      return;
    }

    this.onInputClick();
    this.inputElement.nativeElement.focus();
  }

  onInputClick(): void {
    if (this.disabled) {
      return;
    }

    this.toggle();
  }

  onInteractionEvent(event: Event): void {
    // We always have to stop propagation on change to prevent the change event from bubbling.
    event.stopPropagation();
  }

  toggle(): void {
    if (this.disabled) {
      return;
    }

    this.checked = !this.checked;
    this.onChange(this.checked);
    this.change.emit({ checked: this.checked, source: this });

    // To ensure sync with the native checkbox
    if (this.inputElement) {
      this.inputElement.nativeElement.checked = this.checked;
    }
  }
}
