<div class="flex items-center align-middle text-base font-normal" [ngClass]="{ 'opacity-60': disabled }">
  <input
    class="peer sr-only"
    #input
    type="checkbox"
    [id]="inputId"
    [checked]="checked"
    [indeterminate]="indeterminate"
    [disabled]="disabled"
    (blur)="onBlur()"
    (click)="onInputClick()"
    (change)="onInteractionEvent($event)"
  />
  <div
    (click)="onTouchTargetClick()"
    class="absolute left-0 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer peer-disabled:cursor-default"
  ></div>
  <div
    class="pointer-events-none relative h-4 w-4 shrink-0 select-none rounded-base border border-solid border-grey-300 peer-focus-visible:border-primary-500 peer-aria-invalid:border-red-500"
  >
    <sb-icon
      *ngIf="checked || indeterminate"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      [size]="'sm'"
      [name]="indeterminate ? 'minus' : 'check'"
    ></sb-icon>
  </div>
  <label
    [for]="inputId"
    #label
    [ngClass]="{ truncate: truncateLabel }"
    class="m-0 grow cursor-pointer pl-1.5 empty:hidden empty:select-none peer-disabled:cursor-default peer-aria-invalid:text-red-500"
    ><ng-content></ng-content
  ></label>
</div>
