import { BreakpointObserver as CdkBreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserver extends CdkBreakpointObserver {
  observeMatched(query: string): Observable<boolean> {
    return this.observe(query).pipe(map((state) => state.matches));
  }

  observeAllMatched(queries: string[]): Observable<boolean> {
    return this.observe(queries).pipe(
      map((state) => Object.keys(state.breakpoints).every((key) => state.breakpoints[key])),
    );
  }

  observeMinMaxMatched(min: string, max: string): Observable<boolean> {
    return this.observe(`${min} and ${max}`).pipe(map((state) => state.matches));
  }
}
