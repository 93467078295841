import { NgModule } from '@angular/core';
import { FormGroupPrefixDirective } from './form-group-prefix.directive';
import { FormGroupSuffixDirective } from './form-group-suffix.directive';
import { FormGroupComponent } from './form-group.component';

const standalone = [FormGroupComponent, FormGroupPrefixDirective, FormGroupSuffixDirective];

@NgModule({
  imports: [...standalone],
  exports: [...standalone],
})
export class FormGroupModule {}
