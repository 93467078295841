import { CommonModule } from '@angular/common';
import { Component, ElementRef, InjectionToken, Input } from '@angular/core';
import { sbIcons } from '@sb/svg-icons';

import {
  ButtonComponent,
  ButtonGroupComponent,
  ButtonThemePalette,
  buttonThemePalette,
  IconButtonComponent,
} from '../button';
import { DropdownModule, DropdownPlacement, dropdownPlacement } from '../dropdown';
import { IconComponent } from '../icon';
import { SplitButtonMenuItem } from './split-button.model';

export const SB_SPLIT_BUTTON = new InjectionToken<SplitButtonComponent>('SbSplitButton');

@Component({
  selector: 'sb-split-button',
  templateUrl: './split-button.component.html',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ButtonGroupComponent, IconComponent, IconButtonComponent, DropdownModule],
  providers: [{ provide: SB_SPLIT_BUTTON, useExisting: SplitButtonComponent }],
})
export class SplitButtonComponent {
  @Input()
  public menuItems: SplitButtonMenuItem[] = [];
  @Input()
  public color: buttonThemePalette = ButtonThemePalette.primary;
  @Input()
  public icon: sbIcons | null = 'plus';
  @Input()
  public disabled = false;
  @Input()
  public menuPlacement: dropdownPlacement = DropdownPlacement['bottom-right'];

  public constructor(public elementRef: ElementRef) {}
}
