import { NgModule } from '@angular/core';

import { SbDialogCloseDirective } from './dialog-close.directive';
import { SbDialogContentDirective } from './dialog-content.directive';
import { SbDialogFooterActionsDirective } from './dialog-footer-actions.directive';
import { SbDialogFooterDirective } from './dialog-footer.directive';
import { SbDialogFormDirective } from './dialog-form.directive';
import { SbDialogHeaderActionsDirective } from './dialog-header-actions.directive';
import { SbDialogHeaderComponent } from './dialog-header/dialog-header.component';
import { SbDialogTitleDirective } from './dialog-title.directive';

const standalone = [
  SbDialogCloseDirective,
  SbDialogHeaderComponent,
  SbDialogHeaderActionsDirective,
  SbDialogTitleDirective,
  SbDialogContentDirective,
  SbDialogFooterDirective,
  SbDialogFooterActionsDirective,
  SbDialogFormDirective,
];

@NgModule({
  imports: standalone,
  exports: standalone,
})
export class SbDialogSharedModule {}
