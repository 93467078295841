import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TooltipModule, TooltipPlacement } from '@sb/tooltip';

import { LegendComponent } from '../legend/legend.component';
import { HorizontalStackedBarTooltipItemComponent } from './horizontal-stacked-bar-tooltip-item/horizontal-stacked-bar-tooltip-item.component';

export interface StackedBarChartRestItem {
  label: string;
  tooltipText: string;
  color?: string;
}

export interface StackedBarChartItem extends StackedBarChartRestItem {
  id: string;
  percentage: number;
  color: string;
}

@Component({
  selector: 'sb-horizontal-stacked-bar-chart',
  templateUrl: './horizontal-stacked-bar-chart.component.html',
  standalone: true,
  imports: [CommonModule, TooltipModule, HorizontalStackedBarTooltipItemComponent, LegendComponent],
})
export class HorizontalStackedBarChartComponent {
  @Input({ transform: (value: StackedBarChartItem[]) => value || [] })
  public items: StackedBarChartItem[] = [];

  @Input()
  public restItem?: StackedBarChartRestItem;

  public tooltipPlacement = TooltipPlacement.LEFT;

  public trackByIdFn(_index: number, item: StackedBarChartItem): string {
    return item.id;
  }
}
