<div class="flex items-center space-x-2">
  <ng-content></ng-content>
</div>
<button
  *ngIf="showClose"
  class="-mr-2 ml-2"
  (click)="dialogRef.close()"
  sb-icon-button
  [color]="'primary'"
  [size]="'base'"
>
  <sb-icon [name]="'times'"></sb-icon>
</button>
