import { NgIf } from '@angular/common';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-popover',
  standalone: true,
  templateUrl: './popover.component.html',
  styleUrls: ['popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.sb-popover]': 'true',
  },
})
export class PopoverComponent {
  @HostBinding('class')
  hostClass = clsx(
    ['relative block rounded-base bg-white p-4 drop-shadow-lg'],
    ['border border-solid border-grey-200'],
    ['text-base font-normal text-word-mark-800'],
    ['break-words'],
  );

  @Input()
  title?: string;

  @HostBinding('style.maxWidth')
  @Input()
  maxWidth = '20rem';
}
