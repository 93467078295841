import { DialogRef } from '@angular/cdk/dialog';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Optional } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { IconButtonComponent } from '../../../button';
import { IconComponent } from '../../../icon';
import { SbDialogCloseDirective } from '../dialog-close.directive';

@Component({
  selector: 'sb-dialog-header',
  exportAs: 'sbDialogHeader',
  standalone: true,
  imports: [IconButtonComponent, IconComponent, NgIf, NgClass, SbDialogCloseDirective, TranslateModule],
  templateUrl: './dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbDialogHeaderComponent {
  @HostBinding('class.sb-dialog-header')
  readonly hostClass = true;

  constructor(@Optional() public dialogRef: DialogRef<unknown>) {}

  get showClose(): boolean {
    return !this.dialogRef?.disableClose;
  }
}
