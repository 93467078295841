import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'sb-calendar-week',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './calendar-week.component.html',
})
export class CalendarWeekComponent {}
