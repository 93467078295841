import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sb-progress-bar',
  templateUrl: './progress-bar.component.html',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input()
  public title?: string;
  @Input()
  public subtitle?: string;
  @Input()
  public level!: number;
}
