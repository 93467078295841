import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { getDayNames, getMonthNames } from '@shiftbase-com/utilities';
import { clsx } from 'clsx';
import { isSameDay, isSameMonth, startOfMonth } from 'date-fns';

import { IconButtonComponent } from '../button';
import { IconComponent } from '../icon';
import { CalendarDayComponent } from './calendar-day.component';
import { CalendarNavigationComponent } from './calendar-navigation.component';
import { CalendarWeekComponent } from './calendar-week.component';
import { CalendarMonth, CalendarWeek } from './calendar.types';

// We have to define some Tailwind grid-cols class explicitely because we can't use dynamic values
const gridCols = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
};

type GridCols = keyof typeof gridCols;

// Tailwind by default generates the above grid-cols classes
// Make sure months is between 1 and 7
function clamp(value: number) {
  return Math.max(1, Math.min(7, value));
}

@Component({
  selector: 'sb-calendar',
  standalone: true,
  imports: [
    CommonModule,
    IconButtonComponent,
    IconComponent,
    CalendarNavigationComponent,
    CalendarWeekComponent,
    CalendarDayComponent,
  ],
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit, OnChanges {
  public monthCalendars: CalendarMonth[] = [];

  public weekDays: string[] = [];
  public monthNames: string[] = [];

  public calendarClasses = '';
  public monthClasses = '';

  @Input({
    transform: clamp,
  })
  public months = 1;

  @Input()
  public date = new Date();

  @Input()
  public minDate: Date | undefined;

  @Input()
  public maxDate: Date | undefined;

  @Input()
  public showWeekdays = true;

  @Input()
  public showStacked = false;

  @Input()
  public locale = 'en';

  @Output()
  public dateChange: EventEmitter<Date> = new EventEmitter();

  @ContentChild('dayTemplate', { static: false })
  public dayTemplate: TemplateRef<any> | undefined;

  public ngOnInit(): void {
    this.setCalendarTerms();
    this.setClasses();

    this.monthCalendars = this.getCalendars(this.date);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['months'] || changes['date']) {
      this.monthCalendars = this.getCalendars(this.date);
    }
    if (changes['showStacked'] || changes['months']) {
      this.setClasses();
    }
    if (changes['locale']) {
      this.setCalendarTerms();
    }
  }

  public navigate(event: 'previous' | 'next') {
    event === 'previous' ? this.previousMonth() : this.nextMonth();
    this.monthCalendars = this.getCalendars(this.date);
    this.dateChange.emit(this.date);
  }

  private previousMonth() {
    this.date.setMonth(this.date.getMonth() - 1);
  }

  private nextMonth() {
    this.date.setMonth(this.date.getMonth() + 1);
  }

  private getCalendars(date: Date): CalendarMonth[] {
    date = new Date(date);
    const calendars = [];
    for (let i = 0; i < this.months; i++) {
      calendars.push({
        name: this.monthNames[date.getMonth()],
        monthYear: date.getFullYear(),
        isFirstCalendar: i === 0,
        isLastCalendar: i === this.months - 1,
        containsMinDate: this.minDate ? isSameMonth(date, this.minDate) : false,
        containsMaxDate: this.maxDate ? isSameMonth(date, this.maxDate) : false,
        weeks: this.getMonthDays(date),
      });
      date.setMonth(date.getMonth() + 1);
    }
    return calendars;
  }

  private setClasses() {
    this.calendarClasses = this.showStacked
      ? clsx('grid-cols-1 divide-x-0 divide-y')
      : clsx(['divide-x divide-y-0', gridCols[this.months as GridCols]]);

    this.monthClasses = this.showStacked ? clsx('py-4 first:pt-0 last:pb-0') : clsx('px-6 first:pl-0 last:pr-0');
  }

  private setCalendarTerms() {
    this.weekDays = getDayNames(this.locale);
    this.monthNames = getMonthNames(this.locale);
  }

  private getMonthDays(monthDate: Date): CalendarWeek[] {
    const date = startOfMonth(monthDate);

    // Get weekday with Monday as first day of the week
    const getDay = (date: Date) => {
      let day = date.getDay();
      if (day === 0) day = 7;
      return day - 1;
    };

    const dateIsToday = (date: Date) => isSameDay(date, new Date());

    const calendar: CalendarWeek[] = [[]];

    // Pad the first week with empty days until the first day of the month
    for (let i = 0; i < getDay(date); i++) {
      const previousMonthDate = new Date(date);
      previousMonthDate.setDate(previousMonthDate.getDate() - (getDay(date) - i));

      calendar[calendar.length - 1].push({
        monthDay: previousMonthDate.getDate(),
        isToday: dateIsToday(previousMonthDate),
        isOutOfMonth: true,
        date: previousMonthDate,
      });
    }

    while (isSameMonth(monthDate, date)) {
      calendar[calendar.length - 1].push({
        monthDay: date.getDate(),
        isToday: dateIsToday(date),
        isOutOfMonth: false,
        date: new Date(date),
      });

      date.setDate(date.getDate() + 1);
      if (!isSameMonth(monthDate, date)) break;

      if (getDay(date) % 7 === 0) {
        calendar.push([]);
      }
    }

    // Pad the last week with empty days until the end of the week
    if (getDay(date) != 0) {
      for (let i = getDay(date); i < 7; i++) {
        const nextMonthDate = new Date(date);
        nextMonthDate.setDate(nextMonthDate.getDate() + (i - getDay(date)));
        calendar[calendar.length - 1].push({
          monthDay: nextMonthDate.getDate(),
          isToday: dateIsToday(nextMonthDate),
          isOutOfMonth: true,
          date: nextMonthDate,
        });
      }
    }

    return calendar;
  }
}
