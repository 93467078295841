import { ContentChild, Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

import { CheckboxComponent } from '../checkbox';
import { dropdownHostClasses } from './dropdown-base-item.directive';
import { DropdownItemDirective } from './dropdown-item.directive';

@Directive({
  selector: '[sbDropdownItemCheckbox]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    ...dropdownHostClasses,
    role: 'menuitemcheckbox',
    '[attr.aria-checked]': '!!checked',
    '[attr.aria-disabled]': 'isDisabled || null',
    '[attr.disabled]': 'null',
  },
  providers: [{ provide: DropdownItemDirective, useExisting: DropdownItemCheckboxDirective }],
})
export class DropdownItemCheckboxDirective extends DropdownItemDirective {
  @HostBinding('class')
  hostClasses = clsx('children-checkbox:grow children-checkbox:overflow-hidden');

  get checked(): boolean {
    return this.checkbox?.checked ?? false;
  }
  get isDisabled(): boolean {
    return this.checkbox ? this.checkbox?.disabled : this.disabled;
  }

  @ContentChild(CheckboxComponent, { static: true })
  private checkbox?: CheckboxComponent;

  // todo inner set checkbox tab index -1 to prevent double focus while tabbing

  override trigger(options?: { keepOpen: boolean }) {
    // should not trigger on click only on keyboard events
    if (!options) {
      return;
    }
    if (this.checkbox) {
      this.checkbox.onInputClick();
    }
    super.trigger({ ...options, keepOpen: true });
  }

  constructor() {
    super();

    this.cursorPointer = false;
  }
}
