import { CommonModule } from '@angular/common';
import { Component, forwardRef, Provider, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IconButtonComponent } from '../button';
import { DropdownModule, DropdownToggleDirective } from '../dropdown';
import { IconComponent } from '../icon/icon.component';

export const ICON_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IconPickerComponent),
  multi: true,
};

// legacy icon options needed to match backend icons
// if we do https://differentlab.atlassian.net/browse/SHB-4536 to fix the naming this can be updated
// if this component is ever used for other places the options should be set with input or a provider
export const ICON_PICKER_DEFAULT_OPTIONS = [
  'leave-1',
  'leave-2',
  'leave-4',
  'leave-5',
  'leave-6',
  'ban',
  'bell',
  'book',
  'bullhorn',
  'calculator',
  'calendar',
  'calendar-day',
  'calendar-group',
  'calendar-month',
  'calendar-week',
  'calendar_approved',
  'calendar_denied',
  'car',
  'change_shift',
  'coffee',
  'comment',
  'clipboard',
  'cutlery',
  'exclamation-triangle',
  'delete',
  'eye',
  'flag',
  'info',
  'map-marker',
  'paper-plane',
  'payment',
  'reclock',
  'team',
  'stopwatch',
  'tour',
  'zoom',
  'overtime',
  'ratecard',
];

@Component({
  selector: 'sb-icon-picker',
  standalone: true,
  providers: [ICON_INPUT_CONTROL_VALUE_ACCESSOR],
  imports: [CommonModule, IconComponent, DropdownModule, IconButtonComponent],
  templateUrl: './icon-picker.component.html',
})
export class IconPickerComponent implements ControlValueAccessor {
  @ViewChild(DropdownToggleDirective)
  private dropdownToggle!: DropdownToggleDirective;

  public icons = ICON_PICKER_DEFAULT_OPTIONS;
  public icon = 'leave-1';

  public disabled = false;

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.  Called when the checkbox is blurred.  Needed to properly implement ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onTouched: () => any = () => {};

  onChangeIconInput(value: string): void {
    if (this.icon !== value) {
      this.onTouched();
      this.icon = value;
      this.onChange(value);
    }
    this.dropdownToggle.close();
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string) {
    this.icon = value;
  }
}
