import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DOCUMENT, NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  Optional,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { clsx } from 'clsx';
import { filter, fromEvent, Observable } from 'rxjs';

@Component({
  selector: 'sb-sidebar-drawer',
  exportAs: 'sbSidebarDrawer',
  standalone: true,
  imports: [NgClass, CdkScrollable],
  templateUrl: './sidebar-drawer.component.html',
  animations: [
    trigger('visibility', [
      state('open, open-instant', style({ visibility: '' })),
      state('void', style({ visibility: 'hidden' })),
      transition('void => open-instant, void => open', animate('0ms')),
      transition('open => void, open-instant => void', animate('500ms ease-in-out')),
    ]),
  ],
})
export class SidebarDrawerComponent implements AfterViewInit {
  @HostBinding('tabIndex')
  tabIndex = -1;

  @HostBinding('class')
  hostClass = clsx('relative z-50');

  @HostBinding('@visibility')
  animationState: 'open-instant' | 'open' | 'void' = 'void';

  @HostListener('@visibility.done', ['$event'])
  animationDone(event: AnimationEvent) {
    if (this.isOpen && event.toState !== 'open-instant') {
      this.focusFirstElement();
    }
  }

  @Input()
  set isOpen(value: boolean) {
    this.setIsOpen(value);
  }
  get isOpen(): boolean {
    return this._isOpen;
  }
  private _isOpen = false;

  private doAnimations = false;

  private activeFocusBeforeOpen: HTMLElement | null = null;

  constructor(
    ngZone: NgZone,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(DOCUMENT) private document: any,
  ) {
    // should close on escape inside the drawer
    ngZone.runOutsideAngular(() => {
      (fromEvent(elementRef.nativeElement, 'keydown') as Observable<KeyboardEvent>)
        .pipe(
          filter((event) => {
            return event.keyCode === ESCAPE && !hasModifierKey(event);
          }),
          takeUntilDestroyed(),
        )
        .subscribe((event) =>
          ngZone.run(() => {
            this.close();
            event.stopPropagation();
            event.preventDefault();
          }),
        );
    });
  }

  ngAfterViewInit(): void {
    this.doAnimations = true;
  }

  toggle(): void {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open(): void {
    if (this.isOpen) {
      return;
    }

    // store active focus so we can return to it when closing the drawer
    this.activeFocusBeforeOpen = this.document.activeElement as HTMLElement;

    this.setIsOpen(true);
  }

  close(): void {
    if (!this.isOpen) {
      return;
    }

    this.setIsOpen(false);

    // on close set focus back to the element that was focused before opening the drawer
    this.activeFocusBeforeOpen?.focus();
    this.activeFocusBeforeOpen = null;
  }

  private setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen;

    if (isOpen) {
      this.animationState = this.doAnimations ? 'open' : 'open-instant';
      return;
    }

    this.animationState = 'void';
  }

  private focusFirstElement(): void {
    const focusElements = this.getFocusableElements();
    focusElements[0]?.focus();
  }

  private getFocusableElements(): NodeListOf<HTMLElement> {
    return this.elementRef.nativeElement.querySelectorAll(
      'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)',
    );
  }
}
