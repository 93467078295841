import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sbPopoverFooter], sb-popover-footer',
  standalone: true,
})
export class PopoverFooterDirective {
  @HostBinding('class')
  hostClass = clsx('flex items-center justify-end gap-3');
}
