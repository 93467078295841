import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { StackedBarChartRestItem } from '../horizontal-stacked-bar-chart.component';

@Component({
  selector: 'sb-horizontal-stacked-bar-tooltip-item',
  templateUrl: './horizontal-stacked-bar-tooltip-item.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class HorizontalStackedBarTooltipItemComponent {
  @Input()
  public item!: StackedBarChartRestItem;
}
