import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DialogTab } from '../../dialog/dialog.models';

@Component({
  selector: 'sb-dialog-tab-list',
  templateUrl: './dialog-tab-list.component.html',
  imports: [NgClass],
  standalone: true,
})
export class DialogTabListComponent {
  @Input()
  public tabs: DialogTab[] = [];
  @Input()
  public selectedTab?: DialogTab;
  @Output()
  public selected = new EventEmitter<DialogTab>();
}
