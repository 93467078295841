import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { DialogTab } from '../dialog/dialog.models';
import { DialogTabListComponent } from './dialog-tab-list/dialog-tab-list.component';

@Component({
  selector: 'sb-dialog-tabs',
  templateUrl: './dialog-tabs.component.html',
  imports: [DialogTabListComponent],
  standalone: true,
})
export class DialogTabsComponent implements OnChanges {
  @HostBinding('class')
  public class = 'flex h-full gap-4';

  @ViewChild('tabContentContainer', { static: true })
  public tabContentContainer!: ElementRef<HTMLDivElement>;

  @Input()
  public tabs: DialogTab[] = [];
  @Input()
  public selectedTab?: DialogTab;
  @Output()
  public tabSelected = new EventEmitter<DialogTab>();

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTab'] && this.selectedTab) {
      this.selectTab(this.selectedTab, false);
    }
  }

  public selectTab(selectedTab: DialogTab, emitEvent = true) {
    // Only one tab can be selected at a time
    this.tabs.forEach((tab) => (tab.isSelected = tab.id === selectedTab.id));

    if (emitEvent) {
      this.tabSelected.emit(this.tabs.find((tab) => tab.isSelected));
    }

    // Scroll container to the top
    this.tabContentContainer.nativeElement.scrollTop = 0;
  }
}
