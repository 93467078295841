<input
  #input
  class="peer sr-only"
  [type]="type"
  [id]="id"
  [checked]="checked"
  [attr.name]="name"
  [attr.value]="value"
  [disabled]="disabled"
  (change)="onInputChange($event)"
  (click)="onInputClick()"
/>
<label
  [for]="id"
  class="m-0 inline-flex cursor-pointer select-none items-center justify-center whitespace-nowrap rounded border border-solid border-grey-300 bg-white px-3 py-2 text-center text-base font-medium text-grey-500 outline-none outline-offset-2 hover:border-primary-400 hover:text-primary-500 peer-checked:border-primary-200 peer-checked:bg-primary-25 peer-checked:text-primary-500 peer-focus:border-primary-200 peer-focus:outline peer-focus:outline-2 peer-focus:outline-primary-500 peer-disabled:pointer-events-none peer-disabled:text-grey-600 peer-disabled:opacity-30"
>
  <ng-content></ng-content>
</label>
