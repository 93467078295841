import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[sbDialogContent], [sb-dialog-content], sb-dialog-content',
  exportAs: 'sbDialogContent',
})
export class SbDialogContentDirective {
  @HostBinding('class.sb-dialog-content')
  readonly hostclass = true;
}
