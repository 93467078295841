import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { clsx } from 'clsx';

export enum SpinnerMode {
  determinate = 'determinate',
  indeterminate = 'indeterminate',
}
export type spinnerMode = keyof typeof SpinnerMode;

export enum SpinnerSize {
  sm = 'sm', // 20px
  md = 'md', // 24px
  lg = 'lg', // 32px
  xl = 'xl', // 64px
}
export type spinnerSize = keyof typeof SpinnerSize;

const sizeMap: { [key: string]: string } = {
  [SpinnerSize.sm]: clsx('h-5 w-5'), // 20px
  [SpinnerSize.md]: clsx('h-6 w-6'), // 24px
  [SpinnerSize.lg]: clsx('h-8 w-8'), //32px
  [SpinnerSize.xl]: clsx('h-16 w-16'), // 64px
};

export enum SpinnerColor {
  primary = 'primary',
  grey = 'grey',
  inherit = 'inherit',
  current = 'current',
}
export type spinnerColor = keyof typeof SpinnerColor;

@Component({
  selector: 'sb-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnChanges {
  @Input()
  mode: spinnerMode = SpinnerMode.indeterminate;

  @Input()
  size: spinnerSize = SpinnerSize.md;

  @Input()
  strokeColor: spinnerColor = SpinnerColor.grey;

  @HostBinding('attr.role')
  role = 'progressbar';

  @HostBinding('attr.tabindex')
  tabIndex = -1;

  @HostBinding('attr.aria-valuemin')
  minValue = 0;

  @HostBinding('attr.aria-valuemax')
  maxValue = 100;

  @Input()
  @HostBinding('attr.aria-valuenow')
  set value(value: number) {
    this._value = this.clampValue(value);
  }
  get value() {
    return this._value;
  }
  private _value = 0;

  readonly radius = 45;

  strokeDashOffset?: number;
  strokeCircumference?: number;

  sizeClasses: string;

  constructor() {
    this.sizeClasses = sizeMap[SpinnerSize.md];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['size']) {
      this.sizeClasses = sizeMap[this.size] || sizeMap[SpinnerSize.md];
    }
    if (changes['mode'] || changes['value']) {
      this.setStrokeProps();
    }
  }

  private setStrokeProps(): void {
    if (this.mode !== SpinnerMode.determinate) {
      return;
    }

    this.strokeCircumference = 2 * Math.PI * this.radius;
    this.strokeDashOffset = (this.strokeCircumference * (100 - this.value)) / 100;
  }

  private clampValue(value: number): number {
    return value > this.maxValue ? this.maxValue : value < this.minValue ? this.minValue : value;
  }
}
