<div
  class="flex items-center gap-3 border-0 border-t border-solid border-grey-300 bg-grey-100 p-5 transition-transform duration-300 ease-out"
  [ngClass]="{
    'translate-y-full': !changeCount
  }"
>
  <button sb-button [color]="'primary'" (click)="save.emit()" [sbLadda]="saving" [disabled]="disabled">
    {{ 'Save' | translate }}
  </button>
  <button sb-button [color]="'secondary'" (click)="cancel.emit()" [disabled]="saving">
    {{ 'Cancel' | translate }}
  </button>
  <span class="ml-3 block text-base font-normal text-grey-500">
    {{
      changeCount === 1
        ? (statusText.one | translate: { amount: changeCount })
        : (statusText.more | translate: { amount: changeCount })
    }}
  </span>
</div>
