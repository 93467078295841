import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { IconComponent } from '../icon';
import { TreeNodeDirective } from './tree-node.directive';

export interface TreeItem<T = string | number> {
  text: string;
  value: T;
  collapsed?: boolean;
  children?: TreeItem<T>[];
}

@Component({
  selector: 'sb-tree',
  standalone: true,
  imports: [CommonModule, IconComponent, TreeNodeDirective],
  templateUrl: './tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeComponent {
  @HostBinding('class')
  hostClass = 'block';
  @HostBinding('attr.role')
  role = 'tree';
  @Output()
  toggleCollapse: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();

  @ContentChild('nodeTemplate', { static: false })
  set nodeTemplateRef(value: TemplateRef<any>) {
    if (!value) {
      return;
    }
    this.nodeTemplateInput = value;
  }

  @ContentChild('leafTemplate', { static: false })
  set leafTemplateRef(value: TemplateRef<any>) {
    if (!value) {
      return;
    }
    this.leafTemplateInput = value;
  }

  @Input()
  nodeTemplateInput: TemplateRef<any> | undefined;
  @Input()
  leafTemplateInput: TemplateRef<any> | undefined;

  @Input()
  collapsable = true;
  @Input()
  level = 0;
  @Input()
  items!: TreeItem[];

  onToggleCollapse(item: TreeItem): void {
    if (!this.collapsable) {
      return;
    }
    item.collapsed = !item.collapsed;
    this.toggleCollapse.emit(item);
  }

  trackByFn(_: number, item: TreeItem): string | number {
    return item.value;
  }
}
