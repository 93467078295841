<sb-button-group *ngIf="menuItems.length > 1 && menuItems[0].command; else singleButton">
  <button sb-button [color]="color" [disabled]="disabled" (click)="menuItems[0].command()">
    <sb-icon *ngIf="icon" [name]="icon"></sb-icon>
    {{ menuItems[0].label }}
  </button>
  <button
    sb-icon-button
    [color]="color"
    [disabled]="disabled"
    [sbDropdownToggle]="menu"
    [sbDropdownPlacement]="menuPlacement"
    [sbDropdownConnectedToRef]="elementRef"
    [sbDropdownHasBackdrop]="false"
  >
    <sb-icon [name]="'chevron-down'"></sb-icon>
  </button>
</sb-button-group>

<ng-template #menu>
  <div sbDropdownMenu>
    <ng-container *ngFor="let item of menuItems | slice: 1">
      <button
        *ngIf="!item.separator; else divider"
        sbDropdownItemIcon
        (sbDropdownItemTriggered)="item.command && item.command()"
        [sbDropdownItemDisabled]="item.disabled"
        [class]="item.styleClass"
        [icon]="item.icon"
      >
        {{ item.label }}
      </button>
      <ng-template #divider>
        <span sbDropdownDivider></span>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #singleButton>
  <button
    *ngIf="menuItems.length === 1; else singleButtonWithMenu"
    sb-button
    [color]="color"
    [disabled]="disabled"
    (click)="menuItems[0].command ? menuItems[0].command() : null"
  >
    <sb-icon *ngIf="icon" [name]="icon"></sb-icon>
    {{ menuItems[0].label }}
  </button>
</ng-template>

<ng-template #singleButtonWithMenu>
  <ng-container *ngIf="menuItems.length > 1">
    <button
      sb-button
      [color]="color"
      [disabled]="disabled"
      [sbDropdownToggle]="menu"
      [sbDropdownPlacement]="menuPlacement"
      [sbDropdownHasBackdrop]="true"
    >
      <sb-icon *ngIf="icon" [name]="icon"></sb-icon>
      {{ menuItems[0].label }}
      <sb-icon [name]="'chevron-down'" iconPositionEnd></sb-icon>
    </button>
  </ng-container>
</ng-template>
