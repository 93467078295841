<div
  (click)="onClick()"
  class="w-full gap-4 py-1.5 px-2.5 flex items-center rounded-base hover:bg-grey-50"
  [ngClass]="{ 'cursor-pointer': !isCompleted && !disabled }"
>
  <sb-icon
    class="shrink-0"
    [ngClass]="{
      'text-success-500': isCompleted,
      'text-grey-400': !isCompleted
    }"
    [name]="isCompleted ? 'check-circle' : 'circle'"
    [size]="'xl'"
  ></sb-icon>
  <span class="text-sm font-semibold grow" [ngClass]="{ 'text-grey-300': isCompleted, 'line-through': isCompleted }">
    {{ title }}
  </span>
  <sb-icon
    *ngIf="!isCompleted && !disabled"
    class="shrink-0 text-grey-400"
    [name]="'chevron-right'"
    [size]="'xs'"
  ></sb-icon>
</div>
