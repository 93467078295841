import { TemplateRef } from '@angular/core';

export enum DialogConfirmType {
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
}

export type dialogConfirmType = keyof typeof DialogConfirmType;

export interface DialogConfirmData {
  title: string;
  type?: dialogConfirmType;
  description?: string;
  contentTemplate?: TemplateRef<unknown>;
  primary?: {
    text: string;
    disabled?: boolean;
  };
  secondary?: {
    text: string;
  };
}

export interface DialogConfirmResult {
  confirmed: boolean;
}
