<div class="flex flex-1 justify-between sm:hidden">
  <button sb-button [color]="'secondary'" (click)="goToPage(currentPage - 1)" [disabled]="!canGoToPreviousPage">
    {{ 'Previous' | translate }}
  </button>
  <button sb-button [color]="'secondary'" (click)="goToPage(currentPage + 1)" [disabled]="!canGoToNextPage">
    {{ 'Next' | translate }}
  </button>
</div>
<div
  class="hidden sm:flex sm:flex-1 sm:items-center"
  [ngClass]="{ 'sm:justify-between': showCount, 'sm:justify-center': !showCount }"
>
  <div *ngIf="showCount">
    <p
      class="m-0 text-base text-grey-700 children:font-medium"
      [innerHTML]="
        'Showing {{fromValue}} to {{toValue}} of {{totalValue}} results'
          | translate
            : {
                fromValue: '<b>' + (itemsPerPage * currentPage - itemsPerPage + 1) + '</b>',
                toValue: '<b>' + itemsPerPage * currentPage + '</b>',
                totalValue: '<b>' + totalItems + '</b>'
              }
      "
    ></p>
  </div>

  <div class="inline-flex gap-0.5" aria-label="Pagination">
    <button
      sb-icon-button
      [color]="'transparent'"
      (click)="goToPage(currentPage - 1)"
      [disabled]="!canGoToPreviousPage"
    >
      <span class="sr-only">{{ 'Previous' | translate }}</span>
      <sb-icon [name]="'chevron-left'"></sb-icon>
    </button>

    <ng-container *ngFor="let page of pages; trackBy: trackByPage">
      <button
        *ngIf="page.type === 'page'; else ellipsis"
        sb-button
        [color]="page.value === currentPage ? 'primary' : 'transparent'"
        [clickable]="page.value !== currentPage"
        (click)="goToPage(page.value)"
        [attr.aria-current]="page.value === currentPage ? 'page' : null"
      >
        {{ page.label }}
      </button>
      <ng-template #ellipsis>
        <button sb-icon-button [clickable]="false" [color]="'transparent'">
          <sb-icon [name]="'ellipsis-h'"></sb-icon>
        </button>
      </ng-template>
    </ng-container>

    <button sb-icon-button [color]="'transparent'" (click)="goToPage(currentPage + 1)" [disabled]="!canGoToNextPage">
      <span class="sr-only">{{ 'Next' | translate }}</span>
      <sb-icon [name]="'chevron-right'"></sb-icon>
    </button>
  </div>
</div>
