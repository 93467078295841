import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BadgeComponent } from '../../badge/badge.component';
import { AnchorButtonComponent, ButtonComponent } from '../../button';
import { SbDialogSharedModule } from '../shared/dialog-shared.module';
import { DialogAnnouncementData, DialogAnnouncementResult } from './dialog-announcement.model';

@Component({
  selector: 'sb-dialog-announcement',
  standalone: true,
  imports: [
    SbDialogSharedModule,
    ButtonComponent,
    BadgeComponent,
    AnchorButtonComponent,
    NgStyle,
    NgClass,
    TranslateModule,
    NgTemplateOutlet,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dialog-announcement.component.html',
  styleUrls: ['./dialog-announcement.component.scss'],
})
export class DialogAnnouncementComponent {
  constructor(
    public dialogRef: DialogRef<DialogAnnouncementResult>,
    @Inject(DIALOG_DATA) public readonly data: DialogAnnouncementData,
  ) {}

  public onPrimaryAction() {
    this.dialogRef.close({ action: 'primary' });
  }
}
