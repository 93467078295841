import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { sbIcons } from '@sb/svg-icons';

import { ButtonComponent, ButtonThemePalette, buttonThemePalette } from '../../button';
import { IconComponent } from '../../icon';
import { SbDialogSharedModule } from '../shared/dialog-shared.module';
import { DialogConfirmData, DialogConfirmResult, DialogConfirmType, dialogConfirmType } from './dialog-confirm.model';

interface DialogConfirmLayout {
  buttonColor: buttonThemePalette;
  icon: sbIcons;
  iconColor: string;
}

@Component({
  selector: 'sb-dialog-confirm',
  standalone: true,
  imports: [SbDialogSharedModule, TranslateModule, NgClass, NgIf, NgTemplateOutlet, ButtonComponent, IconComponent],
  templateUrl: './dialog-confirm.component.html',
})
export class DialogConfirmComponent {
  type: dialogConfirmType;
  layout: DialogConfirmLayout;

  constructor(
    public dialogRef: DialogRef<DialogConfirmResult>,
    @Inject(DIALOG_DATA) public readonly data: DialogConfirmData,
  ) {
    this.type = this.data.type || DialogConfirmType.info;
    this.layout = this.getLayout();
  }

  private getLayout(): DialogConfirmLayout {
    switch (this.type) {
      case DialogConfirmType.warning:
        return {
          buttonColor: ButtonThemePalette.orange,
          icon: 'exclamation-triangle',
          iconColor: 'text-orange-400',
        };

      case DialogConfirmType.danger:
        return {
          buttonColor: ButtonThemePalette.red,
          icon: 'exclamation-circle',
          iconColor: 'text-red-400',
        };

      case DialogConfirmType.success:
        return {
          buttonColor: ButtonThemePalette.green,
          icon: 'check-circle',
          iconColor: 'text-success-400',
        };

      default:
        return {
          buttonColor: ButtonThemePalette.primary,
          icon: 'info-circle',
          iconColor: 'text-primary-400',
        };
    }
  }
}
