import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { Overlay, OverlayRef, ScrollDispatcher } from '@angular/cdk/overlay';
import { normalizePassiveListenerOptions, Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, NgZone, OnDestroy, TemplateRef } from '@angular/core';
import { Subject, take, takeUntil } from 'rxjs';

import { TooltipComponent } from './tooltip.component';
import { tooltipPlacement, TooltipPlacement, ToolTipPositions } from './tooltip.model';

const passiveListenerOptions = normalizePassiveListenerOptions({ passive: true });

@Directive({
  selector: '[sbTooltip]',
  exportAs: 'sb-tooltip',
  standalone: true,
})
export class TooltipDirective implements OnDestroy, AfterViewInit {
  @Input('sbTooltipDisabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // If tooltip is disabled, hide immediately.
    if (this._disabled) {
      this.hide();
    } else {
      this.setupPointerEnterEventsIfNeeded();
    }
    this.syncAriaDescription();
  }
  private _disabled = false;

  @Input('sbTooltipDelay')
  public delay = 400;

  @Input('sbTooltipPlacement')
  get placement() {
    return this._placement;
  }
  set placement(value: tooltipPlacement) {
    if (value !== this._placement) {
      this._placement = value;
      if (this.isTooltipVisible()) {
        this.hide();
      }
    }
  }
  private _placement: tooltipPlacement = TooltipPlacement.BOTTOM;

  // will render text or template depending on input
  @Input('sbTooltip')
  get tooltip(): string | TemplateRef<unknown> {
    return this._tooltip;
  }
  set tooltip(value: string | TemplateRef<unknown> | undefined) {
    if (this._tooltip !== value) {
      this._tooltip = value || '';

      if (this.isTooltipVisible()) {
        this.hide();
      } else {
        this.setupPointerEnterEventsIfNeeded();
      }

      this.syncAriaDescription();
    }
  }
  private _tooltip: string | TemplateRef<unknown> = '';

  // triggers cannot be dynamically set, the values are set once the component is initialized
  @Input('sbTooltipTriggers')
  public triggers = 'hover focus touch';

  @Input('sbTooltipMaxWidth')
  public maxWidth?: string;

  private _overlayRef?: OverlayRef;
  private _tooltipInstance: TooltipComponent | null = null;
  private destroyed$ = new Subject<void>();
  /** The timeout ID of any current timer set to show the tooltip */
  private _showTimeoutId: ReturnType<typeof setTimeout> | undefined;
  private _repositionTimeoutId: ReturnType<typeof setTimeout> | null = null;
  private _portal: ComponentPortal<TooltipComponent> | undefined;
  private _viewInitialized = false;
  private _pointerExitEventsInitialized = false;
  private readonly _passiveListeners: (readonly [string, EventListenerOrEventListenerObject])[] = [];
  private _document: Document;

  private pendingAriaDescriptionUpdate = false;
  private ariaDescription?: string;

  constructor(
    private _overlay: Overlay,
    private _elementRef: ElementRef<HTMLElement>,
    private _scrollDispatcher: ScrollDispatcher,
    private _ngZone: NgZone,
    private _platform: Platform,
    private _ariaDescriber: AriaDescriber,
    private _focusMonitor: FocusMonitor,
    @Inject(DOCUMENT) _document: any,
  ) {
    this._document = _document;
  }

  ngAfterViewInit(): void {
    this._viewInitialized = true;
    this.setupPointerEnterEventsIfNeeded();

    if (this.triggers.includes('focus')) {
      this._focusMonitor
        .monitor(this._elementRef)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((origin) => {
          if (!origin) {
            this._ngZone.run(() => this.hide());
          } else if (origin === 'keyboard') {
            this._ngZone.run(() => this.show());
          }
        });
    }
  }

  private isTooltipTemplate(): boolean {
    return this.tooltip instanceof TemplateRef;
  }

  /** Either remove, set or update the aria description  */
  private syncAriaDescription() {
    // do nothing when a change is pending
    // or when there is no tooltip or ariaDescription
    if (this.pendingAriaDescriptionUpdate || (!this.ariaDescription && !this.tooltip)) return;

    this.pendingAriaDescriptionUpdate = true;

    // remove the old description
    this.removeAriaDescription();

    // set the new description
    this._ngZone.runOutsideAngular(() => {
      Promise.resolve().then(() => {
        this.pendingAriaDescriptionUpdate = false;

        if (!this.tooltip || this.isTooltipTemplate() || this.disabled) return;

        this.ariaDescription = this._tooltip as string;
        this._ariaDescriber.describe(this._elementRef.nativeElement, this.ariaDescription, 'tooltip');
      });
    });
  }

  private removeAriaDescription() {
    if (!this.ariaDescription) {
      return;
    }

    this._ariaDescriber.removeDescription(this._elementRef.nativeElement, this.ariaDescription, 'tooltip');
    this.ariaDescription = undefined;
  }

  public ngOnDestroy() {
    this.hide();

    this._overlayRef?.dispose();

    const nativeElement = this._elementRef.nativeElement;
    this._passiveListeners.forEach(([event, listener]) => {
      nativeElement.removeEventListener(event, listener, passiveListenerOptions);
    });
    this._passiveListeners.length = 0;

    this.destroyed$.next();
    this.destroyed$.complete();

    this.removeAriaDescription();
    this._focusMonitor.stopMonitoring(nativeElement);
  }

  show(delay: number = this.delay) {
    if (this.disabled || !this.tooltip || this.isTooltipVisible()) {
      return;
    }

    if (this._showTimeoutId != null) {
      clearTimeout(this._showTimeoutId);
    }

    this._showTimeoutId = setTimeout(() => {
      this.createOverlay();
      this.attachTooltip();
      this._showTimeoutId = undefined;
    }, delay);
  }

  hide() {
    // Cancel the delayed show if it is scheduled
    if (this._showTimeoutId != null) {
      clearTimeout(this._showTimeoutId);
    }
    this.detach();
  }

  public attachTooltip() {
    if (!this._overlayRef || this._overlayRef?.hasAttached()) {
      return;
    }
    this._portal = this._portal || new ComponentPortal(TooltipComponent);
    const instance = this._overlayRef.attach(this._portal).instance;
    this._tooltipInstance = instance;
    if (this.maxWidth) {
      this._tooltipInstance.maxWidth = this.maxWidth;
    }
    this.updateTooltipMessage();
  }

  /**
   * Gets positions for the tooltip sorted by distance to preferred placement
   *
   * @returns an array of positions to be used by the overlay
   */
  public getPositions() {
    // Rotate positions so placement is always the first position
    let positions = Object.entries(ToolTipPositions);
    const index = positions.findIndex(([key]) => key === this.placement);
    const part = positions.splice(0, index);
    positions = positions.concat(part);

    // Map the shortest distance of each position to placement
    const distances = new Map();
    positions.forEach(([key], i) => {
      const distance = Math.min(i, Math.abs(positions.length - i));
      distances.set(key, distance);
    });

    // Sort by distance
    return positions.sort((a, b) => distances.get(a[0]) - distances.get(b[0]));
  }

  private createOverlay() {
    if (this._overlayRef) {
      this.detach();
    }

    const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._elementRef);
    const elementWidth = this._elementRef.nativeElement.offsetWidth;
    const isSmallElement = elementWidth < 50;
    const positions = this.getPositions();

    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(this._elementRef)
      .withFlexibleDimensions(false)
      .withScrollableContainers(scrollableAncestors)
      .withPositions(positions.map(([_, value]) => value));

    this._overlayRef = this._overlay.create({
      positionStrategy: strategy,
      scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 20 }),
      panelClass: isSmallElement ? 'sb-tooltip-trigger-small' : '',
    });

    this._overlayRef
      .detachments()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.detach());

    this._overlayRef
      .keydownEvents()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event) => {
        if (this.isTooltipVisible() && event.keyCode === ESCAPE && !hasModifierKey(event)) {
          event.preventDefault();
          event.stopPropagation();
          this._ngZone.run(() => this.hide());
        }
      });
  }

  isTooltipVisible(): boolean {
    return !!this._tooltipInstance;
  }

  private detach() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
    this._tooltipInstance = null;
  }

  private updateTooltipMessage() {
    if (!this._tooltipInstance) {
      return;
    }
    if (this.tooltip instanceof TemplateRef) {
      this._tooltipInstance.tooltipTemplate = this.tooltip;
      this._tooltipInstance.tooltipText = undefined;
    } else {
      this._tooltipInstance.tooltipText = this.tooltip;
      this._tooltipInstance.tooltipTemplate = undefined;
    }
    this._tooltipInstance.markForCheck();

    // Must wait for the message to be painted to the tooltip so that the overlay can properly
    // calculate the correct positioning based on the size of the text.
    this._ngZone.onMicrotaskEmpty.pipe(take(1), takeUntil(this.destroyed$)).subscribe(() => {
      if (this._tooltipInstance) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._overlayRef!.updatePosition();
      }
    });
  }

  private setupPointerEnterEventsIfNeeded() {
    if (this._disabled || !this.tooltip || !this._viewInitialized || this._passiveListeners.length) {
      return;
    }

    const supportsMouseEvents = this.platformSupportsMouseEvents();

    const enterListener = () => {
      this.setupPointerExitEventsIfNeeded(supportsMouseEvents);
      this.show();
    };

    if (supportsMouseEvents) {
      if (this.triggers.includes('hover')) {
        this._passiveListeners.push(['mouseenter', enterListener]);
      }
    } else {
      if (this.triggers.includes('touch')) {
        this._passiveListeners.push(['touchstart', enterListener]);
      }
    }
    this.addListeners(this._passiveListeners);
  }

  private setupPointerExitEventsIfNeeded(supportsMouseEvents: boolean) {
    if (this._pointerExitEventsInitialized) {
      return;
    }
    this._pointerExitEventsInitialized = true;

    const exitListeners: (readonly [string, EventListenerOrEventListenerObject])[] = [];
    const exitListener = () => {
      this.hide();
    };
    if (supportsMouseEvents) {
      exitListeners.push(['mouseleave', exitListener], ['wheel', (event) => this.wheelListener(event as WheelEvent)]);
    } else {
      exitListeners.push(['touchend', exitListener], ['touchcancel', exitListener]);
    }

    this.addListeners(exitListeners);
    this._passiveListeners.push(...exitListeners);
  }

  private wheelListener(event: WheelEvent) {
    if (this.isTooltipVisible()) {
      const elementUnderPointer = this._document.elementFromPoint(event.clientX, event.clientY);
      const element = this._elementRef.nativeElement;

      // close the tooltip if it's not the trigger.
      if (elementUnderPointer !== element && !element.contains(elementUnderPointer)) {
        this.hide();
        return;
      }
      // reposition tooltip if its still open
      if (this._repositionTimeoutId) {
        clearTimeout(this._repositionTimeoutId);
        this._repositionTimeoutId = null;
      }
      this._repositionTimeoutId = setTimeout(() => {
        this._overlayRef?.updatePosition();
        this._repositionTimeoutId = null;
      }, 200);
    }
  }

  private addListeners(listeners: (readonly [string, EventListenerOrEventListenerObject])[]) {
    listeners.forEach(([event, listener]) => {
      this._elementRef.nativeElement.addEventListener(event, listener, passiveListenerOptions);
    });
  }

  private platformSupportsMouseEvents() {
    return !this._platform.IOS && !this._platform.ANDROID;
  }
}
