import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';

import { PopoverFooterDirective } from './popover-footer.directive';
import { PopoverComponent } from './popover.component';
import { PopoverDirective } from './popover.directive';

const directives = [PopoverDirective, PopoverFooterDirective];
const components = [PopoverComponent];

@NgModule({
  imports: [OverlayModule, directives, components],
  exports: [directives, components],
})
export class PopoverModule {}
