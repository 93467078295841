import { AfterContentInit, Directive, ElementRef, inject, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[sbAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterContentInit, OnDestroy {
  readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private _showTimeoutId: ReturnType<typeof setTimeout> | null = null;

  @Input('sbAutofocusDelay')
  delay = 100;
  @Input('sbAutofocusDisabled')
  disabled = false;

  ngAfterContentInit(): void {
    if (this.disabled) {
      return;
    }
    this._showTimeoutId = setTimeout(() => {
      this.focus();
      this._showTimeoutId = null;
    }, this.delay);
  }

  ngOnDestroy(): void {
    if (this._showTimeoutId != null) {
      clearTimeout(this._showTimeoutId);
    }
  }

  private focus(): void {
    this.elementRef.nativeElement.focus();
  }
}
