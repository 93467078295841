import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ExistingProvider,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorPickerModule } from 'primeng/colorpicker';

const VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ColorPickerComponent),
  multi: true,
};

@Component({
  selector: 'sb-color-picker',
  standalone: true,
  imports: [CommonModule, ColorPickerModule, FormsModule],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
})
export class ColorPickerComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public color?: string;
  @Input()
  public format: 'hex' | 'rgb' | 'hsb' = 'hex';

  @Output()
  public colorPicked = new EventEmitter<string>();

  public value?: string;

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onModelChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.  Called when the checkbox is blurred.  Needed to properly implement ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onModelTouched: () => any = () => {};

  public onChangeColor(color: string) {
    if (this.color === color) {
      return;
    }

    this.value = color;

    this.onChange();
  }

  public registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  public writeValue(value: string): void {
    if (value === null) {
      return;
    }

    this.value = value;
  }

  public onChange() {
    this.colorPicked.emit(this.value);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['color']) {
      this.value = this.color;
    }
  }
}
