import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'sb-form-group',
  standalone: true,
  templateUrl: './form-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent {
  @HostBinding('class')
  hostClasses = 'relative flex w-full items-stretch ';
}
