<div
  class="m-0 flex flex-wrap justify-start gap-x-4 gap-y-1.5 whitespace-nowrap p-0 text-xs font-medium text-word-mark-800"
>
  @for (item of items; track $index) {
    <sb-legend-item [item]="item"></sb-legend-item>
  }
  @if (restItem) {
    <sb-legend-item [item]="restItem"></sb-legend-item>
  }
</div>
