<sb-form-group>
  <button
    sbFormGroupPrefix
    sb-button
    [color]="'grey'"
    [sbDropdownToggle]="menu"
    (sbDropdownToggleOpened)="onOpened()"
    class="min-w-10"
  >
    @if (selectedCountry) {
      <div class="flex">
        <div class="mr-2 flex h-5 w-5 items-center" [innerHTML]="selectedCountry.flag"></div>
        <span>+{{ selectedCountry.phoneCode }}</span>
      </div>
    }
  </button>
  <input
    #inputElement
    sbInput
    type="tel"
    autocomplete="off"
    [id]="inputId"
    [attr.placeholder]="placeholder"
    [disabled]="disabled"
    (blur)="onTouched()"
    [(ngModel)]="phoneNumber"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onPhoneNumberChange()"
    (keypress)="onInputKeyPress($event)"
    (paste)="onPaste($event)"
  />
</sb-form-group>

<ng-template #menu>
  <div sbDropdownMenu class="max-h-72" [ngStyle]="{ width: dropdownWidth }">
    <div class="mx-4 mb-2.5 mt-1">
      <sb-search-input
        [(ngModel)]="searchQuery"
        (ngModelChange)="onSearch()"
        [ngModelOptions]="{ standalone: true }"
        [autoFocusInput]="true"
      ></sb-search-input>
    </div>

    @if (!filteredCountries.length) {
      <div sbDropdownBaseItem [hasHoverEffect]="false">
        <span>{{ 'No items found' | translate }}</span>
      </div>
    }

    @for (country of filteredCountries; track country.regionCode) {
      <button sbDropdownItem (sbDropdownItemTriggered)="onSelect(country)" type="button">
        <div class="mr-2 flex h-5 w-5 items-center" [innerHTML]="country.flag"></div>
        {{ country.name }} <span class="ml-1 text-grey-300"> +{{ country.phoneCode }}</span>
      </button>
    }
  </div>
</ng-template>
