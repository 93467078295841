<!-- First row -->
<!-- TODO leading overwrite is necessary to make it adhere to design. Once typography is settled, this should be revised -->
<div class="mt-0.5">
  <div [ngClass]="rowClasses">
    <div class="truncate font-bold" [ngClass]="{ 'text-xl leading-6': !compact }">
      <ng-content select="[firstRowLeft]" />
    </div>
    <div [ngClass]="cellClasses">
      <ng-content select="[firstRowRight]" />
    </div>
  </div>
  <!-- Second row -->
  <div [ngClass]="rowClasses">
    <div class="truncate" [ngClass]="cellClasses">
      <ng-content select="[secondRowLeft]" />
    </div>
    <div [ngClass]="cellClasses">
      <ng-content select="[secondRowRight]" />
    </div>
  </div>
</div>
<!-- Third row -->
<div [ngClass]="rowClasses">
  <div [ngClass]="cellClasses">
    <ng-content select="[thirdRowLeft]" />
  </div>
</div>
