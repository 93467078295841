import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LaddaDirective } from '@app/shared/ladda/ladda.directive';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../../button';

// todo when we have a different loading directive, we should use it here and remove this illigal import

@Component({
  selector: 'sb-save-bar',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonComponent, LaddaDirective],
  templateUrl: './save-bar.component.html',
})
export class SaveBarComponent {
  // sticky bottom-0 mt-auto
  @HostBinding('class')
  public readonly class = 'block overflow-hidden';

  @Input({ required: true })
  changeCount = 0;

  @Input({ required: true })
  saving = false;

  @Input()
  disabled = false;

  @Input()
  statusText: { one: string; more: string } = {
    one: marker('You have changed 1 setting'),
    more: marker('You have changed {{amount}} settings'),
  };

  @Output()
  save = new EventEmitter<void>();

  @Output()
  cancel = new EventEmitter<void>();
}
