import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { sbIcons } from '@sb/svg-icons';
import { clsx } from 'clsx';

import { ButtonComponent } from '../../button';
import { IconComponent } from '../../icon';

@Component({
  selector: 'sb-empty-state',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, ButtonComponent],
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
  @Input()
  title?: string;

  @Input({ required: true })
  description!: string;

  @Input({ required: true })
  icon!: sbIcons;

  @Input()
  richIcon = false;

  @HostBinding('class')
  classNames = clsx('flex flex-col items-center justify-center');
}
