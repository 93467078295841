import { Directive, HostBinding } from '@angular/core';
import { FormGroupAddonDirective } from './form-group-addon.directive';

@Directive({
  selector: '[sbFormGroupPrefix]',
  standalone: true,
})
export class FormGroupPrefixDirective extends FormGroupAddonDirective {
  @HostBinding('class')
  prefixHostClasses = '-mr-px rounded-r-none';
}
