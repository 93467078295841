import { NgModule } from '@angular/core';

import { InputBaseDirective } from './input-base.directive';
import { InputButtonSelectDirective, InputSelectDirective } from './input-select.directive';
import { InputDirective } from './input.directive';

const directives = [InputDirective, InputSelectDirective, InputButtonSelectDirective, InputBaseDirective];

@NgModule({
  imports: [...directives],
  exports: [...directives],
})
export class InputModule {}
