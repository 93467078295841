import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

export class PhoneInputValidator implements Validator {
  private readonly _phoneUtil = PhoneNumberUtil.getInstance();

  validate(control: AbstractControl<string>): ValidationErrors | null {
    let invalidPhoneNumber = true;
    const trimmed = control.value?.trim();
    if (!trimmed) return null;
    try {
      const number = this._phoneUtil.parse(trimmed);
      invalidPhoneNumber = !this._phoneUtil.isValidNumber(number);
    } catch (e) {
      invalidPhoneNumber = true;
    }

    return invalidPhoneNumber ? { invalidPhoneNumber: { value: control.value } } : null;
  }
}
