import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { sbIcons } from '@sb/svg-icons';

import { IconComponent } from '../icon';

export enum StatusType {
  PASS = 'pass',
  WARNING = 'warning',
}

export interface StatusListItem {
  label: string;
  status: StatusType;
}

export interface StatusList {
  titleLabels: Record<StatusType, string>;
  items: StatusListItem[];
}

interface TitleProps {
  icon: sbIcons;
  iconClass: string;
}

@Component({
  selector: 'sb-status-list',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslateModule],
  templateUrl: './status-list.component.html',
})
export class StatuslistComponent implements OnChanges {
  public titleProps: Record<StatusType, TitleProps> = {
    [StatusType.PASS]: {
      icon: 'check-circle',
      iconClass: 'text-success-500',
    },
    [StatusType.WARNING]: {
      icon: 'exclamation-triangle',
      iconClass: 'text-warning-500',
    },
  };

  public statusIconMap: Record<StatusType, sbIcons> = {
    [StatusType.PASS]: 'check',
    [StatusType.WARNING]: 'exclamation-triangle',
  };

  public mainStatus: StatusType = StatusType.WARNING;

  @Input({ required: true })
  public list!: StatusList;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['list']) {
      this.mainStatus = this.getMainStatus();
    }
  }

  private getMainStatus() {
    return this.list.items.every((item) => item.status === StatusType.PASS) ? StatusType.PASS : StatusType.WARNING;
  }
}
