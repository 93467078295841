<sb-input-icon>
  <input
    sbInput
    [hasError]="hasError"
    [type]="!passwordShown ? 'password' : 'text'"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [readonly]="readonly"
    [ngModel]="text"
    maxlength="72"
    (ngModelChange)="onChangeInput($event)"
    [ngModelOptions]="{ standalone: true }"
    (blur)="onBlurInput()"
  />
  <sb-input-icon-suffix role="button" (click)="togglePasswordIcon()">
    <sb-icon id="toggle-icon" [name]="!passwordShown ? 'eye' : 'eye-slash'" [size]="'lg'"></sb-icon>
  </sb-input-icon-suffix>
</sb-input-icon>
