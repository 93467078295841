import { Directive, HostBinding } from '@angular/core';
import { FormGroupAddonDirective } from './form-group-addon.directive';

@Directive({
  selector: '[sbFormGroupSuffix]',
  standalone: true,
})
export class FormGroupSuffixDirective extends FormGroupAddonDirective {
  @HostBinding('class')
  suffixHostClasses = '-ml-px rounded-l-none';
}
