import { Component, Input } from '@angular/core';
import { sbIcons } from '@sb/svg-icons';

import { IconComponent } from '../icon';
import { DropdownItemDirective } from './dropdown-item.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[sb-dropdown-item-icon], [sbDropdownItemIcon]',
  standalone: true,
  imports: [IconComponent],
  template: `
    @if (icon) {
      <sb-icon [name]="icon" class="mr-2.5 flex-none"></sb-icon>
    }
    <ng-content></ng-content>
  `,
  providers: [{ provide: DropdownItemDirective, useExisting: DropdownItemIconComponent }],
})
export class DropdownItemIconComponent extends DropdownItemDirective {
  @Input()
  icon?: sbIcons;
}
