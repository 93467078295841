import { DOWN_ARROW, hasModifierKey, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { ConnectedPosition, Overlay, OverlayRef, ScrollDispatcher } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { filter, merge, Subject, takeUntil } from 'rxjs';

import { LEFT_POSITIONING_PREFERENCES, RIGHT_POSITIONING_PREFERENCES } from './dropdown-connected-position-strategy';
import { DropdownMenuDirective } from './dropdown-menu.directive';

export enum DropdownPlacement {
  'bottom-left' = 'bottom-left',
  'bottom-right' = 'bottom-right',
}
export type dropdownPlacement = keyof typeof DropdownPlacement | undefined;

export const MENU_TRIGGER = new InjectionToken<DropdownToggleDirective>('sb-menu-trigger');

@Directive({
  selector: '[sbDropdownToggle]',
  standalone: true,
})
export class DropdownToggleDirective implements OnDestroy {
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly overlay = inject(Overlay);
  private readonly scrollDispatcher = inject(ScrollDispatcher);
  readonly injector = inject(Injector);

  protected readonly destroyed$ = new Subject<void>();
  protected readonly closed$ = new Subject<void>();

  menuTemplateRef!: TemplateRef<unknown>;
  menuPosition: ConnectedPosition[] = LEFT_POSITIONING_PREFERENCES;
  childMenu?: DropdownMenuDirective;

  private overlayRef?: OverlayRef;
  private portal?: TemplatePortal<unknown>;
  private childMenuInjector?: Injector;

  @Input()
  set sbDropdownToggle(value: TemplateRef<unknown>) {
    this.menuTemplateRef = value;
  }

  @Input()
  public sbDropdownConnectedToRef?: ElementRef<HTMLElement>;

  @Input()
  public sbDropdownHasBackdrop = true;

  @Input()
  set sbDropdownPlacement(value: dropdownPlacement) {
    if (this.placement === value && this.menuPosition) {
      return;
    }
    this._placement = value;
    this.menuPosition = this.getConnectedPosition(value);
    if (this.isOpen) {
      this.close({ focusTrigger: true });
    }
    // need to set the overlay ref to undefined so that the new positions are used next time the dropdown opens
    this.overlayRef = undefined;
  }
  get placement(): dropdownPlacement {
    return this._placement;
  }
  private _placement?: dropdownPlacement;

  @Output('sbDropdownToggleClosed')
  readonly closed: EventEmitter<void> = new EventEmitter();

  @Output('sbDropdownToggleOpened')
  readonly opened: EventEmitter<void> = new EventEmitter();

  @HostBinding('attr.role')
  role = 'button';

  @HostBinding('attr.aria-haspopup')
  get ariaHasPopup(): 'menu' | null {
    return this.menuTemplateRef ? 'menu' : null;
  }

  @HostBinding('attr.aria-expanded')
  get ariaExpanded(): boolean | null {
    return this.menuTemplateRef ? this.isOpen : null;
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('click')
  handleClick() {
    this.toggle();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case TAB: {
        if (!hasModifierKey(event) && this.isOpen) {
          event.preventDefault();

          this.childMenu?.focusFirstElement();
        }
        break;
      }
      case UP_ARROW: {
        if (!hasModifierKey(event)) {
          event.preventDefault();
          if (!this.isOpen) {
            this.open();
          }
          this.childMenu?.focusLastItem('keyboard');
        }
        break;
      }
      case DOWN_ARROW: {
        if (!hasModifierKey(event)) {
          event.preventDefault();
          if (!this.isOpen) {
            this.open();
          }
          this.childMenu?.focusFirstItem('keyboard');
        }
        break;
      }
    }
  }

  toggle() {
    this.isOpen ? this.close() : this.open();
  }

  open() {
    this.createOverlay();
    this.attachOverlay();
    this.opened.emit();
  }

  close({ focusTrigger = false } = {}) {
    if (focusTrigger) {
      this.focus();
    }
    this.detachOverlay();
    this.closed$.next();
    this.closed.emit();
  }

  registerChildMenu(child: DropdownMenuDirective) {
    this.childMenu = child;
  }

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  get isOpen(): boolean {
    return !!this.overlayRef?.hasAttached();
  }

  private createOverlay(): void {
    if (this.overlayRef) {
      this.detachOverlay();
    }

    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.sbDropdownConnectedToRef || this.elementRef)
      .withFlexibleDimensions(false)
      .withScrollableContainers(scrollableAncestors)
      .withPositions(this.menuPosition);

    this.overlayRef = this.overlay.create({
      hasBackdrop: this.sbDropdownHasBackdrop,
      backdropClass: '',
      positionStrategy: strategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({ scrollThrottle: 20 }),
    });

    this.overlayRef
      .outsidePointerEvents()
      .pipe(
        // Filter out clicks on the trigger element itself
        filter((event: MouseEvent) => {
          return !this.elementRef.nativeElement.contains(event.target as HTMLElement);
        }),
        takeUntil(merge(this.closed$, this.destroyed$)),
      )
      .subscribe(() => {
        this.close({ focusTrigger: false });
      });
  }

  private attachOverlay(): void {
    if (!this.overlayRef || this.overlayRef?.hasAttached()) {
      return;
    }

    this.portal = this.getPortal();
    this.overlayRef.attach(this.portal);
  }

  private detachOverlay() {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
      this.overlayRef?.dispose();
    }
  }

  private getChildMenuInjector() {
    this.childMenuInjector =
      this.childMenuInjector ||
      Injector.create({
        providers: [{ provide: MENU_TRIGGER, useValue: this }],
        parent: this.injector,
      });
    return this.childMenuInjector;
  }

  private getPortal() {
    return (
      this.portal || new TemplatePortal(this.menuTemplateRef, this.viewContainerRef, {}, this.getChildMenuInjector())
    );
  }

  private getConnectedPosition(value: dropdownPlacement): ConnectedPosition[] {
    switch (value) {
      case DropdownPlacement['bottom-right']: {
        return RIGHT_POSITIONING_PREFERENCES;
      }
      default: {
        return LEFT_POSITIONING_PREFERENCES;
      }
    }
  }
}
