import { DialogModule as CdkDialogModule, DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';

import { SbDialogService } from './dialog.service';
import { SbDialogContainerComponent } from './internal';
import { SbDialogSharedModule } from './shared';

const config = new DialogConfig();

@NgModule({
  providers: [
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: { ...config, maxWidth: '90vw', maxHeight: '85vh', hasBackdrop: true },
    },
    SbDialogService,
  ],
  declarations: [SbDialogContainerComponent],
  imports: [CdkDialogModule, SbDialogSharedModule],
  exports: [SbDialogSharedModule],
})
export class SbDialogModule {}
