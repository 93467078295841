import { DialogRef } from '@angular/cdk/dialog';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, Optional } from '@angular/core';

import { IconButtonComponent } from '../../button';
import { IconComponent } from '../../icon';

@Component({
  selector: 'sb-legacy-dialog-header',
  standalone: true,
  imports: [IconButtonComponent, IconComponent, NgIf, NgClass],
  templateUrl: './dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @HostBinding('class')
  hostClass = 'bg-primary-500 px-3 py-1.5 flex justify-between items-center font-semibold text-base text-white h-12';

  @Input()
  showClose = true;

  constructor(@Optional() public dialogRef: DialogRef<any>) {}
}
