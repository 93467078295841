import { NgModule } from '@angular/core';

import { InputIconPrefixDirective } from './input-icon-prefix.directive';
import { InputIconSuffixDirective } from './input-icon-suffix.directive';
import { InputIconComponent } from './input-icon.component';

const standalone = [InputIconComponent, InputIconPrefixDirective, InputIconSuffixDirective];

@NgModule({
  imports: standalone,
  exports: standalone,
})
export class InputIconModule {}
