<sb-dialog-header>
  <sb-icon [ngClass]="layout.iconColor" [name]="layout.icon" [size]="'xl'"></sb-icon>
  <h3 sb-dialog-title>{{ data.title }}</h3>
</sb-dialog-header>
<sb-dialog-content>
  @if (data.contentTemplate) {
    <ng-container *ngTemplateOutlet="data.contentTemplate"></ng-container>
  } @else if (data.description) {
    <p class="mb-0">{{ data.description }}</p>
  }
</sb-dialog-content>
<sb-dialog-footer>
  <sb-dialog-footer-actions [align]="'end'">
    <button sb-button [color]="'secondary'" [sb-dialog-close]="{ confirmed: false }">
      {{ data.secondary?.text || ('Cancel' | translate) }}
    </button>
    <button
      sb-button
      [disabled]="data.primary?.disabled || false"
      [color]="layout.buttonColor"
      [sb-dialog-close]="{ confirmed: true }"
    >
      {{ data.primary?.text || ('Confirm' | translate) }}
    </button>
  </sb-dialog-footer-actions>
</sb-dialog-footer>
