import { NgModule } from '@angular/core';

import { SidebarDrawerContainerComponent } from './sidebar-drawer-container.component';
import { SidebarDrawerContentComponent } from './sidebar-drawer-content.component';
import { SidebarDrawerComponent } from './sidebar-drawer.component';

const standalone = [SidebarDrawerContainerComponent, SidebarDrawerComponent, SidebarDrawerContentComponent];

@NgModule({
  imports: standalone,
  exports: standalone,
})
export class SidebarDrawerModule {}
