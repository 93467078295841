import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';

import { AnchorButtonComponent, ButtonComponent } from './button.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[sb-icon-button]',
  standalone: true,
  templateUrl: './icon-button.component.html',
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent extends ButtonComponent {
  constructor(elementRef: ElementRef<HTMLButtonElement>) {
    super(elementRef);
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[sb-icon-button]',
  standalone: true,
  templateUrl: './icon-button.component.html',
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconAnchorButtonComponent extends AnchorButtonComponent {
  constructor(elementRef: ElementRef<HTMLAnchorElement>) {
    super(elementRef);
  }
}
