<div class="mt-2 flex flex-col gap-1 text-word-mark-400">
  <div *ngFor="let requirement of requirements; trackBy: trackByRequirement" class="flex items-center gap-1">
    <sb-icon
      *ngIf="hasError || requirement.confirmationType === 'success'"
      class="font-bold"
      [ngClass]="{
        'text-green-600': requirement.confirmationType === 'success',
        'text-red-500': hasError && requirement.confirmationType === 'error'
      }"
      [size]="'sm'"
      [name]="requirement.confirmationType === 'success' ? 'check' : 'exclamation-circle'"
    ></sb-icon>
    <span
      class="text-xs font-medium"
      [ngClass]="{
        'text-word-mark-600': hasError && requirement.confirmationType === 'error'
      }"
      [innerHtml]="requirement.label"
    ></span>
  </div>
</div>
