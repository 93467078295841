<div (click)="onClick()" class="group inline-block relative cursor-pointer">
  <div *ngIf="notificationsCount" class="absolute w-full">
    <div
      class="float-right -mt-1 -mr-1 border-2 border-solid border-primary-500 group-hover:border-primary-600 rounded-full"
    >
      <div class="flex items-center justify-center">
        <div class="w-5 h-5 rounded-full bg-white border-2 border-solid border-white flex items-center justify-center">
          <span id="notification-count" class="text-primary-500 text-base font-semibold group-hover:text-primary-600">{{
            notificationsCount
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    id="bubble-title"
    class="text-base font-semibold text-white text-center px-5 py-2 bg-primary-500 rounded-full shadow-lg group-hover:bg-primary-600"
  >
    {{ title }}
  </div>
</div>
