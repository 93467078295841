<span
  class="flex flex-row items-center justify-start gap-5 rounded-md border border-solid px-2.5 py-1 group-focus-visible/item:outline group-focus-visible/item:outline-1 group-focus-visible/item:outline-offset-1 group-focus-visible/item:outline-primary-500"
  [ngClass]="{
    'text-grey-500 bg-white border-white': state === 'void' || state === 'expanded',
    'group-hover/item:bg-grey-100': state === 'void',
    'bg-primary-50 border-primary-50 text-primary-500': state === 'active',
    'bg-grey-100 border-grey-100 text-grey-400': state === 'disabled' || state === 'locked',
    'group-hover/item:text-grey-500': state === 'locked',
    'pl-3.5': !icon
  }"
>
  @if (icon) {
    <sb-icon
      [name]="locked ? 'lock' : icon"
      [size]="'lg'"
      class="shrink-0"
      [ngClass]="{
        'text-grey-400': state === 'void',
        'text-primary-500': state === 'expanded'
      }"
    ></sb-icon>
  }
  <span class="min-w-0">
    <ng-content></ng-content>
  </span>
  @if (collapsable) {
    <sb-icon class="ml-auto shrink-0 text-grey-400" [name]="active ? 'minus' : 'plus'"></sb-icon>
  }
</span>
