import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ExistingProvider,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutofocusDirective } from '@sb/shared/util';

import { IconComponent } from '../icon';
import { InputModule } from '../input';
import { InputIconModule } from '../input-icon/input-icon.module';

export const SEARCH_INPUT_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchInputComponent),
  multi: true,
};

@Component({
  selector: 'sb-search-input',
  standalone: true,
  imports: [
    CommonModule,
    InputModule,
    InputIconModule,
    FormsModule,
    IconComponent,
    TranslateModule,
    AutofocusDirective,
  ],
  templateUrl: './search-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SEARCH_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SearchInputComponent implements ControlValueAccessor {
  @Input()
  placeholder = '';
  @Input()
  disabled = false;
  @Input()
  readonly = false;
  @Input()
  autoFocusInput = false;
  @Input()
  hideClearButton = false;

  @ViewChild('inputElement', { static: true, read: ElementRef })
  inputElement!: ElementRef;

  query = '';

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.  Called when the checkbox is blurred.  Needed to properly implement ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => any = () => {};

  // Implemented as part of ControlValueAccessor.
  writeValue(value: any): void {
    if (value === this.query) {
      return;
    }
    this.query = value;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeInput(value: string): void {
    this.query = value;
    this.onChange(this.query);
  }

  onBlurInput(): void {
    Promise.resolve().then(() => {
      this.onTouched();
    });
  }

  resetInput(): void {
    this.query = '';
    this.onChange(this.query);
  }
}
