import { ConnectedPosition } from '@angular/cdk/overlay';

export enum TooltipPlacement {
  TOP = 'top',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  LEFT = 'left',
  RIGHT = 'right',
}

export type tooltipPlacement =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'right';

// Note: order of the positions here is important as it is used to determine neighboring positions
export const ToolTipPositions: Record<TooltipPlacement, ConnectedPosition> = {
  [TooltipPlacement.TOP]: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -20,
    panelClass: ['arrow-bottom'],
    weight: 10,
  },
  [TooltipPlacement.TOP_RIGHT]: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -20,

    panelClass: ['arrow-bottom-left'],
    weight: 5,
  },
  [TooltipPlacement.RIGHT]: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: 20,
    panelClass: ['arrow-left'],
    weight: 10,
  },
  [TooltipPlacement.BOTTOM_RIGHT]: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 20,
    panelClass: ['arrow-top-left'],
    weight: 5,
  },
  [TooltipPlacement.BOTTOM]: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 20,
    panelClass: ['arrow-top'],
    weight: 10,
  },
  [TooltipPlacement.BOTTOM_LEFT]: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: 20,
    panelClass: ['arrow-top-right'],
    weight: 5,
  },
  [TooltipPlacement.LEFT]: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -20,
    panelClass: ['arrow-right'],
    weight: 10,
  },
  [TooltipPlacement.TOP_LEFT]: {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetY: -20,
    panelClass: ['arrow-bottom-right'],
    weight: 5,
  },
};
