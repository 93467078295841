import { NgModule } from '@angular/core';

import { DropdownBaseItemDirective } from './dropdown-base-item.directive';
import { DropdownDividerDirective } from './dropdown-divider.directive';
import { DropdownItemCheckboxDirective } from './dropdown-item-checkbox.directive';
import { DropdownItemIconComponent } from './dropdown-item-icon.component';
import { DropdownItemDirective } from './dropdown-item.directive';
import { DropdownMenuDirective } from './dropdown-menu.directive';
import { DropdownToggleDirective } from './dropdown-toggle.directive';

const standalone = [
  DropdownToggleDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownItemIconComponent,
  DropdownBaseItemDirective,
  DropdownItemCheckboxDirective,
  DropdownDividerDirective,
];

@NgModule({
  imports: [...standalone],
  exports: [...standalone],
  declarations: [],
})
export class DropdownModule {}
