import { Directive, HostBinding, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[sbTreeNode], [sb-tree-node], sb-tree-node',
  standalone: true,
})
export class TreeNodeDirective implements OnChanges {
  private baseClass = 'block';
  @HostBinding('attr.role')
  role = 'treeitem';
  @Input()
  nodePadding = true;
  @Input()
  level = 0;

  @HostBinding('class')
  hostClass = this.getHostClass();

  ngOnChanges(): void {
    this.hostClass = this.getHostClass();
  }

  getHostClass(): string {
    if (!this.nodePadding || this.level === 0) {
      return this.baseClass;
    }
    if (this.level === 1) {
      return `${this.baseClass} pl-6`;
    }
    if (this.level === 2) {
      return `${this.baseClass} pl-9`;
    }
    return `${this.baseClass} pl-10`;
  }
}
