import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sbCheckboxCircleGroup], [sb-checkbox-circle-group], sb-checkbox-circle-group',
  standalone: true,
})
export class CheckboxCircleGroupDirective {
  @HostBinding()
  class = clsx(['flex flex-wrap gap-3']);
}
