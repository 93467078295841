<button
  *ngIf="statusMapping[status]"
  [sbDropdownToggle]="menu"
  [sbDropdownPlacement]="position"
  [disabled]="saving"
  sb-icon-button
  [color]="statusMapping[status].button"
>
  <ng-container *ngIf="!saving; else savingTemplate">
    <sb-icon [name]="statusMapping[status].icon"></sb-icon>
    <span class="sr-only">{{ statusMapping[status].label | translate }}</span>
  </ng-container>
  <ng-template #savingTemplate>
    <sb-spinner [size]="'sm'" [strokeColor]="'current'"></sb-spinner>
  </ng-template>
  <sb-icon iconPositionEnd [name]="'chevron-down'" [size]="'xs'"></sb-icon>
</button>
<ng-template #menu>
  <div sbDropdownMenu>
    <ng-container *ngFor="let option of options; trackByStatus">
      <button
        *ngIf="status !== option.status"
        sbDropdownItem
        (sbDropdownItemTriggered)="statusChange.emit(option.status)"
      >
        {{ option.label | translate }}
      </button>
    </ng-container>
  </div>
</ng-template>
