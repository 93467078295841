<div class="w-full">
  <div *ngIf="title || subtitle" class="flex mb-1.5">
    <div class="grow">
      <label id="pb-title" class="text-sm font-semibold text-word-mark-800 m-0 inline">{{ title }}</label>
    </div>
    <div>
      <label id="pb-subtitle" class="text-sm font-medium text-grey-400 m-0 inline">{{ subtitle }}</label>
    </div>
  </div>
  <div class="bg-grey-200 rounded-full">
    <div id="pb-bar" class="bg-success-500 h-1 rounded-full" [ngStyle]="{ width: level + '%' }"></div>
  </div>
</div>
