<button [sbDropdownToggle]="menu" sb-icon-button [color]="'secondary'" [disabled]="disabled">
  <sb-icon [name]="icon"></sb-icon>
  <span class="sr-only">{{ icon }}</span>
</button>
<ng-template #menu>
  <div sbDropdownMenu>
    <div class="grid grid-cols-5 gap-2 px-3">
      <button *ngFor="let icon of icons" (click)="onChangeIconInput(icon)" sb-icon-button [color]="'transparent'">
        <sb-icon [name]="icon"></sb-icon>
        <span class="sr-only">{{ icon }}</span>
      </button>
    </div>
  </div>
</ng-template>
