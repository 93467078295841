import { ConnectedPosition } from '@angular/cdk/overlay';

export const PLACEMENT_BELOW_LEFT: ConnectedPosition = {
  originX: 'start',
  originY: 'bottom',
  overlayX: 'start',
  overlayY: 'top',
};

export const PLACEMENT_ABOVE_LEFT: ConnectedPosition = {
  originX: 'start',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'bottom',
};

export const PLACEMENT_BELOW_RIGHT: ConnectedPosition = {
  originX: 'end',
  originY: 'bottom',
  overlayX: 'end',
  overlayY: 'top',
};

export const PLACEMENT_ABOVE_RIGHT: ConnectedPosition = {
  originX: 'end',
  originY: 'top',
  overlayX: 'end',
  overlayY: 'bottom',
};

export const LEFT_POSITIONING_PREFERENCES: ConnectedPosition[] = [
  PLACEMENT_BELOW_LEFT,
  PLACEMENT_ABOVE_LEFT,
  PLACEMENT_BELOW_RIGHT,
  PLACEMENT_ABOVE_RIGHT,
];

export const RIGHT_POSITIONING_PREFERENCES: ConnectedPosition[] = [
  PLACEMENT_BELOW_RIGHT,
  PLACEMENT_ABOVE_RIGHT,
  PLACEMENT_BELOW_LEFT,
  PLACEMENT_ABOVE_LEFT,
];
