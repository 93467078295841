import { NgClass } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  standalone: true,
  imports: [NgClass],
  selector: 'sb-summary-item',
  templateUrl: './summary-item.component.html',
})
export class SummaryItemComponent implements OnChanges {
  public cellClasses = 'flex gap-2';
  public rowClasses = clsx('flex items-center justify-between gap-2 whitespace-nowrap leading-6');

  @HostBinding('class')
  public readonly class = clsx('flex w-full flex-col gap-1 overflow-hidden');

  @Input()
  public compact = false;

  public ngOnChanges(changes: SimpleChanges) {
    // When these classes are updated, don't forget to reflect that in the template defined classes of the `thirdRowLeft` as well
    this.cellClasses = clsx(['flex gap-2', changes['compact'] ? 'text-sm leading-6' : '']);
  }
}
