<div>
  <sb-icon [name]="icon" [size]="'xl'"></sb-icon>
</div>
<div class="flex flex-col gap-3 children:my-0">
  @if (title) {
    <h6 class="text-base font-semibold text-word-mark-800">
      {{ title }}
    </h6>
  }
  <p class="text-base font-normal text-grey-500" [innerHTML]="description"></p>
  @if (link) {
    <a (click)="linkClicked.emit()" class="text-base font-semibold text-word-mark-800">
      {{ link }}
    </a>
  }
</div>
