<ng-template #defaultNodeTemplate let-item="item" let-level="level">
  <div sbTreeNode [nodePadding]="true" [level]="level" class="text-base font-semibold flex items-center">
    <div *ngIf="collapsable" role="button" (click)="onToggleCollapse(item)" class="mr-2 cursor-pointer flex">
      <sb-icon [name]="'chevron-down'" [ngClass]="{ '-rotate-90': item.collapsed }"></sb-icon>
    </div>
    {{ item.text }}
  </div>
</ng-template>
<ng-template #defaultLeafTemplate let-item="item" let-level="level">
  <div sbTreeNode [nodePadding]="true" [level]="level" class="text-base flex items-center">
    <span *ngIf="collapsable" class="block w-3.5 h-3.5 ml-2" aria-hidden="true"></span>
    {{ item.text }}
  </div>
</ng-template>

<ng-container *ngFor="let item of items; trackBy: trackByFn" role="treeitem">
  <ng-container
    *ngTemplateOutlet="
      item.children?.length
        ? this.nodeTemplateInput || this.defaultNodeTemplate
        : this.leafTemplateInput || this.defaultLeafTemplate;
      context: { item: item, level: level }
    "
  ></ng-container>

  <sb-tree
    *ngIf="item?.children?.length"
    [ngClass]="{
      'h-0 overflow-hidden': collapsable && item.collapsed
    }"
    [items]="item.children || []"
    [level]="level + 1"
    [collapsable]="collapsable"
    [nodeTemplateInput]="nodeTemplateInput || defaultNodeTemplate"
    [leafTemplateInput]="leafTemplateInput || defaultLeafTemplate"
    (toggleCollapse)="toggleCollapse.emit($event)"
  >
  </sb-tree>
</ng-container>
