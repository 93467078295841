<div class="bg-white inline-block border border-solid border-grey-200 shadow-md">
  <div class="pt-1.5 pl-4 pr-1.5 pb-3">
    <div class="flex items-center">
      <div id="checklist-title" class="text-word-mark-800 font-semibold text-base grow">{{ title }}</div>
      <div class="flex">
        <div class="pr-1">
          <button (click)="onMinimize()" sb-icon-button [color]="'transparent'" [size]="'base'">
            <sb-icon [name]="'chevron-down'"></sb-icon>
          </button>
        </div>
        <div>
          <button (click)="onClose()" sb-icon-button [color]="'transparent'" [size]="'base'">
            <sb-icon [name]="'times'"></sb-icon>
          </button>
        </div>
      </div>
    </div>
    <div id="checklist-subtitle" class="text-sm text-word-mark-600">{{ subtitle }}</div>
  </div>

  <hr class="border-solid border-t-1 border-grey-200 w-full max-w-5xl m-0 mb-4" />

  <div *ngIf="progress" class="mx-4 mb-4 w-96">
    <sb-progress-bar [title]="progress.title" [subtitle]="progress.subtitle" [level]="progress.level"></sb-progress-bar>
  </div>

  <div
    class="mx-1.5"
    *ngFor="let task of tasks; let last = last; trackBy: trackByTitle"
    [ngClass]="last ? 'mb-6' : 'mb-2.5'"
  >
    <sb-checklist-item
      [title]="task.title"
      [link]="task.link"
      [isCompleted]="task.isCompleted"
      (clicked)="onItemClick($event)"
    ></sb-checklist-item>
  </div>
</div>
