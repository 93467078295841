import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { clsx } from 'clsx';

import { DialogContainerComponent } from './dialog-container/dialog-container.component';

export enum LegacyDialogSize {
  small = '300px',
  medium = '600px',
  large = '900px',
}
export type legacyDialogSize = keyof typeof LegacyDialogSize;

@Injectable()
export class LegacyDialogService {
  public readonly sizes = LegacyDialogSize;

  constructor(
    private readonly dialog: Dialog,
    private overlay: Overlay,
  ) {}

  /**
   * @deprecated This dialog service is deprecated. Use the new dialog service and module instead.
   */
  open<R = unknown, D = unknown, C = unknown>(
    component: ComponentType<C>,
    config?: DialogConfig<D, DialogRef<R, C>>,
  ): DialogRef<R, C> {
    const dialogRef = this.dialog.open(component, {
      hasBackdrop: true,
      container: DialogContainerComponent,
      width: LegacyDialogSize.medium,
      maxHeight: 'unset',
      positionStrategy: this.overlay.position().global().top('0').centerHorizontally(),
      ...config,
    });
    dialogRef.overlayRef.hostElement.classList.add(...clsx('pointer-events-auto overflow-auto').split(' '));
    return dialogRef;
  }
}
