import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[sbInputIconPrefix], sb-input-icon-prefix',
  standalone: true,
})
export class InputIconPrefixDirective {
  @HostBinding('class')
  hostClass = 'absolute inset-y-0 left-0 flex items-center px-3 text-grey-400';
}
