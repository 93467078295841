/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

import { BUTTON_HOST, ButtonBaseDirective } from './button-base.directive';
import { ButtonType, buttonType } from './button.model';

@Component({
  selector: 'button[sb-button]',
  standalone: true,
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: BUTTON_HOST,
})
export class ButtonComponent extends ButtonBaseDirective {
  @Input()
  @HostBinding('type')
  set type(value: buttonType) {
    this._type = value;
  }
  get type() {
    return this._type || ButtonType.button;
  }
  private _type?: buttonType;

  constructor(elementRef: ElementRef<HTMLButtonElement>) {
    super(elementRef);
  }
}

@Component({
  selector: 'a[sb-button]',
  standalone: true,
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: BUTTON_HOST,
})
export class AnchorButtonComponent extends ButtonBaseDirective {
  constructor(elementRef: ElementRef<HTMLAnchorElement>) {
    super(elementRef);
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.disabled || !this.clickable) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
