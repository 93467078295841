import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[sbSelectCollapseSpacer], sb-select-collapse-spacer',
  standalone: true,
})
export class SelectCollapseSpacerDirective {
  @HostBinding('class')
  hostClasses = 'w-3.5 mr-2 inline-block flex-shrink-0';
}
