import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'icon',
  standalone: true,
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    role: 'img',
    '[class.si]': 'true',
  },
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent extends MatIcon {
  @Input()
  @HostBinding('class.rotate-90')
  public rotate: boolean = false;

  @Input()
  @HostBinding('class.si--animated')
  public animated: boolean = false;
}
