import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { sbIcons } from '@sb/svg-icons';

import { IconButtonComponent } from '../button';
import { IconComponent } from '../icon';

type noticeThemePalette = keyof typeof NoticeThemePalette;

export enum NoticeThemePalette {
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
}

@Component({
  selector: 'sb-notice',
  standalone: true,
  imports: [CommonModule, IconComponent, IconButtonComponent],
  templateUrl: './notice.component.html',
})
export class NoticeComponent implements OnInit {
  public icon: sbIcons = 'info-circle';

  private readonly baseClass = 'flex gap-3 rounded border-2 border-solid p-3';

  private readonly colorClasses: Record<noticeThemePalette, string> = {
    [NoticeThemePalette.info]: 'text-grey-500 border-grey-300 bg-grey-100',
    [NoticeThemePalette.warning]: 'text-warning-500 border-warning-200 bg-warning-100',
    [NoticeThemePalette.danger]: 'text-error-500 border-error-200 bg-error-100',
    [NoticeThemePalette.success]: 'text-success-500 border-success-200 bg-success-50',
  };

  private readonly iconMap: Record<noticeThemePalette, sbIcons> = {
    [NoticeThemePalette.info]: 'info-circle',
    [NoticeThemePalette.warning]: 'exclamation-triangle',
    [NoticeThemePalette.danger]: 'exclamation-circle',
    [NoticeThemePalette.success]: 'check-circle',
  };

  @HostBinding('class')
  public hostClasses!: string;

  private _color: noticeThemePalette = NoticeThemePalette.info;
  @Input()
  public title?: string | SafeHtml;
  @Input()
  public description!: string | SafeHtml;
  @Input()
  public link?: string;
  @Output()
  public linkClicked = new EventEmitter();

  @Input()
  set color(value: noticeThemePalette) {
    if (this._color !== value) {
      this._color = value;
      this.setHostClasses();
      this.setIcon();
    }
  }
  get color() {
    return this._color;
  }

  public ngOnInit() {
    this.setHostClasses();
    this.setIcon();
  }

  private setHostClasses() {
    this.hostClasses = `${this.baseClass} ${this.colorClasses[this.color]}`;
  }

  private setIcon() {
    this.icon = this.iconMap[this.color];
  }
}
