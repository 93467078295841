<div class="grid w-max items-stretch divide-solid divide-grey-100 text-word-mark-800" [ngClass]="calendarClasses">
  @for (month of monthCalendars; track $index) {
    <div class="flex shrink-0 flex-col gap-3.5" [ngClass]="monthClasses">
      <sb-calendar-navigation
        [month]="month"
        [alwaysShowNavigation]="showStacked"
        (navigate)="navigate($event)"
      ></sb-calendar-navigation>
      @if (showWeekdays) {
        <sb-calendar-week>
          @for (weekday of weekDays; track $index) {
            <sb-calendar-day class="font-bold">
              {{ weekday }}
            </sb-calendar-day>
          }
        </sb-calendar-week>
      }
      @for (week of month.weeks; track $index) {
        <sb-calendar-week>
          @for (day of week; track $index) {
            <ng-container *ngTemplateOutlet="dayTemplate || defaultDayTemplate; context: { day: day }"></ng-container>
          }
        </sb-calendar-week>
      }
    </div>
  }
</div>

<ng-template #defaultDayTemplate let-day="day">
  <sb-calendar-day
    class="font-medium"
    [ngClass]="{
      'opacity-50': day.isOutOfMonth
    }"
  >
    <span
      [ngClass]="{
        'underline underline-offset-2 decoration-2': day.isToday
      }"
    >
      {{ day.monthDay }}
    </span>
  </sb-calendar-day>
</ng-template>
