import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-legacy-dialog-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentComponent {
  @Input()
  set hasSidebar(value: boolean) {
    this._hasSidebar = value;
    this.class = this.getHostClasses();
  }
  get hasSidebar(): boolean {
    return this._hasSidebar;
  }
  private _hasSidebar = false;

  @Input()
  withPadding = true;

  @HostBinding('class')
  public class = this.getHostClasses();

  private getHostClasses(): string {
    return clsx(this.hasSidebar ? 'flex' : 'block');
  }
}
