import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { LegendItem, LegendItemComponent } from './legend-item/legend-item.component';

@Component({
  selector: 'sb-legend',
  standalone: true,
  imports: [CommonModule, LegendItemComponent],
  templateUrl: './legend.component.html',
})
export class LegendComponent {
  @Input()
  public items: LegendItem[] = [];
  @Input()
  public restItem?: LegendItem;
}
