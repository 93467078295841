import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[sbDialogFooterActions], [sb-dialog-footer-actions], sb-dialog-footer-actions',
  exportAs: 'sbDialogFooterActions',
})
export class SbDialogFooterActionsDirective {
  @Input()
  align: 'start' | 'end' = 'start';

  @HostBinding('class.sb-dialog-footer-actions')
  readonly hostclass = true;

  @HostBinding('class.sb-dialog-footer-actions--align-end')
  get alignEnd(): boolean {
    return this.align === 'end';
  }
}
