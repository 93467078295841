import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { IconButtonComponent } from '../button';
import { ChecklistItemComponent } from '../checklist-item/checklist-item.component';
import { IconComponent } from '../icon';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';

interface ChecklistItemModel {
  title: string;
  isCompleted: boolean;
  link: string;
}

interface ProgressBarModel {
  title: string;
  subtitle: string;
  level: number;
}

@Component({
  selector: 'sb-checklist',
  standalone: true,
  imports: [CommonModule, IconComponent, IconButtonComponent, ChecklistItemComponent, ProgressBarComponent],
  templateUrl: './checklist.component.html',
})
export class ChecklistComponent {
  @HostBinding('class')
  public baseClass = 'absolute bottom-20 z-50';

  @Input()
  public title!: string;
  @Input()
  public subtitle!: string;
  @Input()
  public progress!: ProgressBarModel;
  @Input()
  public tasks!: ChecklistItemModel[];
  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public minimized: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public itemClicked: EventEmitter<void> = new EventEmitter<void>();

  public onMinimize() {
    this.minimized.emit();
  }

  public onClose() {
    this.closed.emit();
  }

  public onItemClick(value: void) {
    this.itemClicked.emit(value);
  }

  public trackByTitle(index: number, item: ChecklistItemModel): string {
    return item.title;
  }
}
