import { DialogConfig } from '@angular/cdk/dialog';

import { dialogSize, DialogSize, dialogWidth, DialogWidth } from '../dialog.models';

export const getDialogSize = (config: DialogConfig): dialogWidth => {
  switch (config.width) {
    case DialogWidth.sm:
      return DialogSize.sm;
    case DialogWidth.lg:
      return DialogSize.lg;
    case DialogWidth.xl:
      return DialogSize.xl;
    default:
      return DialogSize.md;
  }
};

export const getDialogWidth = (size: dialogSize): DialogWidth => {
  switch (size) {
    case DialogSize.sm:
      return DialogWidth.sm;
    case DialogSize.lg:
      return DialogWidth.lg;
    case DialogSize.xl:
      return DialogWidth.xl;
    default:
      return DialogWidth.md;
  }
};
