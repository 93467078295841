<!-- eslint-disable tailwindcss/no-custom-classname -->
<ng-template #simpleText>
  <div class="break-words font-semibold">{{ tooltipText }}</div>
</ng-template>
<div class="sb-tooltip-content">
  <ng-container *ngTemplateOutlet="tooltipTemplate || simpleText"></ng-container>
</div>
<span class="sb-tooltip-arrow">
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none">
    <path fill="currentColor" d="M11.068 10.02a2 2 0 0 1-3.136 0L0 0h19l-7.932 10.02Z" />
  </svg>
</span>
