<li class="flex items-center justify-between gap-8">
  <div class="flex items-center gap-1.5">
    <span
      class="h-3 w-3 rounded-full"
      [ngClass]="{ 'bg-grey-300': !item.color }"
      [ngStyle]="{ backgroundColor: item.color }"
    >
    </span>
    {{ item.label }}
  </div>
  <span class="text-right opacity-75">{{ item.tooltipText }}</span>
</li>
