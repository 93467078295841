<div class="flex flex-col items-center gap-4">
  <div class="flex flex-row" aria-hidden="true">
    @if (richIcon) {
      <svg class="mt-auto text-primary-300" width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="4.95" width="7" height="7" rx="2" transform="rotate(-45 0 4.95)" fill="currentColor" />
      </svg>
    }

    <div class="my-1 flex items-end justify-center rounded-xl bg-grey-100 p-2 text-grey-400">
      <sb-icon [name]="icon" [size]="'xl'"></sb-icon>
    </div>

    @if (richIcon) {
      <svg class="text-primary-500" width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="4" stroke="currentColor" stroke-width="2" />
      </svg>
    }
  </div>
  <div class="max-w-64">
    @if (title) {
      <h3 class="m-0 mb-2 text-center text-base font-bold text-grey-700">
        {{ title }}
      </h3>
    }
    <p class="m-0 text-center text-base font-medium text-grey-500">
      {{ description }}
    </p>
  </div>
</div>
<div class="mt-4 flex flex-wrap-reverse justify-center gap-4 empty:hidden">
  <ng-content></ng-content>
</div>
