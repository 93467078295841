export enum DialogSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum DialogWidth {
  sm = '442px',
  md = '600px',
  lg = '750px',
  xl = '900px',
}

export enum OverlayPosition {
  center = 'center',
  top = 'top',
}

export type dialogWidth = keyof typeof DialogWidth;

export type dialogSize = keyof typeof DialogSize;

export type overlayPosition = keyof typeof OverlayPosition;

export interface DialogTab {
  id: string;
  text: string;
  isSelected: boolean;
  isInvalid?: boolean;
}
