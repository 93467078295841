import { Component, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-calendar-day',
  standalone: true,
  template: `<ng-content></ng-content>`,
})
export class CalendarDayComponent {
  @HostBinding('class')
  public classes = clsx('flex h-8.5 w-8.5 grow-0 cursor-default items-center justify-center rounded-xl');
}
