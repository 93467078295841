import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconButtonComponent } from '../button';
import { IconComponent } from '../icon';
import { CalendarMonth } from './calendar.types';

@Component({
  selector: 'sb-calendar-navigation',
  standalone: true,
  imports: [CommonModule, IconButtonComponent, IconComponent],
  templateUrl: './calendar-navigation.component.html',
})
export class CalendarNavigationComponent {
  @Input({ required: true })
  public month!: CalendarMonth;

  @Input()
  public alwaysShowNavigation = false;

  @Output()
  public navigate = new EventEmitter<'previous' | 'next'>();
}
