import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconComponent } from '../icon';

@Component({
  selector: 'sb-checklist-item',
  templateUrl: './checklist-item.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconComponent, RouterModule],
})
export class ChecklistItemComponent {
  @Input()
  public title!: string;
  @Input()
  public link!: string;
  @Input()
  public isCompleted!: boolean;
  @Input()
  public disabled!: boolean;
  @Output()
  public clicked = new EventEmitter();
  @Output()
  public completed = new EventEmitter();

  public onComplete() {
    this.completed.emit();
  }

  public onClick() {
    if (this.disabled) {
      return;
    }

    this.clicked.emit(this.link);
  }
}
