<div
  class="relative z-1 h-full transition-width duration-500 ease-in-out"
  [ngClass]="{
    'w-64': isOpen,
    'w-0': !isOpen
  }"
>
  <div class="absolute inset-0 bg-white transition-width duration-500 ease-in-out">
    <div
      cdkScrollable
      [attr.aria-hidden]="!isOpen"
      class="h-full overflow-y-auto overflow-x-hidden shadow-xl transition-opacity duration-500 ease-in-out"
      [ngClass]="{
        'opacity-0': !isOpen,
        'opacity-100': isOpen
      }"
    >
      <div class="h-full min-w-64">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
