import { Injectable } from '@angular/core';
import { SbIcons, sbIcons } from '@sb/svg-icons';

import { legacyMapping } from './legacy-mapping';

@Injectable({
  providedIn: 'root',
})
export class IconRegistry {
  private registry = new Map<string, string>();

  public registerIcons(icons: SbIcons[]): void {
    icons.forEach((icon) => {
      this.registerIcon(icon.name, icon.data);
    });
  }

  public registerIcon(name: sbIcons, data: string): void {
    this.registry.set(name, data);
  }

  public getIcon(name: sbIcons): string | undefined {
    if (!this.hasIcon(name)) {
      console.warn(`Icon ${name} not found in registry, did you add it to the IconRegistry?`);
    }
    if (legacyMapping[name]) {
      return this.registry.get(legacyMapping[name]);
    }
    return this.registry.get(name);
  }

  public hasIcon(name: sbIcons): boolean {
    if (legacyMapping[name]) {
      return true;
    }
    return this.registry.has(name);
  }
}
