import { NgClass } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, inject, Input } from '@angular/core';
import { sbIcons } from '@sb/svg-icons';
import { clsx } from 'clsx';

import { IconComponent } from '../../icon';
import { SidebarAccordionItemDirective } from './sidebar-accordion-item.directive';
import { SB_SIDEBAR } from './sidebar.token';

enum SidebarItemState {
  active = 'active',
  expanded = 'expanded',
  disabled = 'disabled',
  locked = 'locked',
  void = 'void',
}

@Component({
  selector: 'sb-sidebar-item, [sb-sidebar-item]',
  standalone: true,
  imports: [NgClass, IconComponent],
  templateUrl: './sidebar-item.component.html',
})
export class SidebarItemComponent {
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private sidebar = inject(SB_SIDEBAR, { optional: true });

  @Input()
  icon?: sbIcons;

  @Input()
  @HostBinding('class.pointer-events-none')
  disabled = false;

  @HostBinding('attr.tabindex')
  get tabIndex() {
    return this.disabled ? -1 : 0;
  }

  @HostListener('click')
  onClick() {
    if (!this.clickable || !this.accordionItemDirective) {
      return;
    }

    this.accordionItemDirective.toggle();
    this.sidebar?.closeOtherAccordionItems(this.accordionItemDirective.id);
  }

  @Input()
  locked = false;

  @Input()
  active = false;

  get collapsable() {
    return !!this.accordionItemDirective;
  }

  accordionItemDirective?: SidebarAccordionItemDirective;

  @HostBinding('class')
  hostClass = clsx(
    ['group/item'],
    ['m-0 block w-full appearance-none border-none bg-transparent p-0'],
    ['text-left text-base font-semibold'],
    ['cursor-pointer'],
    ['focus:outline-none active:outline-none'],
  );

  get state() {
    if (this.disabled) {
      return SidebarItemState.disabled;
    }
    if (this.locked) {
      return SidebarItemState.locked;
    }
    if (this.collapsable && this.active) {
      return SidebarItemState.expanded;
    }
    if (this.active) {
      return SidebarItemState.active;
    }
    return SidebarItemState.void;
  }

  click() {
    this.elementRef.nativeElement.click();
  }

  get clickable(): boolean {
    return !this.disabled && !this.locked;
  }

  registerAccordionItem(item: SidebarAccordionItemDirective) {
    this.accordionItemDirective = item;
  }
}
