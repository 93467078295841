import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[sbDialogFooter], [sb-dialog-footer], sb-dialog-footer',
  exportAs: 'sbDialogFooter',
})
export class SbDialogFooterDirective {
  @HostBinding('class.sb-dialog-footer')
  readonly hostclass = true;
}
