import { ConnectedPosition } from '@angular/cdk/overlay';

export enum PopoverPlacement {
  'bottom' = 'bottom',
  'top' = 'top',
  'left' = 'left',
  'right' = 'right',
}
export type popoverPlacement = keyof typeof PopoverPlacement;

export const PopoverPositions: Record<PopoverPlacement, ConnectedPosition> = {
  [PopoverPlacement.bottom]: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    panelClass: ['sb-popover-bottom'],
  },
  [PopoverPlacement.top]: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    panelClass: ['sb-popover-top'],
  },
  [PopoverPlacement.right]: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    panelClass: ['sb-popover-right'],
  },
  [PopoverPlacement.left]: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    panelClass: ['sb-popover-left'],
  },
};
