import { RegionCode } from 'google-libphonenumber';

import { CountryBase } from './country.model';

// these regions has higher priority
export const priorityRecord: Record<string, RegionCode> = {
  '1': 'US',
  '7': 'RU',
  '39': 'IT',
  '44': 'GB',
  '47': 'NO',
  '61': 'AU',
  '212': 'MA',
  '262': 'RE',
  '290': 'SH',
  '358': 'FI',
  '590': 'GP',
  '599': 'CW',
};

// AC', 'TA' are missing from the full region code set
export const countries: CountryBase[] = [
  { name: 'Afghanistan (‫افغانستان‬‎)', regionCode: 'AF' },
  { name: 'Albania (Shqipëri)', regionCode: 'AL' },
  { name: 'Algeria (‫الجزائر‬‎)', regionCode: 'DZ' },
  { name: 'American Samoa', regionCode: 'AS' },
  { name: 'Andorra', regionCode: 'AD' },
  { name: 'Angola', regionCode: 'AO' },
  { name: 'Anguilla', regionCode: 'AI' },
  { name: 'Antigua and Barbuda', regionCode: 'AG' },
  { name: 'Argentina', regionCode: 'AR' },
  { name: 'Armenia (Հայաստան)', regionCode: 'AM' },
  { name: 'Aruba', regionCode: 'AW' },
  { name: 'Australia', regionCode: 'AU' },
  { name: 'Austria (Österreich)', regionCode: 'AT' },
  { name: 'Azerbaijan (Azərbaycan)', regionCode: 'AZ' },
  { name: 'Bahamas', regionCode: 'BS' },
  { name: 'Bahrain (‫البحرين‬‎)', regionCode: 'BH' },
  { name: 'Bangladesh (বাংলাদেশ)', regionCode: 'BD' },
  { name: 'Barbados', regionCode: 'BB' },
  { name: 'Belarus (Беларусь)', regionCode: 'BY' },
  { name: 'Belgium (België)', regionCode: 'BE' },
  { name: 'Belize', regionCode: 'BZ' },
  { name: 'Benin (Bénin)', regionCode: 'BJ' },
  { name: 'Bermuda', regionCode: 'BM' },
  { name: 'Bhutan (འབྲུག)', regionCode: 'BT' },
  { name: 'Bolivia', regionCode: 'BO' },
  { name: 'Bosnia and Herzegovina (Босна и Херцеговина)', regionCode: 'BA' },
  { name: 'Botswana', regionCode: 'BW' },
  { name: 'Brazil (Brasil)', regionCode: 'BR' },
  { name: 'British Indian Ocean Territory', regionCode: 'IO' },
  { name: 'British Virgin Islands', regionCode: 'VG' },
  { name: 'Brunei', regionCode: 'BN' },
  { name: 'Bulgaria (България)', regionCode: 'BG' },
  { name: 'Burkina Faso', regionCode: 'BF' },
  { name: 'Burundi (Uburundi)', regionCode: 'BI' },
  { name: 'Cambodia (កម្ពុជា)', regionCode: 'KH' },
  { name: 'Cameroon (Cameroun)', regionCode: 'CM' },
  { name: 'Canada', regionCode: 'CA' },
  { name: 'Cape Verde (Kabu Verdi)', regionCode: 'CV' },
  { name: 'Caribbean Netherlands', regionCode: 'BQ' },
  { name: 'Cayman Islands', regionCode: 'KY' },
  { name: 'Central African Republic (République centrafricaine)', regionCode: 'CF' },
  { name: 'Chad (Tchad)', regionCode: 'TD' },
  { name: 'Chile', regionCode: 'CL' },
  { name: 'China (中国)', regionCode: 'CN' },
  { name: 'Christmas Island', regionCode: 'CX' },
  { name: 'Cocos (Keeling) Islands', regionCode: 'CC' },
  { name: 'Colombia', regionCode: 'CO' },
  { name: 'Comoros (‫جزر القمر‬‎)', regionCode: 'KM' },
  { name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', regionCode: 'CD' },
  { name: 'Congo (Republic) (Congo-Brazzaville)', regionCode: 'CG' },
  { name: 'Cook Islands', regionCode: 'CK' },
  { name: 'Costa Rica', regionCode: 'CR' },
  { name: 'Côte d’Ivoire', regionCode: 'CI' },
  { name: 'Croatia (Hrvatska)', regionCode: 'HR' },
  { name: 'Cuba', regionCode: 'CU' },
  { name: 'Curaçao', regionCode: 'CW' },
  { name: 'Cyprus (Κύπρος)', regionCode: 'CY' },
  { name: 'Czech Republic (Česká republika)', regionCode: 'CZ' },
  { name: 'Denmark (Danmark)', regionCode: 'DK' },
  { name: 'Djibouti', regionCode: 'DJ' },
  { name: 'Dominica', regionCode: 'DM' },
  { name: 'Dominican Republic (República Dominicana)', regionCode: 'DO' },
  { name: 'Ecuador', regionCode: 'EC' },
  { name: 'Egypt (‫مصر‬‎)', regionCode: 'EG' },
  { name: 'El Salvador', regionCode: 'SV' },
  { name: 'Equatorial Guinea (Guinea Ecuatorial)', regionCode: 'GQ' },
  { name: 'Eritrea', regionCode: 'ER' },
  { name: 'Estonia (Eesti)', regionCode: 'EE' },
  { name: 'Ethiopia', regionCode: 'ET' },
  { name: 'Falkland Islands (Islas Malvinas)', regionCode: 'FK' },
  { name: 'Faroe Islands (Føroyar)', regionCode: 'FO' },
  { name: 'Fiji', regionCode: 'FJ' },
  { name: 'Finland (Suomi)', regionCode: 'FI' },
  { name: 'France', regionCode: 'FR' },
  { name: 'French Guiana (Guyane française)', regionCode: 'GF' },
  { name: 'French Polynesia (Polynésie française)', regionCode: 'PF' },
  { name: 'Gabon', regionCode: 'GA' },
  { name: 'Gambia', regionCode: 'GM' },
  { name: 'Georgia (საქართველო)', regionCode: 'GE' },
  { name: 'Germany (Deutschland)', regionCode: 'DE' },
  { name: 'Ghana (Gaana)', regionCode: 'GH' },
  { name: 'Gibraltar', regionCode: 'GI' },
  { name: 'Greece (Ελλάδα)', regionCode: 'GR' },
  { name: 'Greenland (Kalaallit Nunaat)', regionCode: 'GL' },
  { name: 'Grenada', regionCode: 'GD' },
  { name: 'Guadeloupe', regionCode: 'GP' },
  { name: 'Guam', regionCode: 'GU' },
  { name: 'Guatemala', regionCode: 'GT' },
  { name: 'Guernsey', regionCode: 'GG' },
  { name: 'Guinea (Guinée)', regionCode: 'GN' },
  { name: 'Guinea-Bissau (Guiné Bissau)', regionCode: 'GW' },
  { name: 'Guyana', regionCode: 'GY' },
  { name: 'Haiti', regionCode: 'HT' },
  { name: 'Honduras', regionCode: 'HN' },
  { name: 'Hong Kong (香港)', regionCode: 'HK' },
  { name: 'Hungary (Magyarország)', regionCode: 'HU' },
  { name: 'Iceland (Ísland)', regionCode: 'IS' },
  { name: 'India (भारत)', regionCode: 'IN' },
  { name: 'Indonesia', regionCode: 'ID' },
  { name: 'Iran (‫ایران‬‎)', regionCode: 'IR' },
  { name: 'Iraq (‫العراق‬‎)', regionCode: 'IQ' },
  { name: 'Ireland', regionCode: 'IE' },
  { name: 'Isle of Man', regionCode: 'IM' },
  { name: 'Israel (‫ישראל‬‎)', regionCode: 'IL' },
  { name: 'Italy (Italia)', regionCode: 'IT' },
  { name: 'Jamaica', regionCode: 'JM' },
  { name: 'Japan (日本)', regionCode: 'JP' },
  { name: 'Jersey', regionCode: 'JE' },
  { name: 'Jordan (‫الأردن‬‎)', regionCode: 'JO' },
  { name: 'Kazakhstan (Казахстан)', regionCode: 'KZ' },
  { name: 'Kenya', regionCode: 'KE' },
  { name: 'Kiribati', regionCode: 'KI' },
  { name: 'Kosovo', regionCode: 'XK' },
  { name: 'Kuwait (‫الكويت‬‎)', regionCode: 'KW' },
  { name: 'Kyrgyzstan (Кыргызстан)', regionCode: 'KG' },
  { name: 'Laos (ລາວ)', regionCode: 'LA' },
  { name: 'Latvia (Latvija)', regionCode: 'LV' },
  { name: 'Lebanon (‫لبنان‬‎)', regionCode: 'LB' },
  { name: 'Lesotho', regionCode: 'LS' },
  { name: 'Liberia', regionCode: 'LR' },
  { name: 'Libya (‫ليبيا‬‎)', regionCode: 'LY' },
  { name: 'Liechtenstein', regionCode: 'LI' },
  { name: 'Lithuania (Lietuva)', regionCode: 'LT' },
  { name: 'Luxembourg', regionCode: 'LU' },
  { name: 'Macau (澳門)', regionCode: 'MO' },
  { name: 'Macedonia (FYROM) (Македонија)', regionCode: 'MK' },
  { name: 'Madagascar (Madagasikara)', regionCode: 'MG' },
  { name: 'Malawi', regionCode: 'MW' },
  { name: 'Malaysia', regionCode: 'MY' },
  { name: 'Maldives', regionCode: 'MV' },
  { name: 'Mali', regionCode: 'ML' },
  { name: 'Malta', regionCode: 'MT' },
  { name: 'Marshall Islands', regionCode: 'MH' },
  { name: 'Martinique', regionCode: 'MQ' },
  { name: 'Mauritania (‫موريتانيا‬‎)', regionCode: 'MR' },
  { name: 'Mauritius (Moris)', regionCode: 'MU' },
  { name: 'Mayotte', regionCode: 'YT' },
  { name: 'Mexico (México)', regionCode: 'MX' },
  { name: 'Micronesia', regionCode: 'FM' },
  { name: 'Moldova (Republica Moldova)', regionCode: 'MD' },
  { name: 'Monaco', regionCode: 'MC' },
  { name: 'Mongolia (Монгол)', regionCode: 'MN' },
  { name: 'Montenegro (Crna Gora)', regionCode: 'ME' },
  { name: 'Montserrat', regionCode: 'MS' },
  { name: 'Morocco (‫المغرب‬‎)', regionCode: 'MA' },
  { name: 'Mozambique (Moçambique)', regionCode: 'MZ' },
  { name: 'Myanmar (Burma) (မြန်မာ)', regionCode: 'MM' },
  { name: 'Namibia (Namibië)', regionCode: 'NA' },
  { name: 'Nauru', regionCode: 'NR' },
  { name: 'Nepal (नेपाल)', regionCode: 'NP' },
  { name: 'Netherlands (Nederland)', regionCode: 'NL' },
  { name: 'New Caledonia (Nouvelle-Calédonie)', regionCode: 'NC' },
  { name: 'New Zealand', regionCode: 'NZ' },
  { name: 'Nicaragua', regionCode: 'NI' },
  { name: 'Niger (Nijar)', regionCode: 'NE' },
  { name: 'Nigeria', regionCode: 'NG' },
  { name: 'Niue', regionCode: 'NU' },
  { name: 'Norfolk Island', regionCode: 'NF' },
  { name: 'North Korea (조선 민주주의 인민 공화국)', regionCode: 'KP' },
  { name: 'Northern Mariana Islands', regionCode: 'MP' },
  { name: 'Norway (Norge)', regionCode: 'NO' },
  { name: 'Oman (‫عُمان‬‎)', regionCode: 'OM' },
  { name: 'Pakistan (‫پاکستان‬‎)', regionCode: 'PK' },
  { name: 'Palau', regionCode: 'PW' },
  { name: 'Palestine (‫فلسطين‬‎)', regionCode: 'PS' },
  { name: 'Panama (Panamá)', regionCode: 'PA' },
  { name: 'Papua New Guinea', regionCode: 'PG' },
  { name: 'Paraguay', regionCode: 'PY' },
  { name: 'Peru (Perú)', regionCode: 'PE' },
  { name: 'Philippines', regionCode: 'PH' },
  { name: 'Poland (Polska)', regionCode: 'PL' },
  { name: 'Portugal', regionCode: 'PT' },
  { name: 'Puerto Rico', regionCode: 'PR' },
  { name: 'Qatar (‫قطر‬‎)', regionCode: 'QA' },
  { name: 'Réunion (La Réunion)', regionCode: 'RE' },
  { name: 'Romania (România)', regionCode: 'RO' },
  { name: 'Russia (Россия)', regionCode: 'RU' },
  { name: 'Rwanda', regionCode: 'RW' },
  { name: 'Saint Barthélemy (Saint-Barthélemy)', regionCode: 'BL' },
  { name: 'Saint Helena', regionCode: 'SH' },
  { name: 'Saint Kitts and Nevis', regionCode: 'KN' },
  { name: 'Saint Lucia', regionCode: 'LC' },
  { name: 'Saint Martin (Saint-Martin (partie française))', regionCode: 'MF' },
  { name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', regionCode: 'PM' },
  { name: 'Saint Vincent and the Grenadines', regionCode: 'VC' },
  { name: 'Samoa', regionCode: 'WS' },
  { name: 'San Marino', regionCode: 'SM' },
  { name: 'São Tomé and Príncipe (São Tomé e Príncipe)', regionCode: 'ST' },
  { name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', regionCode: 'SA' },
  { name: 'Senegal (Sénégal)', regionCode: 'SN' },
  { name: 'Serbia (Србија)', regionCode: 'RS' },
  { name: 'Seychelles', regionCode: 'SC' },
  { name: 'Sierra Leone', regionCode: 'SL' },
  { name: 'Singapore', regionCode: 'SG' },
  { name: 'Sint Maarten', regionCode: 'SX' },
  { name: 'Slovakia (Slovensko)', regionCode: 'SK' },
  { name: 'Slovenia (Slovenija)', regionCode: 'SI' },
  { name: 'Solomon Islands', regionCode: 'SB' },
  { name: 'Somalia (Soomaaliya)', regionCode: 'SO' },
  { name: 'South Africa', regionCode: 'ZA' },
  { name: 'South Korea (대한민국)', regionCode: 'KR' },
  { name: 'South Sudan (‫جنوب السودان‬‎)', regionCode: 'SS' },
  { name: 'Spain (España)', regionCode: 'ES' },
  { name: 'Sri Lanka (ශ්‍රී ලංකාව)', regionCode: 'LK' },
  { name: 'Sudan (‫السودان‬‎)', regionCode: 'SD' },
  { name: 'Suriname', regionCode: 'SR' },
  { name: 'Svalbard and Jan Mayen', regionCode: 'SJ' },
  { name: 'Swaziland', regionCode: 'SZ' },
  { name: 'Sweden (Sverige)', regionCode: 'SE' },
  { name: 'Switzerland (Schweiz)', regionCode: 'CH' },
  { name: 'Syria (‫سوريا‬‎)', regionCode: 'SY' },
  { name: 'Taiwan (台灣)', regionCode: 'TW' },
  { name: 'Tajikistan', regionCode: 'TJ' },
  { name: 'Tanzania', regionCode: 'TZ' },
  { name: 'Thailand (ไทย)', regionCode: 'TH' },
  { name: 'Timor-Leste', regionCode: 'TL' },
  { name: 'Togo', regionCode: 'TG' },
  { name: 'Tokelau', regionCode: 'TK' },
  { name: 'Tonga', regionCode: 'TO' },
  { name: 'Trinidad and Tobago', regionCode: 'TT' },
  { name: 'Tunisia (‫تونس‬‎)', regionCode: 'TN' },
  { name: 'Turkey (Türkiye)', regionCode: 'TR' },
  { name: 'Turkmenistan', regionCode: 'TM' },
  { name: 'Turks and Caicos Islands', regionCode: 'TC' },
  { name: 'Tuvalu', regionCode: 'TV' },
  { name: 'U.S. Virgin Islands', regionCode: 'VI' },
  { name: 'Uganda', regionCode: 'UG' },
  { name: 'Ukraine (Україна)', regionCode: 'UA' },
  { name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', regionCode: 'AE' },
  { name: 'United Kingdom', regionCode: 'GB' },
  { name: 'United States', regionCode: 'US' },
  { name: 'Uruguay', regionCode: 'UY' },
  { name: 'Uzbekistan (Oʻzbekiston)', regionCode: 'UZ' },
  { name: 'Vanuatu', regionCode: 'VU' },
  { name: 'Vatican City (Città del Vaticano)', regionCode: 'VA' },
  { name: 'Venezuela', regionCode: 'VE' },
  { name: 'Vietnam (Việt Nam)', regionCode: 'VN' },
  { name: 'Wallis and Futuna', regionCode: 'WF' },
  { name: 'Western Sahara (‫الصحراء الغربية‬‎)', regionCode: 'EH' },
  { name: 'Yemen (‫اليمن‬‎)', regionCode: 'YE' },
  { name: 'Zambia', regionCode: 'ZM' },
  { name: 'Zimbabwe', regionCode: 'ZW' },
  { name: 'Åland Islands', regionCode: 'AX' },
];
