import { Injectable, signal } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export interface FormValueChangesResult {
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class FormValueChangesService<TValue = any, TRawValue extends TValue = TValue> {
  private _initData?: TRawValue;

  public readonly result = signal<FormValueChangesResult>({ count: 0 });

  public get initData(): TRawValue | undefined {
    return this._initData;
  }

  public init(value: AbstractControl<TValue, TRawValue>) {
    this._initData = value.getRawValue();
    this.result.set({ count: 0 });
  }

  public count(value: AbstractControl<TValue, TRawValue>): void {
    if (!this.initData) {
      throw new Error('Initial data is not set');
    }
    try {
      this.result.set(this._count(value, this.initData));
    } catch (e) {
      console.error('error', e);

      this.result.set({ count: 0 });
    }
  }

  private _count(control: AbstractControl<TValue, TRawValue>, initValue: any): FormValueChangesResult {
    if (control instanceof FormGroup) {
      return Object.keys(control.controls).reduce(
        (acc, key) => {
          const nestedCount = this._count(control.controls[key], initValue[key]);
          return { count: acc.count + nestedCount.count };
        },
        { count: 0 },
      );
    }

    // nested form arrays may not always work as expected
    if (control instanceof FormArray) {
      return control.controls.reduce(
        (acc, control, index) => {
          const nestedCount = this._count(control, initValue[index]);
          return { count: acc.count + nestedCount.count };
        },
        { count: 0 },
      );
    }

    // more value checks can be added here, e.g. for arrays, dates, etc.
    if (control.value !== initValue) {
      return { count: 1 };
    }

    return { count: 0 };
  }
}
