import { FocusKeyManager, FocusOrigin } from '@angular/cdk/a11y';
import { DOWN_ARROW, ESCAPE, hasModifierKey, UP_ARROW } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { clsx } from 'clsx';
import { Subject } from 'rxjs';

import { DropdownItemDirective } from './dropdown-item.directive';
import { MENU_TRIGGER } from './dropdown-toggle.directive';

let nextId = 0;

@Directive({
  selector: '[sbDropdownMenu]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'menu',
    class: 'sb-dropdown-menu',
    '(keydown)': 'handleKeyEvent($event)',
  },
})
export class DropdownMenuDirective implements AfterContentInit, OnDestroy {
  private parentTrigger = inject(MENU_TRIGGER, { optional: true });
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private readonly _document: Document = inject(DOCUMENT);
  @ContentChildren(DropdownItemDirective, { descendants: true })
  readonly items!: QueryList<DropdownItemDirective>;

  @Input()
  id = `sb-dropdown-menu-${nextId++}`;

  protected keyManager: FocusKeyManager<DropdownItemDirective> | undefined;
  protected readonly destroyed$ = new Subject<void>();
  private endAnchor?: HTMLElement;

  @HostBinding('class')
  hostClasses = clsx([
    'inline-flex flex-col overflow-auto rounded-base',
    'my-1 px-0 py-2',
    'bg-white text-word-mark-800 shadow-md',
    'border border-solid border-grey-200 outline-none',
  ]);

  protected endAnchorListener = () => this.parentTrigger?.close({ focusTrigger: true });

  constructor() {
    this.parentTrigger?.registerChildMenu(this);
  }

  ngAfterContentInit(): void {
    this.attachFocusEndAnchor();
    this.setKeyManager();
  }

  ngOnDestroy(): void {
    this.detachFocusEndAnchor();
    this.keyManager?.destroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  focusFirstElement(): void {
    const focusElements = this.getFocusableElements();
    focusElements[0]?.focus();
  }

  focusFirstItem(focusOrigin: FocusOrigin = 'program') {
    this.keyManager?.setFocusOrigin(focusOrigin);
    this.keyManager?.setFirstItemActive();
  }

  focusLastItem(focusOrigin: FocusOrigin = 'program') {
    this.keyManager?.setFocusOrigin(focusOrigin);
    this.keyManager?.setLastItemActive();
  }

  // list of common focusable elements
  private getFocusableElements(): NodeListOf<HTMLElement> {
    return this.elementRef.nativeElement.querySelectorAll(
      'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)',
    );
  }

  handleKeyEvent(event: KeyboardEvent) {
    const keyManager = this.keyManager;
    if (!keyManager) {
      return;
    }
    switch (event.keyCode) {
      case ESCAPE:
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this.parentTrigger?.close({ focusTrigger: true });
        }
        break;
      case DOWN_ARROW:
      case UP_ARROW:
        if (!hasModifierKey(event)) {
          event.preventDefault();
          keyManager.setFocusOrigin('keyboard');
          keyManager.onKeydown(event);
        }
        break;

      default:
        keyManager.onKeydown(event);
    }
  }

  private setKeyManager() {
    this.keyManager = new FocusKeyManager(this.items).withWrap().withHomeAndEnd();
    this.keyManager.withVerticalOrientation();

    const selected = this.selectedItem;
    if (selected) {
      this.keyManager.setActiveItem(selected);
    }
  }

  private get selectedItem(): DropdownItemDirective | undefined {
    return this.items.find((item) => item.selected);
  }

  // focus end achor is needed to be able to know when to close the menu on tab sequence
  private attachFocusEndAnchor() {
    this.endAnchor = this.createTabAnchor();
    this.endAnchor.addEventListener('focus', this.endAnchorListener);
    this.elementRef.nativeElement.parentNode?.insertBefore(this.endAnchor, this.elementRef.nativeElement.nextSibling);
  }

  private detachFocusEndAnchor() {
    if (this.endAnchor) {
      this.endAnchor.removeEventListener('focus', this.endAnchorListener);
      this.endAnchor.remove();
    }
  }

  private createTabAnchor(): HTMLElement {
    const anchor = this._document.createElement('div');
    anchor.classList.add('sb-focus-trap-anchor');
    anchor.setAttribute('tabindex', '0');
    anchor.setAttribute('aria-hidden', 'true');
    return anchor;
  }
}
