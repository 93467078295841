import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sbDropdownDivider], sb-dropdown-divider',
  standalone: true,
})
export class DropdownDividerDirective {
  @HostBinding('class')
  class = clsx('mx-4 my-2.5 block h-px overflow-hidden bg-grey-200');
}
