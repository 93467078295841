import { DialogRef } from '@angular/cdk/dialog';
import { Directive, HostBinding, HostListener, Input, Optional } from '@angular/core';

@Directive({
  selector: '[sbLegacyDialogClose], [sb-legacy-dialog-close]',
  exportAs: 'sbLegacyDialogClose',
  standalone: true,
})
export class DialogCloseDirective {
  @Input()
  @HostBinding('attr.type')
  type: 'submit' | 'button' | 'reset' = 'button';

  @Input('sb-legacy-dialog-close')
  dialogResult: any;
  @Input('sbLegacyDialogClose')
  dialogClose: any;

  @HostListener('click')
  onClick(): void {
    this.dialogRef.close(this.dialogResult || this.dialogClose);
  }

  constructor(@Optional() public dialogRef: DialogRef<any>) {}
}
