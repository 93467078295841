<div
  class="flex h-6 gap-0.5 overflow-hidden rounded-base"
  [sbTooltip]="tooltipTemplate"
  [sbTooltipPlacement]="tooltipPlacement"
  [sbTooltipMaxWidth]="'30rem'"
  [sbTooltipDisabled]="!items.length && !restItem"
>
  <span
    class="min-w-1.5"
    *ngFor="let item of items; trackBy: trackByIdFn"
    [ngStyle]="{ backgroundColor: item.color, width: item.percentage + '%' }"
  >
  </span>
  <span
    class="flex-1"
    [ngStyle]="{ backgroundColor: restItem?.color ?? null }"
    [ngClass]="{ 'bg-grey-300': !restItem?.color }"
  >
  </span>
</div>

<sb-legend class="mb-0 mt-3 inline-block" [items]="items" [restItem]="restItem"></sb-legend>

<ng-template #tooltipTemplate>
  <ul
    id="tooltip-list"
    class="m-0 flex list-none flex-col gap-2 whitespace-nowrap px-3 py-2 text-base font-medium text-white"
  >
    <sb-horizontal-stacked-bar-tooltip-item
      *ngFor="let item of items; trackBy: trackByIdFn"
      [item]="item"
    ></sb-horizontal-stacked-bar-tooltip-item>
    <sb-horizontal-stacked-bar-tooltip-item *ngIf="restItem" [item]="restItem"></sb-horizontal-stacked-bar-tooltip-item>
  </ul>
</ng-template>
