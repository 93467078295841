import { Component, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-sidebar-drawer-container',
  exportAs: 'sbSidebarDrawerContainer',
  standalone: true,
  template: `
    <ng-content select="sb-sidebar-drawer"></ng-content>
    <ng-content select="sb-sidebar-drawer-content"></ng-content>
  `,
})
export class SidebarDrawerContainerComponent {
  @HostBinding('class')
  hostClass = clsx('relative flex h-full flex-row');
}
