import { Directive, HostBinding } from '@angular/core';
import { InputBaseDirective } from './input-base.directive';

@Directive({
  standalone: true,
})
export class InputBaseSelectDirective extends InputBaseDirective {
  @HostBinding('class')
  hostClass = 'sb-select text-left cursor-pointer disabled:pointer-events-none';
}

@Directive({
  selector: 'select[sbInput]',
  standalone: true,
})
export class InputSelectDirective extends InputBaseSelectDirective {
  @HostBinding('required')
  get attachRequired() {
    return this.required;
  }
}

@Directive({
  selector: 'button[sbInput]',
  standalone: true,
})
export class InputButtonSelectDirective extends InputBaseSelectDirective {}
