import { Directive, HostBinding, HostListener, Input, Optional } from '@angular/core';

import { DialogRef } from '../dialog';

@Directive({
  selector: '[sbDialogClose], [sb-dialog-close]',
  exportAs: 'sbDialogClose',
  standalone: true,
})
export class SbDialogCloseDirective {
  @Input()
  @HostBinding('attr.type')
  type: 'submit' | 'button' | 'reset' = 'button';

  @Input('sb-dialog-close')
  dialogResult: any;
  @Input('sbDialogClose')
  dialogClose: any;

  @HostBinding('disabled')
  get disableClose(): boolean {
    return !!this.dialogRef?.disableClose;
  }

  @HostListener('click')
  onClick(): void {
    this.dialogRef.close(this.dialogResult || this.dialogClose);
  }

  constructor(@Optional() public dialogRef: DialogRef<any>) {}
}
