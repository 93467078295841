import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'sb-bubble-notification',
  templateUrl: './bubble-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class BubbleNotificationComponent {
  @HostBinding('class')
  public baseClass = 'absolute bottom-7 z-50';

  @Input()
  public notificationsCount?: number;
  @Input()
  public title!: string;
  @Output()
  public clicked: EventEmitter<void> = new EventEmitter<void>();

  public onClick() {
    this.clicked.emit();
  }
}
