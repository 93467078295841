import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { CdkDialogContainer, DialogConfig } from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, ElementRef, HostBinding, NgZone } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-legacy-dialog-container',
  templateUrl: './dialog-container.component.html',
})
export class DialogContainerComponent extends CdkDialogContainer {
  @HostBinding('class')
  private readonly class = clsx('h-full max-h-inherit min-h-inherit w-full py-8');

  constructor(
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    config: DialogConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor: FocusMonitor,
  ) {
    super(elementRef, focusTrapFactory, document, config, interactivityChecker, ngZone, overlayRef, focusMonitor);
  }
}
