export type PageItem = number | '...';

export const getPageRange = (start: number, end: number): number[] => {
  if (end < start) {
    throw 'End must be greater than start';
  }
  const rangeLength = end - start + 1;
  return Array.from({ length: rangeLength }, (_, i) => i + start);
};

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

// helper function to calculate which pages to show in the pagination
export const calculatePages = ({
  currentPage,
  totalPages,
  maxSize,
}: {
  currentPage: number;
  totalPages: number;
  maxSize: number;
}): PageItem[] => {
  // if there are no pages, nothing to do
  if (totalPages < 1) {
    return [];
  }
  // current page should not be less than 1
  if (currentPage < 1) {
    currentPage = 1;
  }
  // current page should not be greater than total pages
  if (currentPage > totalPages) {
    currentPage = totalPages;
  }
  // maxSize must be odd number
  if (maxSize % 2 === 0) {
    maxSize++;
  }
  // maxSize should not be less than 5
  if (maxSize < 5) {
    maxSize = 5;
  }

  const offset = (maxSize - 1) / 2;

  const shouldAddDots = totalPages > maxSize;

  const rangeConfig = {
    start: clamp(currentPage - offset, 1, shouldAddDots ? totalPages - maxSize + 1 : 1),
    end: clamp(currentPage + offset, maxSize, totalPages),
  };

  const pages: PageItem[] = getPageRange(rangeConfig.start, rangeConfig.end);

  if (shouldAddDots && pages[0] !== 1) {
    pages[0] = 1;
    pages[1] = '...';
  }

  if (shouldAddDots && pages[pages.length - 1] !== totalPages) {
    pages[pages.length - 2] = '...';
    pages[pages.length - 1] = totalPages;
  }

  return pages;
};
