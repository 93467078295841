import { Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerComponent } from '../../spinner';

@Component({
  selector: 'sb-loading-state',
  standalone: true,
  imports: [SpinnerComponent, TranslateModule],
  templateUrl: './loading-state.component.html',
})
export class LoadingStateComponent {
  @Input()
  label?: string;

  @HostBinding('class')
  classes = 'flex flex-col items-center justify-center max-w-full';
}
