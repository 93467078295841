import { Component, Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sbCardTitle], [sb-card-title], sb-card-title',
  standalone: true,
  exportAs: 'sbCardTitle',
})
export class CardTitleDirective {
  @HostBinding('class')
  hostClass = clsx('m-0 block text-lg font-bold text-word-mark-800');
}

@Component({
  selector: 'sb-card',
  standalone: true,
  template: `<ng-content></ng-content>`,
})
export class CardComponent {
  @HostBinding('class')
  hostClass = clsx('relative box-border flex flex-col rounded-base border border-solid border-grey-200 bg-white');
}

@Component({
  selector: 'sb-card-header',
  standalone: true,
  template: `<ng-content></ng-content>`,
})
export class CardHeaderComponent {
  @HostBinding('class')
  hostClass = clsx('flex flex-col px-6 pt-6 last:pb-6');
}

@Component({
  selector: 'sb-card-content',
  standalone: true,
  template: `<ng-content></ng-content>`,
})
export class CardContentComponent {
  @HostBinding('class')
  hostClass = clsx('flex grow flex-col gap-4 p-6');
}

@Directive({
  selector: '[sbCardDivider], [sb-card-divider], sb-card-divider',
  standalone: true,
  exportAs: 'sbCardDivider',
})
export class CardDividerDirective {
  @HostBinding('class')
  hostClass = clsx('block h-px w-full bg-grey-200');
}
