import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ExistingProvider,
  forwardRef,
  Input,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IconComponent } from '../icon';
import { InputModule } from '../input';
import { InputIconModule } from '../input-icon';

export const PASSWORD_INPUT_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordInputComponent),
  multi: true,
};

@Component({
  selector: 'sb-password-input',
  standalone: true,
  imports: [CommonModule, InputModule, InputIconModule, FormsModule, IconComponent],
  templateUrl: './password-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PASSWORD_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class PasswordInputComponent implements ControlValueAccessor {
  @Input()
  public placeholder = '';
  @Input()
  public passwordShown = false;
  @Input()
  public hasError = false;
  @Input()
  public disabled = false;
  @Input()
  public readonly = false;

  public text = '';

  public constructor(private cdr: ChangeDetectorRef) {}

  public togglePasswordIcon() {
    this.passwordShown = !this.passwordShown;
    this.cdr.detectChanges();
  }

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.  Called when the checkbox is blurred.  Needed to properly implement ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => any = () => {};

  // Implemented as part of ControlValueAccessor.
  writeValue(value: any): void {
    if (value === this.text) {
      return;
    }
    this.text = value;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeInput(value: string): void {
    this.text = value;
    this.onChange(this.text);
  }

  onBlurInput(): void {
    Promise.resolve().then(() => {
      this.onTouched();
    });
  }
}
