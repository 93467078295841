import { NgModule } from '@angular/core';

import { SidebarAccordionBodyComponent } from './sidebar-accordion-body.component';
import { SidebarAccordionItemDirective } from './sidebar-accordion-item.directive';
import { SidebarItemComponent } from './sidebar-item.component';
import { SidebarListDirective } from './sidebar-list.directive';
import { SidebarComponent } from './sidebar.component';
import { SidebarTitleDirective } from './sidebar.title.directive';

const standalone = [
  SidebarComponent,
  SidebarItemComponent,
  SidebarAccordionBodyComponent,
  SidebarTitleDirective,
  SidebarAccordionItemDirective,
  SidebarListDirective,
];

@NgModule({
  imports: standalone,
  exports: standalone,
})
export class SidebarModule {}
