import { DialogModule as CdkDialogModule, DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';

import { DialogBlockComponent } from './dialog-block/dialog-block.component';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { LegacyDialogService } from './dialog.service';
import { DialogActionsDirective } from './directives/dialog-actions.directive';
import { DialogCloseDirective } from './directives/dialog-close.directive';
import { DialogSidebarDirective } from './directives/dialog-sidebar.directive';

const standalone = [
  DialogHeaderComponent,
  DialogContentComponent,
  DialogActionsDirective,
  DialogCloseDirective,
  DialogBlockComponent,
  DialogSidebarDirective,
];

const config = new DialogConfig();

@NgModule({
  providers: [
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: { ...config, maxWidth: '90vw', maxHeight: '85vh', hasBackdrop: true },
    },
    LegacyDialogService,
  ],
  declarations: [DialogContainerComponent],
  imports: [...standalone, CdkDialogModule],
  exports: [...standalone],
})
export class LegacyDialogModule {}
