import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';
import { clsx } from 'clsx';

import { SidebarItemComponent } from './sidebar-item.component';

let id = 0;

@Component({
  selector: '[sbSidebarAccordionBody], [sb-sidebar-accordion-body], sb-sidebar-accordion-body',
  exportAs: 'sbSidebarAccordionBody',
  standalone: true,
  animations: [
    trigger('expansion', [
      state('collapsed, void', style({ height: '0px', visibility: 'hidden', overflow: 'hidden' })),
      state('expanded', style({ height: '*', visibility: '', overflow: '' })),
      transition('expanded <=> collapsed, void => collapsed', animate('300ms ease-in-out')),
    ]),
  ],
  template: `<ng-content></ng-content>`,
})
export class SidebarAccordionBodyComponent {
  @Input()
  @HostBinding('id')
  readonly id = `sb-sidebar-accordion-body-${id++}`;

  @ContentChildren(SidebarItemComponent, { descendants: true, read: SidebarItemComponent })
  readonly items!: QueryList<SidebarItemComponent>;

  @HostBinding('attr.aria-labelledby')
  ariaLabelledby!: string;

  @HostBinding('class')
  readonly class = clsx([
    'flex list-none flex-col gap-1',
    'my-0 ml-4',
    'border-0 border-l-2 border-solid border-grey-300',
    'pl-3.5 pr-0',
  ]);

  @HostBinding('@expansion')
  animationState: 'expanded' | 'collapsed' | 'void' = 'void';

  set expanded(value: boolean) {
    if (value === this.expanded) {
      return;
    }
    this._expanded = value;
    this.animationState = this.expanded ? 'expanded' : 'collapsed';
  }

  get expanded(): boolean {
    return this._expanded;
  }

  private _expanded = false;
}
