import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { clsx } from 'clsx';

export interface LegendItem {
  label: string;
  color?: string;
  outline?: boolean;
}

@Component({
  selector: 'sb-legend-item',
  templateUrl: './legend-item.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class LegendItemComponent {
  @Input()
  set item(value: LegendItem) {
    this._item = value;
    this.stylesAndClasses = this.getStylesAndClasses();
  }
  get item(): LegendItem {
    return this._item;
  }
  private _item!: LegendItem;

  public stylesAndClasses = this.getStylesAndClasses();

  private getStylesAndClasses(): {
    styles: { [key: string]: string };
    classes: string;
  } {
    return {
      styles: {
        borderColor: this.item?.color && this.item?.outline ? this.item.color : '',
        backgroundColor: this.item?.color && !this.item?.outline ? this.item.color : '',
      },
      classes: clsx({
        'border-2 border-solid': this.item?.outline,
        'border-grey-300': !this.item?.color && this.item?.outline,
        'bg-grey-300': !this.item?.color && !this.item?.outline,
      }),
    };
  }
}
