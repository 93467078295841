const secondsInUnit = {
  weeks: 604800,
  days: 86400,
  hours: 3600,
  minutes: 60,
  seconds: 1
};
export const decimalUnitToSeconds = (value, unit) => {
  return Math.round(value * secondsInUnit[unit]);
};
export const secondsToDuration = (value, units = Object.keys(secondsInUnit)) => {
  const parts = {};
  let remainder = Math.abs(value);
  units.sort((a, b) => secondsInUnit[b] - secondsInUnit[a]).forEach(unit => {
    const unitAmount = Math.floor(remainder / secondsInUnit[unit]);
    remainder -= unitAmount * secondsInUnit[unit];
    parts[unit] = unitAmount;
  });
  return Object.assign(Object.assign({}, parts), {
    sign: value < 0 ? '-' : '+'
  });
};
/**
 * @deprecated Use `decimalUnitToSeconds` in conjuction with `secondsToDurationParts` instead
 */
export const decimalHoursToDurationParts = (value, withSeconds = false) => {
  let sign = value < 0 ? '-' : '+';
  const positiveValue = Math.abs(value);
  //only interested in the hour part
  // tslint:disable-next-line:no-bitwise
  let hours = positiveValue | 0;
  let minutes = Math.abs(positiveValue - hours) * 60;
  let seconds = 0;
  if (!withSeconds) {
    minutes = Math.round(minutes);
  } else {
    const fullMinutes = Math.floor(minutes);
    seconds = Math.round((minutes - fullMinutes) * 60);
    minutes = fullMinutes;
  }
  //handle rounding to 60 seconds
  if (seconds === 60) {
    minutes += 1;
    seconds = 0;
  }
  //handle rounding to 60 minutes
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }
  if (!hours && !minutes) {
    sign = '';
  }
  return {
    hours,
    minutes,
    seconds,
    sign
  };
};
