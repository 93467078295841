<sb-input-icon>
  <sb-input-icon-prefix>
    <sb-icon [name]="'search'"></sb-icon>
  </sb-input-icon-prefix>

  <input
    #inputElement
    sbInput
    sbAutofocus
    [sbAutofocusDisabled]="!autoFocusInput"
    type="search"
    class="truncate"
    [placeholder]="placeholder || ('Search' | translate)"
    [disabled]="disabled"
    [readonly]="readonly"
    [ngModel]="query"
    (ngModelChange)="onChangeInput($event)"
    [ngModelOptions]="{ standalone: true }"
    (blur)="onBlurInput()"
  />

  <sb-input-icon-suffix
    *ngIf="!hideClearButton && !readonly && !disabled && query"
    [attr.aria-label]="'Clear' | translate"
    tabindex="0"
    role="button"
    (click)="resetInput()"
  >
    <sb-icon [name]="'times'"></sb-icon>
  </sb-input-icon-suffix>
</sb-input-icon>
