import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { CdkDialogContainer, DialogConfig } from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, ElementRef, HostBinding, NgZone, ViewEncapsulation } from '@angular/core';

import { getDialogSize } from '../dialog.helper';

@Component({
  selector: 'sb-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SbDialogContainerComponent extends CdkDialogContainer {
  @HostBinding('class')
  get hostClass() {
    return `sb-dialog-container sb-dialog-container--${getDialogSize(this._config)}`;
  }

  constructor(
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    config: DialogConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor: FocusMonitor,
  ) {
    super(elementRef, focusTrapFactory, document, config, interactivityChecker, ngZone, overlayRef, focusMonitor);
  }

  override attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    // When a component is passed into the dialog, the host element interrupts
    // the `display:flex` from affecting the dialog title, content, and
    // actions. To fix this, we make the component host `display: contents` by
    // marking its host with the `sb-dialog-component-host` class.
    //
    // Note that this problem does not exist when a template ref is used since
    // the title, contents, and actions are then nested directly under the
    // dialog surface.
    const ref = super.attachComponentPortal(portal);
    ref.location.nativeElement.classList.add('sb-dialog-component-host');
    return ref;
  }
}
