<div
  [ngClass]="{
    'flex flex-col flex-auto w-3/5': hasSidebar,
    'p-3': withPadding
  }"
>
  <ng-content></ng-content>
  <div class="-m-3 mt-auto empty:hidden">
    <ng-content select="sb-legacy-dialog-actions, [sb-legacy-dialog-actions], [sbLegacyDialogActions]"></ng-content>
  </div>
</div>
<div *ngIf="hasSidebar" class="max-h-inherit w-2/5 flex-auto">
  <ng-content select="sb-legacy-dialog-sidebar"></ng-content>
</div>
