<div class="flex flex-col gap-4">
  <div class="mb-2 flex items-center gap-3 last:mb-0">
    <sb-icon
      [name]="titleProps[mainStatus].icon"
      size="xl"
      class="shrink-0"
      [ngClass]="titleProps[mainStatus].iconClass"
    ></sb-icon>
    <h4 class="m-0 text-lg font-bold text-word-mark-800">
      {{ list.titleLabels[mainStatus] | translate }}
    </h4>
  </div>
  @for (item of list.items; track $index) {
    <div class="flex gap-3">
      <sb-icon [name]="statusIconMap[item.status]" size="xl" class="shrink-0 text-word-mark-800"></sb-icon>
      <p class="m-0 text-grey-600">
        {{ item.label }}
      </p>
    </div>
  }
</div>
