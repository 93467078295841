import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, ElementRef, HostBinding, NgZone } from '@angular/core';
import { clsx } from 'clsx';

@Component({
  selector: 'sb-sidebar-drawer-content',
  exportAs: 'sbSidebarDrawerContent',
  standalone: true,
  template: `<ng-content></ng-content>`,
  providers: [
    {
      provide: CdkScrollable,
      useExisting: SidebarDrawerContentComponent,
    },
  ],
})
export class SidebarDrawerContentComponent extends CdkScrollable {
  @HostBinding('class')
  hostClass = clsx('min-w-0 grow overflow-y-auto overflow-x-hidden');

  constructor(elementRef: ElementRef<HTMLElement>, scrollDispatcher: ScrollDispatcher, ngZone: NgZone) {
    super(elementRef, scrollDispatcher, ngZone);
  }
}
