import { sbIcons } from '@sb/svg-icons';

export const legacyMapping: { [key: string]: sbIcons } = {
  absence: 'plane',
  absentee_maternity_leave: 'baby-carriage',
  absentee_other: 'calendar-slash',
  'absentee-national_day': 'flag',
  'absentee-sick': 'medkit',
  absentee_sick: 'medkit',
  'absentee-unavailable': 'calendar-slash',
  'absentee-vacation': 'plane',
  'leave-1': 'plane',
  'leave-2': 'medkit',
  'leave-4': 'windsock',
  'leave-5': 'calendar-slash',
  'leave-6': 'baby-carriage',
  bullhorn: 'megaphone',
  calendar_approved: 'calendar-check',
  calendar_denied: 'calendar-times',
  car: 'car-sideview',
  change_shift: 'repeat',
  cutlery: 'utensils',
  delete: 'trash',
  info: 'info-circle',
  payment: 'credit-card',
  reclock: 'history',
  team: 'users',
  tour: 'sign-alt',
  zoom: 'search',
  overtime: 'future',
  ratecard: 'percentage-square',
};
