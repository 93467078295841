import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  InjectionToken,
  Input,
  Provider,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { clsx } from 'clsx';

import { RadioBadgeComponent } from '../radio-badge/radio-badge.component';

let nextUniqueId = 0;

const CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioBadgeGroupComponent),
  multi: true,
};

export const SB_RADIO_GROUP = new InjectionToken<RadioBadgeGroupComponent>('SbRadioGroup');

@Component({
  selector: 'sb-radio-badge-group',
  standalone: true,
  imports: [CommonModule],
  exportAs: 'sbRadioBadgeGroup',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CONTROL_VALUE_ACCESSOR, { provide: SB_RADIO_GROUP, useExisting: RadioBadgeGroupComponent }],
})
export class RadioBadgeGroupComponent implements ControlValueAccessor, AfterViewInit {
  private _uniqueId = `sb-radio-${++nextUniqueId}`;

  @ContentChildren(forwardRef(() => RadioBadgeComponent), { descendants: true })
  radios!: QueryList<RadioBadgeComponent>;

  @HostBinding('class')
  baseClass = clsx('flex flex-wrap gap-2');

  @HostBinding('attr.role')
  get role() {
    return this.multiple ? 'group' : 'radiogroup';
  }

  @Input()
  multiple = false;

  @Input()
  disabled = false;

  @Input()
  set name(value: string) {
    this._name = value;
  }
  get name(): string {
    return this._name || this._uniqueId;
  }
  private _name = '';

  @Input()
  set value(value: string | string[]) {
    if (this.multiple && !Array.isArray(value)) {
      this._value = value ? [value] : [];
    } else {
      this._value = value || '';
    }
    this.selected = Array.isArray(this._value) ? this._value : [this._value];
    this.updateRadioCheckedBasedOnValue();
  }
  get value(): string | string[] {
    return this._value;
  }
  _value: string | string[] = '';

  selected: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onModelChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onModelTouched: any = () => {};

  writeValue(value: string | string[]): void {
    this.value = value;
  }

  onValueChange(value: string): void {
    this.value = value;
    this.onControlChange();
  }

  onControlChange() {
    this.onModelTouched();
    this.onModelChange(this.value);
  }

  registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectedRemoveValue(value: string) {
    this.selected = this.selected.filter((item) => item !== value);
    this.onSelectedChange();
  }

  selectedAddValue(value: string) {
    this.selected = [...new Set([...this.selected, value])];
    this.onSelectedChange();
  }

  ngAfterViewInit() {
    this.updateRadioCheckedBasedOnValue();
  }

  private onSelectedChange() {
    if (!this.multiple) {
      return;
    }
    this.value = this.selected;
    this.onControlChange();
  }

  private updateRadioCheckedBasedOnValue() {
    this.radios?.forEach((radio) => {
      radio.checked = this.multiple ? this.value?.includes(radio.value) : this.value === radio.value;
    });
  }
}
