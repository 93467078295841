// TODO for some reason es2022 doesn't expose DateTimeFormatOptions anymore.
// So we have to redefine those types here
import { startOfWeek, startOfMonth } from 'date-fns';
export function getDayNames(locale = 'en', format = 'short') {
  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: format
  });
  const weekdays = [];
  for (let i = 0; i < 7; i++) {
    const date = startOfWeek(new Date(), {
      weekStartsOn: 1
    });
    date.setDate(date.getDate() + i);
    weekdays.push(formatter.format(date));
  }
  return weekdays;
}
export function getMonthNames(locale = 'en', format = 'long') {
  const formatter = new Intl.DateTimeFormat(locale, {
    month: format
  });
  const months = [];
  for (let i = 0; i < 12; i++) {
    const date = startOfMonth(new Date());
    date.setMonth(i);
    months.push(formatter.format(date));
  }
  return months;
}
