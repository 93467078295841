import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
// disable this rule only for now until legacy icons are no longer needed
// eslint-disable-next-line @nx/enforce-module-boundaries
import { IconComponent as LegacyIconComponent } from '@app/+authenticated/shared/icon.component';
import { sbIcons } from '@sb/svg-icons';

import { IconComponent, IconRegistry } from '../icon';

export type IconName = sbIcons | string;

export enum BadgeSize {
  'sm' = 'sm',
  'base' = 'base',
  'lg' = 'lg',
  'xl' = 'xl',
  'xxl' = 'xxl',
}
export type badgeSize = keyof typeof BadgeSize | undefined;

@Component({
  selector: 'sb-badge-icon',
  standalone: true,
  imports: [CommonModule, IconComponent, LegacyIconComponent],
  template: `
    <sb-icon *ngIf="!isLegacyIcon" [class]="iconClasses" [name]="name"></sb-icon>
    <icon *ngIf="isLegacyIcon" [class]="iconClasses" [svgIcon]="name"></icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeIconComponent {
  private readonly sizeClasses = {
    [BadgeSize.sm]: {
      badge: 'h-4 w-4 rounded-sm', // 16px
      icon: 'h-2 w-2',
    },
    [BadgeSize.base]: {
      badge: 'h-5 w-5 rounded', // 20px
      icon: 'h-2.5 w-2.5',
    },
    [BadgeSize.lg]: {
      badge: 'h-6 w-6 rounded-lg', // 24px
      icon: 'h-3.5 w-3.5',
    },
    [BadgeSize.xl]: {
      badge: 'h-7 w-7 rounded-lg', // 28px
      icon: 'h-4 w-4',
    },
    [BadgeSize.xxl]: {
      badge: 'h-8 w-8 rounded-lg', // 32px
      icon: 'h-5 w-5',
    },
  };

  isLegacyIcon = false;

  constructor(private iconRegistry: IconRegistry) {}

  @Input()
  set name(value: IconName) {
    if (value === this.name) {
      return;
    }
    this._name = value;

    if (!this.iconRegistry.hasIcon(value as sbIcons)) {
      this.isLegacyIcon = true;
    }
  }
  get name(): IconName {
    return this._name;
  }
  private _name!: IconName;

  @Input()
  set size(value: badgeSize) {
    if (value === this.size) {
      return;
    }
    this._size = value;
    this.classes = this.getHostClasses();
    this.iconClasses = this.getIconClasses();
  }
  get size(): badgeSize {
    return this._size;
  }
  private _size?: badgeSize = BadgeSize.base;

  @HostBinding('class')
  classes = this.getHostClasses();

  iconClasses = this.getIconClasses();

  private getHostClasses(): string {
    return `flex justify-center items-center ${this.size ? this.sizeClasses[this.size].badge : ''}`;
  }

  // TODO once we are fully switched over to an icon set that only uses square icons (i.e. the
  // icons' dimensions are the smallest circumbscribed square around the vector) we can get
  // rid of this and simply use `w-fit h-fit` as the icon's classes
  private getIconClasses(): string {
    return `${this.size ? this.sizeClasses[this.size].icon : ''}`;
  }
}
