<button
  *ngIf="!customToggle"
  [sbDropdownToggle]="menu"
  (sbDropdownToggleOpened)="onOpened()"
  sbInput
  [disabled]="disabled"
  [hasError]="hasError"
  class="w-full"
  type="button"
  [ngClass]="{
    'flex items-center gap-1': showPill && multiSelect && selectedCount,
    'border-primary-500 ': (showPill && multiSelect && selectedCount) || buttonActive
  }"
>
  <span class="block h-5 truncate">{{ selectButtonLabel }}</span>
  <span
    *ngIf="showPill && multiSelect && selectedCount"
    class="flex h-3.5 w-3.5 items-center justify-center rounded-full bg-primary-500 text-xs leading-none text-white"
    >{{ selectedCount }}</span
  >
</button>

<ng-content *ngIf="customToggle"></ng-content>

<ng-template #onlyButton let-item="item">
  <button
    class="invisible m-0 cursor-pointer border-0 bg-transparent font-semibold text-primary-500 outline-none hover:underline group-hover:visible"
    (click)="onSelectOnly(item)"
  >
    {{ 'Only' | translate }}
  </button>
</ng-template>

<ng-template #menu>
  <div
    sbDropdownMenu
    class="min-w-44"
    [ngStyle]="{ width: customToggle || !matchWidth ? undefined : dropdownWidth }"
    [ngClass]="{ 'max-w-sm': customToggle }"
  >
    <div *ngIf="searchable" class="mx-4 mb-2.5 mt-1">
      <sb-search-input
        [placeholder]="searchPlaceholder || ('Search' | translate)"
        [autoFocusInput]="true"
        [(ngModel)]="query"
        (ngModelChange)="onSearch()"
        [ngModelOptions]="{ standalone: true }"
      ></sb-search-input>
    </div>

    <ng-container *ngIf="multiSelect && showSelectAll">
      <div sbDropdownItemCheckbox>
        <sb-checkbox (change)="onSelectAll($event.checked)" [checked]="allChecked">
          {{ 'Select all' | translate }}
        </sb-checkbox>
      </div>
      <sb-dropdown-divider></sb-dropdown-divider>
    </ng-container>

    <div class="max-h-72 overflow-y-auto overflow-x-hidden">
      <div *ngIf="!displayedItems?.length" sbDropdownBaseItem [hasHoverEffect]="false">
        <span>{{ 'No items found' | translate }}</span>
      </div>

      <ng-container *ngIf="displayTree; else simpleViewTemplate">
        <sb-tree [items]="displayedItems" [collapsable]="collapsable">
          <ng-template #leafTemplate let-item="item" let-level="level">
            <div
              *ngIf="multiSelect"
              sbTreeNode
              [level]="level"
              sbDropdownItemCheckbox
              [ngClass]="{ group: showOnly && !item.disabled }"
            >
              <sb-select-collapse-spacer *ngIf="collapsable"></sb-select-collapse-spacer>
              <sb-checkbox
                [checked]="item.checked"
                [disabled]="item.disabled"
                (change)="onCheckboxChange($event.checked, item)"
              >
                {{ item.text }}
              </sb-checkbox>
              @if (showOnly && !item.disabled) {
                <ng-container *ngTemplateOutlet="onlyButton; context: { item }"></ng-container>
              }
            </div>
            <button
              *ngIf="!multiSelect"
              sbTreeNode
              [level]="level"
              sbDropdownItem
              [selected]="item.value === selected"
              (sbDropdownItemTriggered)="onItemTrigger(item)"
              [sbDropdownItemDisabled]="item.disabled"
            >
              <sb-select-collapse-spacer *ngIf="collapsable"></sb-select-collapse-spacer>
              <span class="truncate">
                {{ item.text }}
              </span>
            </button>
          </ng-template>
          <ng-template #nodeTemplate let-item="item" let-level="level">
            <div
              sbDropdownBaseItem
              tabindex="0"
              class="cursor-default"
              sbTreeNode
              [level]="level"
              [ngClass]="{ group: showOnly && !item.disabled }"
            >
              <div
                *ngIf="collapsable"
                role="button"
                [attr.aria-label]="'Toggle' | translate"
                (click)="onToggleCollapse(item)"
                class="mr-2.5 flex cursor-pointer items-center justify-center"
              >
                <sb-icon [name]="'chevron-down'" [ngClass]="{ '-rotate-90': item.collapsed }"></sb-icon>
              </div>
              <sb-checkbox
                *ngIf="multiSelect"
                class="grow overflow-hidden"
                [checked]="item.checked"
                [indeterminate]="item.indeterminate"
                [disabled]="item.disabled"
                (change)="onCheckboxChange($event.checked, item)"
              >
                {{ item.text }}
              </sb-checkbox>
              @if (multiSelect && showOnly && !item.disabled) {
                <ng-container *ngTemplateOutlet="onlyButton; context: { item }"></ng-container>
              }
              <span
                *ngIf="!multiSelect"
                class="truncate"
                [ngClass]="{
                  'font-extrabold': level === 0,
                  'font-semibold': level > 0
                }"
              >
                {{ item.text }}
              </span>
            </div>
          </ng-template>
        </sb-tree>
      </ng-container>
      <ng-template #simpleViewTemplate>
        <ng-container *ngFor="let item of displayedItems; trackBy: trackByFn">
          <div *ngIf="multiSelect" sbDropdownItemCheckbox [ngClass]="{ group: showOnly && !item.disabled }">
            <sb-select-collapse-spacer *ngIf="collapsable"></sb-select-collapse-spacer>
            <sb-checkbox
              [checked]="item.checked"
              [disabled]="item.disabled"
              (change)="onCheckboxChange($event.checked, item)"
            >
              {{ item.text }}
            </sb-checkbox>
            @if (showOnly && !item.disabled) {
              <ng-container *ngTemplateOutlet="onlyButton; context: { item }"></ng-container>
            }
          </div>

          <button
            *ngIf="!multiSelect"
            sbDropdownItem
            [selected]="item.value === selected"
            (sbDropdownItemTriggered)="onItemTrigger(item)"
            [sbDropdownItemDisabled]="item.disabled"
          >
            <sb-select-collapse-spacer
              *ngIf="collapsable"
              class="mr-2.5 inline-block w-3.5 shrink-0"
            ></sb-select-collapse-spacer>
            <span class="truncate">
              {{ item.text }}
            </span>
          </button>
        </ng-container>
      </ng-template>
    </div>
  </div>
</ng-template>
