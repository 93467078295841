<div class="flex items-center justify-between border-0 border-b border-solid border-grey-100 pb-4">
  @if (alwaysShowNavigation || month.isFirstCalendar) {
    <button sb-icon-button [color]="'grey'" [disabled]="month.containsMinDate" (click)="navigate.emit('previous')">
      <sb-icon [name]="'chevron-left'"></sb-icon>
    </button>
  } @else {
    <div class="h-8.5 w-8.5"></div>
  }
  <div class="flex h-8.5 items-center gap-4 font-semibold">
    <span>{{ month.name }}</span>
    <span>{{ month.monthYear }}</span>
  </div>
  @if (alwaysShowNavigation || month.isLastCalendar) {
    <button sb-icon-button [color]="'grey'" [disabled]="month.containsMaxDate" (click)="navigate.emit('next')">
      <sb-icon [name]="'chevron-right'"></sb-icon>
    </button>
  } @else {
    <div class="h-8.5 w-8.5"></div>
  }
</div>
