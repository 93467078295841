import { Directive, HostBinding } from '@angular/core';
import { clsx } from 'clsx';

@Directive({
  selector: '[sbSidebarTitle], [sb-sidebar-title], sb-sidebar-title',
  exportAs: 'sbSidebarTitle',
  standalone: true,
})
export class SidebarTitleDirective {
  @HostBinding('class')
  hostClass = clsx('m-0 mb-2 block px-2.5 py-1 text-base font-bold text-word-mark-800');
}
