<div
  class="overflow-hidden max-w-inherit relative {{ sizeClasses }}"
  [ngClass]="{
    'sb-progress-spinner-indeterminate': mode === 'indeterminate',
    'sb-progress-spinner-determinate': mode === 'determinate'
  }"
>
  <svg viewBox="0 0 100 100" class="sb-progress-spinner-svg h-full w-full absolute inset-0 m-auto pointer-events-none">
    <circle
      cx="50"
      cy="50"
      [attr.r]="radius"
      class="sb-progress-spinner-circle"
      [ngClass]="{
        'stroke-grey-400': strokeColor === 'grey',
        'stroke-primary-400': strokeColor === 'primary',
        'stroke-inherit': strokeColor === 'inherit',
        'stroke-current': strokeColor === 'current'
      }"
      fill="none"
      [style.stroke-dasharray.px]="mode === 'determinate' ? strokeCircumference : undefined"
      [style.stroke-dashoffset.px]="mode === 'determinate' ? strokeDashOffset : undefined"
    ></circle>
  </svg>
</div>
