import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { clsx } from 'clsx';

export enum BadgeThemePalette {
  grey = 'grey',
  primary = 'primary',
  yellow = 'yellow',
  red = 'red',
  pink = 'pink',
  purple = 'purple',
  indigo = 'indigo',
  blue = 'blue',
  cyan = 'cyan',
  green = 'green',
  greenLight = 'greenLight',
}
export type badgeThemePalette = keyof typeof BadgeThemePalette;

export enum BadgeSizes {
  medium = 'medium',
  large = 'large',
}
export type badgeSizes = keyof typeof BadgeSizes;

@Component({
  selector: 'sb-badge',
  standalone: true,
  imports: [],
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  private readonly baseClasses = clsx('inline-flex items-center justify-center truncate rounded text-sm font-bold');

  private readonly colorClasses = {
    [BadgeThemePalette.grey]: clsx('bg-grey-200 text-grey-600'),
    [BadgeThemePalette.primary]: clsx('bg-primary-500 text-white'),
    [BadgeThemePalette.yellow]: clsx('bg-warning-100 text-warning-500'),
    [BadgeThemePalette.red]: clsx('bg-error-100 text-error-600'),
    [BadgeThemePalette.pink]: clsx('bg-pink-100 text-pink-700'),
    [BadgeThemePalette.purple]: clsx('bg-purple-100 text-purple-600'),
    [BadgeThemePalette.indigo]: clsx('bg-indigo-100 text-indigo-700'),
    [BadgeThemePalette.blue]: clsx('bg-primary-100 text-primary-700'),
    [BadgeThemePalette.cyan]: clsx('bg-cyan-100 text-cyan-700'),
    [BadgeThemePalette.green]: clsx('bg-green-100 text-green-900'),
    [BadgeThemePalette.greenLight]: clsx('bg-green-light-100 text-green-light-700'),
  };

  private readonly sizeClasses = {
    [BadgeSizes.large]: clsx('h-8 px-1.5 py-2'),
    [BadgeSizes.medium]: clsx('h-5 px-1.5 py-1 leading-none'),
  };

  @Input()
  set color(value: badgeThemePalette | null) {
    if (this._color !== value) {
      this._color = value;
      this.hostClasses = this.getHostClasses();
    }
  }
  get color() {
    return this._color;
  }
  private _color: badgeThemePalette | null = null;

  @Input()
  set size(value: badgeSizes) {
    if (this._size !== value) {
      this._size = value;
      this.hostClasses = this.getHostClasses();
    }
  }
  get size() {
    return this._size;
  }
  private _size: badgeSizes = BadgeSizes.medium;

  @HostBinding('class')
  hostClasses = this.getHostClasses();

  private getHostClasses() {
    return `${this.baseClasses} ${this.getSizeClasses()} ${this.getColorClasses()}`;
  }

  private getSizeClasses() {
    if (!this.size) return '';
    return this.sizeClasses[this.size];
  }

  private getColorClasses() {
    if (!this.color) return '';
    return this.colorClasses[this.color];
  }
}
