import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '../icon';

export interface InputPasswordRequirement {
  label: string;
  confirmationType: string;
}

@Component({
  selector: 'sb-password-input-requirements',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './password-input-requirements.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputRequirementsComponent {
  @Input()
  requirements!: InputPasswordRequirement[];
  @Input()
  hasError = false;

  trackByRequirement(_: number, item: InputPasswordRequirement): string {
    return item.label;
  }
}
