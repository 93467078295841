import { NgModule } from '@angular/core';

import {
  CardComponent,
  CardContentComponent,
  CardDividerDirective,
  CardHeaderComponent,
  CardTitleDirective,
} from './card';

const standalone = [CardComponent, CardHeaderComponent, CardTitleDirective, CardContentComponent, CardDividerDirective];

@NgModule({
  imports: standalone,
  exports: standalone,
})
export class CardModule {}
