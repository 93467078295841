import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'sb-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @HostBinding('class.sb-tooltip')
  hostClass = true;

  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input()
  public tooltipText?: string;

  @Input()
  public tooltipTemplate?: TemplateRef<unknown>;

  @Input()
  public maxWidth = '14rem';

  @HostBinding('style.maxWidth') get getMaxWidth() {
    return this.maxWidth;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  markForCheck() {
    this.cdr.markForCheck();
  }
}
