import { CommonModule } from '@angular/common';
import { Component, ElementRef, ExistingProvider, forwardRef, inject, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { hasFlag } from 'country-flag-icons';
import * as flags from 'country-flag-icons/string/3x2';
import { PhoneNumberFormat, PhoneNumberUtil, RegionCode } from 'google-libphonenumber';

import { ButtonComponent } from '../button';
import { DropdownModule, DropdownToggleDirective } from '../dropdown';
import { FormGroupModule } from '../form-group';
import { InputDirective } from '../input';
import { SearchInputComponent } from '../search-input';
import { countries, priorityRecord } from './countries.data';
import { Country } from './country.model';
import { PhoneInputValidator } from './phone-input.validator';

export const PHONE_INPUT_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneInputComponent),
  multi: true,
};

const getFlagSvg = (regionCode: string) => {
  if (!hasFlag(regionCode.toUpperCase())) {
    return '';
  }
  const el = document.createElement('div');
  el.innerHTML = (flags as { [key: string]: string })[regionCode.toUpperCase()];

  const flag = el.firstChild as HTMLElement;
  flag.style.width = '100%';
  flag.style.height = '100%';

  return flag.outerHTML;
};

const phoneUtil = PhoneNumberUtil.getInstance();

enum PhoneInternationalPrefix {
  PLUS = '+',
  DOUBLE_ZERO = '00',
}

@Component({
  selector: 'sb-phone-input',
  standalone: true,
  imports: [
    CommonModule,
    FormGroupModule,
    InputDirective,
    DropdownModule,
    SearchInputComponent,
    FormsModule,
    ButtonComponent,
    TranslateModule,
  ],
  templateUrl: './phone-input.component.html',
  providers: [
    PHONE_INPUT_CONTROL_VALUE_ACCESSOR,
    {
      provide: NG_VALIDATORS,
      useClass: PhoneInputValidator,
      multi: true,
    },
  ],
})
export class PhoneInputComponent implements ControlValueAccessor {
  private readonly _countries: Country[] = countries.map((country) => {
    return {
      ...country,
      phoneCode: phoneUtil.getCountryCodeForRegion(country.regionCode),
      flag: this.sanitizer.bypassSecurityTrustHtml(getFlagSvg(country.regionCode)),
    };
  });

  private readonly internationalPrefixes = Object.values(PhoneInternationalPrefix);

  private readonly _elementRef: ElementRef = inject(ElementRef);

  @Input()
  set fallbackRegionCode(regionCode: string | undefined) {
    this._fallbackRegionCode = regionCode;
    this.updateSelectedCountry();
  }
  get fallbackRegionCode(): string | undefined {
    return this._fallbackRegionCode;
  }
  private _fallbackRegionCode: string | undefined;

  @Input()
  inputId = '';

  @Input()
  disabled = false;

  @ViewChild(DropdownToggleDirective, { static: true, read: ElementRef })
  dropdown?: ElementRef<HTMLButtonElement>;

  @ViewChild('inputElement', { static: true, read: ElementRef })
  inputElement?: ElementRef;

  filteredCountries: Country[] = this.searchedCountries;

  selectedRegionCode: RegionCode | undefined;

  selectedCountry?: Country;

  dropdownWidth?: string;

  phoneNumber = '';

  placeholder = '';

  searchQuery = '';

  private value = '';

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onChange: (value: any) => void = () => {};
  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  public onTouched: () => any = () => {};

  constructor(private sanitizer: DomSanitizer) {}

  // Implemented as part of ControlValueAccessor.
  public writeValue(value: string): void {
    this.value = value || '';
    this.verifyAndUpdatePhoneNumber({ value: this.value, triggerOnChange: false });
  }

  private determineRegionCodeFromPhoneNumber(number: string): RegionCode | undefined {
    const foundCountry = this._countries.find((country) => number.startsWith(`+${country.phoneCode}`));

    if (!foundCountry) {
      return undefined;
    }

    const phoneCode = `${foundCountry.phoneCode}`;
    const regionCode = priorityRecord[phoneCode] || foundCountry.regionCode;
    return regionCode;
  }

  private setSelectedRegionCode(regionCode: RegionCode | undefined): void {
    if (regionCode === this.selectedRegionCode) {
      return;
    }
    this.selectedRegionCode = regionCode;
    this.updateSelectedCountry();
  }

  private get trimmedPhoneNumber(): string {
    return (this.phoneNumber || '').trim();
  }

  private formatInputValue(value: string | undefined): string {
    const number = value?.trim() || '';
    if (number.startsWith(PhoneInternationalPrefix.DOUBLE_ZERO)) {
      return number.replace(PhoneInternationalPrefix.DOUBLE_ZERO, PhoneInternationalPrefix.PLUS);
    }
    return number;
  }

  private verifyAndUpdatePhoneNumber({
    value,
    triggerOnChange,
  }: {
    value: string | undefined;
    triggerOnChange: boolean;
  }): void {
    const formattedValue = this.formatInputValue(value);
    const startsWithPlus = formattedValue.startsWith(PhoneInternationalPrefix.PLUS);

    try {
      const number = phoneUtil.parse(formattedValue, startsWithPlus ? undefined : this.regionCode);
      const fullPhoneNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
      let regionCode = phoneUtil.getRegionCodeForNumber(number);
      const countryCode = number.getCountryCode();
      const nationalNumber = `${number.getNationalNumber()}`;

      // find by priority by checking priorityRecord
      if (countryCode && !regionCode) {
        regionCode = priorityRecord[`${countryCode}`];
      }

      this.setSelectedRegionCode(regionCode);

      this.phoneNumber = nationalNumber;

      if (triggerOnChange) {
        this.onChange(fullPhoneNumber);
      }
    } catch (error) {
      // if the number starts with + and we can't parse it, we try to determine the region code
      if (startsWithPlus) {
        const regionCode = this.determineRegionCodeFromPhoneNumber(formattedValue);
        if (regionCode) {
          this.setSelectedRegionCode(regionCode);
          this.phoneNumber = formattedValue.substring(`+${this.phoneCode}`.length);

          if (triggerOnChange) {
            this.onChange(formattedValue);
          }
          return;
        }
      }

      this.phoneNumber = formattedValue;
      if (triggerOnChange) {
        this.onChange(this.phoneCode ? `+${this.phoneCode}${formattedValue}` : formattedValue);
      }
    }
  }

  public onPaste(event: ClipboardEvent): void {
    // prevent the default paste action
    event.preventDefault();

    const { clipboardData } = event;
    const pastedNumber = clipboardData?.getData('text')?.trim() || '';

    // remove all characters that are not numbers or allowed characters
    const value = pastedNumber.replace(/[^0-9+\-() ]/gm, '');
    this.verifyAndUpdatePhoneNumber({ value, triggerOnChange: true });
  }

  public onPhoneNumberChange(): void {
    this.verifyAndUpdatePhoneNumber({ value: this.trimmedPhoneNumber, triggerOnChange: true });
  }

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onSearch(): void {
    this.filteredCountries = this.searchedCountries;
  }

  public onSelect(country: Country): void {
    this.selectedRegionCode = country.regionCode;
    this.updateSelectedCountry();
    this.onPhoneNumberChange();

    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    });
  }

  public onInputKeyPress(event: KeyboardEvent): void {
    const allowedChars = /[0-9+\-() ]/;
    const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
    const allowedOtherKeys = [
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End',
      'Insert',
      'Delete',
      'Backspace',
    ];

    if (
      !allowedChars.test(event.key) &&
      !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
      !allowedOtherKeys.includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  public onOpened(): void {
    this.dropdownWidth = `${this._elementRef?.nativeElement.offsetWidth}px`;
  }

  private get regionCode(): string | undefined {
    return this.selectedRegionCode || this.fallbackRegionCode;
  }

  private get phoneCode(): number | undefined {
    return this.selectedCountry?.phoneCode;
  }

  private updateSelectedCountry(): void {
    this.selectedCountry = this.getSelectedCountry(this.regionCode);
    this.placeholder = this.getPhoneNumberPlaceholder();
  }

  private getSelectedCountry(regionCode: string | undefined): Country | undefined {
    if (!regionCode) {
      return undefined;
    }
    return this._countries.find((country) => {
      return country.regionCode.toUpperCase() === regionCode.toUpperCase();
    });
  }

  private get searchedCountries(): Country[] {
    if (!this.searchQuery) {
      return this._countries;
    }

    const query = this.formatSearchQuery(this.searchQuery);

    return this._countries.filter((country) => {
      if (country.regionCode.toLowerCase().includes(query)) {
        return country;
      }
      if (country.name.toLowerCase().includes(query)) {
        return country;
      }
      if (`${country.phoneCode}`.includes(query)) {
        return country;
      }
      return false;
    });
  }

  private formatSearchQuery(value: string): string {
    const formattedValue = value.trim().toLowerCase();
    const foundPrefix = this.internationalPrefixes.find((p) => formattedValue.startsWith(p));
    if (!foundPrefix) {
      return formattedValue;
    }
    return formattedValue.substring(foundPrefix.length).trim();
  }

  private getPhoneNumberPlaceholder(): string {
    if (!this.regionCode) {
      return '';
    }
    try {
      let phoneNumber = phoneUtil.format(phoneUtil.getExampleNumber(this.regionCode), PhoneNumberFormat.INTERNATIONAL);
      if (phoneNumber.startsWith(PhoneInternationalPrefix.PLUS)) {
        phoneNumber = phoneNumber.substring(phoneNumber.indexOf(' ') + 1);
      }
      return phoneNumber;
    } catch (error) {
      return '';
    }
  }
}
