import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[sbDialogTitle], [sb-dialog-title]',
  exportAs: 'sbDialogTitle',
})
export class SbDialogTitleDirective {
  @HostBinding('class.sb-dialog-title')
  readonly hostclass = true;
}
