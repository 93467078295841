import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
})
export class FormGroupAddonDirective {
  @HostBinding('class')
  readonly hostClasses: string;

  constructor(protected elementRef: ElementRef) {
    const nodeName = elementRef.nativeElement.nodeName.toLowerCase();

    if (nodeName === 'button') {
      this.hostClasses = '';
      return;
    }
    this.hostClasses =
      'flex items-center px-2.5 text-base text-center justify-center border-solid border border-grey-200 bg-grey-200 text-grey-600 whitespace-nowrap rounded-base';
  }
}
