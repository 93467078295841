import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { IconComponent } from '../icon';
import { CheckboxBaseComponent, CheckboxChangeEvent } from './checkbox-base.component';

export const SB_CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};

let nextUniqueId = 0;

@Component({
  selector: 'sb-checkbox',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './checkbox.component.html',
  providers: [SB_CHECKBOX_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends CheckboxBaseComponent {
  protected override _uniqueId;

  @Input()
  truncateLabel = true;

  @Input()
  id: string;

  @Input()
  get indeterminate(): boolean {
    return this._indeterminate;
  }
  set indeterminate(value: BooleanInput) {
    const changed = value != this._indeterminate;
    this._indeterminate = coerceBooleanProperty(value);
    if (changed) {
      this.indeterminateChange.emit(this._indeterminate);
    }
    this.syncIndeterminate(this._indeterminate);
  }
  private _indeterminate = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change: EventEmitter<CheckboxChangeEvent<CheckboxComponent>> = new EventEmitter<
    CheckboxChangeEvent<CheckboxComponent>
  >();
  // for this case native output is expected

  @Output()
  readonly indeterminateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('input')
  inputElement!: ElementRef<HTMLInputElement>;

  @ViewChild('label')
  labelElement!: ElementRef<HTMLInputElement>;

  constructor(protected override cd: ChangeDetectorRef) {
    super(cd);
    this.id = this._uniqueId = `sb-checkbox-${++nextUniqueId}`;
  }

  override onInputClick(): void {
    if (this.disabled) {
      return;
    }

    super.onInputClick();

    // When user clicks checkbox, `indeterminate` is set to false.
    if (this.indeterminate) {
      this.indeterminate = false;
    }
  }

  private syncIndeterminate(value: boolean): void {
    const nativeCheckbox = this.inputElement;

    if (nativeCheckbox) {
      nativeCheckbox.nativeElement.indeterminate = value;
    }
  }
}
