import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  FormGroupDirective,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { concat, interval, merge, of, Subject, Subscription } from 'rxjs';
import { filter, map, scan, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';

enum LaddaProgress {
  START = 'start',
  STOP = 'stop',
}

@Directive({
  selector: 'button[sbLadda]',
  standalone: true,
})
export class LaddaDirective implements OnInit, OnDestroy, AfterViewInit {
  private readonly baseHostClasses = 'relative overflow-hidden';
  private loadingState$: Subject<boolean> = new Subject();

  private dataSubs = new Subscription();

  @HostBinding('class')
  protected hostClasses = this.getHostClasses();

  @HostBinding('style.--ladda-progress')
  protected hostProgress = this.getHostProgress();

  private progress?: LaddaProgress | number;

  @Input('sbLadda')
  @HostBinding('class.pointer-events-none')
  public set isLoading(isLoading: boolean) {
    this.loadingState$.next(isLoading);
    this._isLoading = isLoading;
  }
  public get isLoading() {
    return this._isLoading;
  }
  private _isLoading = false;

  public constructor(
    private elementRef: ElementRef<HTMLButtonElement>,
    private cdRef: ChangeDetectorRef,
    @Optional() private form: FormGroupDirective,
  ) {}

  public ngOnInit(): void {
    // button must wrap text in a span to be able to animate label
    // this is done to match current ladda as much as possible
    if (!this.elementRef.nativeElement.querySelector('span')) {
      const laddaLabel = document.createElement('span');
      this.wrapContent(this.elementRef.nativeElement, laddaLabel);
    }

    this.startListening();
  }

  public ngAfterViewInit(): void {
    // button must have the type submit to be able to update form validation
    if (this.form && this.elementRef.nativeElement.type === 'submit') {
      this.dataSubs.add(
        this.form.ngSubmit.subscribe(() => {
          this.markFormAsTouchedAndDirty(this.form.form);
        }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  private startListening() {
    this.dataSubs.add(this.getButtonProgressSubscription());
  }

  private getButtonProgressSubscription(): Subscription {
    const startLoading = this.loadingState$.pipe(filter((isLoading) => isLoading));

    const finishLoading = this.loadingState$.pipe(filter((isLoading) => !isLoading));

    const progress = concat(
      of(LaddaProgress.START),
      interval(250).pipe(
        scan((current_progress) => current_progress + this.incrementTimer(current_progress), 0),
        takeWhile((progress) => progress < 90),
        takeUntil(finishLoading),
      ),
    );

    return merge(
      startLoading.pipe(switchMap(() => progress)),
      finishLoading.pipe(
        switchMap(() =>
          concat(
            of(99),
            interval(150).pipe(
              map(() => LaddaProgress.STOP),
              take(1),
            ),
          ),
        ),
      ),
    ).subscribe((progress) => {
      this.progress = progress;
      this.hostProgress = this.getHostProgress();
      this.hostClasses = this.getHostClasses();
      this.cdRef.markForCheck();
    });
  }

  private getHostProgress() {
    if (typeof this.progress === 'number') {
      return `${this.progress}%`;
    }
    return '0%';
  }

  private getHostClasses() {
    if (this.progress && this.progress !== LaddaProgress.STOP) {
      return `${this.baseHostClasses} [&>span]:transition [&>span]:duration-300 [&>span]:opacity-0 [&>span]:inline-block [&>span]:scale-150 after:w-[var(--ladda-progress)] after:absolute after:transition-all after:duration-300 after:content-[''] after:top-0 after:left-0 after:h-full after:bg-black/20`;
    }
    return `${this.baseHostClasses}`;
  }

  private incrementTimer(progress: number): number {
    if (progress < 25) {
      // Start out between 3 - 6% increments
      return Math.random() * (5 - 3 + 1) + 3;
    }
    if (progress < 65) {
      // increment between 0 - 3%
      return Math.random() * 3;
    }
    if (progress < 90) {
      // increment between 0 - 2%
      return Math.random() * 2;
    }
    // dont increment any further
    return 0;
  }

  private markFormAsTouchedAndDirty(form: AbstractControl) {
    if (form instanceof UntypedFormGroup || form instanceof FormGroup) {
      this.markFormGroupAsTouched(form);
    }

    if (form instanceof UntypedFormArray || form instanceof FormArray) {
      this.markFormArrayAsTouched(form);
    }

    form.markAsDirty({ onlySelf: true });
    form.markAsTouched({ onlySelf: true });
    form.updateValueAndValidity({ onlySelf: true });
  }

  private markFormGroupAsTouched(formGroup: UntypedFormGroup | FormGroup) {
    Object.keys(formGroup.controls).forEach((controlName) =>
      this.markFormAsTouchedAndDirty(formGroup.controls[controlName]),
    );
  }

  private markFormArrayAsTouched(formArray: UntypedFormArray | FormArray) {
    formArray.controls.forEach((control: AbstractControl) => this.markFormAsTouchedAndDirty(control));
  }

  private wrapContent(node: HTMLButtonElement, wrapper: HTMLSpanElement) {
    const r = document.createRange();
    r.selectNodeContents(node);
    r.surroundContents(wrapper);
    node.appendChild(wrapper);
  }
}
