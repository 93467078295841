import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, Optional, ViewChild } from '@angular/core';

import { RadioBadgeGroupComponent, SB_RADIO_GROUP } from '../radio-badge-group/radio-badge-group.component';

let nextUniqueId = 0;

@Component({
  selector: 'sb-radio-badge',
  standalone: true,
  exportAs: 'sbRadioBadge',
  templateUrl: './radio-badge.component.html',
})
export class RadioBadgeComponent {
  private _uniqueId = `sb-radio-${++nextUniqueId}`;

  @ViewChild('input')
  private inputElement!: ElementRef<HTMLInputElement>;

  @Input()
  set name(value: string) {
    this._name = value;
  }
  get name(): string {
    return this._name || this.radioGroup?.name;
  }
  private _name?: string;

  @Input()
  set id(value: string) {
    this._id = value;
  }
  get id(): string {
    return this._id || this._uniqueId;
  }
  private _id?: string;

  @Input()
  value = '';

  get type(): 'radio' | 'checkbox' {
    return this.radioGroup?.multiple ? 'checkbox' : 'radio';
  }

  @Input()
  set checked(value: boolean) {
    this._checked = coerceBooleanProperty(value);
  }
  get checked() {
    return this._checked;
  }
  private _checked = false;

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  get disabled() {
    return this.radioGroup?.disabled || this._disabled;
  }
  private _disabled = false;

  constructor(@Optional() @Inject(SB_RADIO_GROUP) private radioGroup: RadioBadgeGroupComponent) {}

  onInputClick() {
    if (this.disabled) {
      return;
    }

    if (this.type === 'checkbox') {
      this.checked = !this.checked;

      if (this.checked) {
        this.radioGroup.selectedAddValue(this.value);
        return;
      }

      this.radioGroup.selectedRemoveValue(this.value);

      return;
    }

    this.checked = true;
    this.radioGroup?.onValueChange(this.value);
  }

  onInputChange(event: Event) {
    event.stopPropagation();
  }

  focus() {
    this.inputElement.nativeElement.focus();
  }
}
